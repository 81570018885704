import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'app/services/core/shared.service';
import { PrintFirmCertificateComponent } from '../print-firm-certificate/print-firm-certificate.component';

@Component({
  selector: 'app-print-firm-certificate-modal',
  templateUrl: './print-firm-certificate-modal.component.html',
  styleUrls: ['./print-firm-certificate-modal.component.scss']
})
export class PrintFirmCertificateModalComponent implements OnInit {

  public uiData = LEAD_BASED_PAINT;
  public selectedCertificates: boolean = false;
  public selectedLetters: boolean = false;
  
  constructor(private dialogRef: MatDialogRef<PrintFirmCertificateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ codeType:any, firmName:any,  firmAddress: any, firmId:any,expDate:any, issueDate: any, contactName:any }, 
    private toastr: ToastrService, public dialog: MatDialog,public sharedService: SharedService) { }

  ngOnInit(): void {    
  
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onPrintClick(): void {
    this.dialog.open(PrintFirmCertificateComponent, {
        data: {codeType: this.data.codeType, firmName: this.data.firmName, 
              firmAddress: this.data.firmAddress, firmId: this.data.firmId,expDate : this.data.expDate, issueDate: this.data.issueDate,
               contactName : this.data.contactName, selectedCertificates: this.selectedCertificates, selectedLetters: this.selectedLetters},
          role: 'dialog',
          ariaLabel: 'Complaint',
          minWidth: !this.sharedService.mobile ? '1000px' : '300px',
          maxWidth: !this.sharedService.mobile ? '980px' : '300px'
        });
  }


}
