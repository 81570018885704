<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
</section>

<mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
        <strong class="noMarginHeader">Results </strong>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <mat-form-field style="width:200px;">
            <input (keyup)="filterTable()" placeholder="Filter" matInput/>                    
        </mat-form-field>            
        
        <br />

        <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
            <ng-container *ngFor="let cellData of individualApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size">{{cellData.displayName}}</mat-header-cell>
                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size">
                    <ng-container *ngIf="cellData.type === 'date'">
                        {{row[cellData.columnName] | date:'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-container *ngIf="cellData.type === 'boolean'">
                        <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                      </ng-container>
                      <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                        {{row[cellData.columnName]}}
                      </ng-container>
                </mat-cell>
            </ng-container>                    
          
            <!-- Button column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef style="width: 100px;">Actions</mat-header-cell>
                <mat-cell *matCellDef="let row" style="width: 100px;">
                    <div style="display: flex; justify-content: space-between; width: 100px;">
                        <button mat-icon-button style="width: 50px;" >
                            <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button mat-icon-button color="primary" style="width: 50px;" (click)="editEntry(row)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>
                                
            <!-- Column definitions -->
            <mat-header-row *matHeaderRowDef="individualApplicationTable.layout.columns.concat('actions')"></mat-header-row>
            <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: individualApplicationTable.layout.columns.concat('actions')"></mat-row>
          </mat-table>
          
        <br />
        <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
    </div>              
</mat-card>   