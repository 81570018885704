<div fxLayout="column">
  <mat-card [style.background-color]="uiData.firm.color">
      <mat-card-title>
          {{firmPersonToAdd.id === 0 ? 'Add Firm Staff' : 'Edit Firm Staff'}}
      </mat-card-title>
  </mat-card>
  <div class="scroll-container">  
    <mat-form-field style="width: 100%;">
      <mat-label>Search (type at least 3 characters)</mat-label>            
      <input matInput [(ngModel)]="selectedStaffMember" [matAutocomplete]="auto" (input)="updateDebounceString($event)">        
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayStaffMember" (optionSelected)="updateScrollContainerHeight()">
          <mat-option *ngFor="let staffMember of filteredStaffList" [value]="staffMember">
              {{ staffMember.id === 0 ? staffMember.firstName : staffMember.id + ': ' + staffMember.firstName + ' ' + staffMember.lastName }}
          </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <form [formGroup]="personDataForm" *ngIf="selectedStaffMember?.id === 0">
      <div fxLayout="column">                            
        &nbsp;&nbsp;
        <span><strong>Legal Name</strong></span>                                                 
        <mat-form-field fxFlex="100%">
            <input matInput placeholder="Title" formControlName="title" >                                    
        </mat-form-field>
    
        <mat-form-field fxFlex="100%">
            <input matInput placeholder="First" formControlName="firstName" >                                    
        </mat-form-field>                                                                                
    
        <mat-form-field fxFlex="100%">
            <input matInput placeholder="Middle" formControlName="middleName" >                                    
        </mat-form-field>
    
        <mat-form-field fxFlex="100%">
            <input matInput placeholder="Last" formControlName="lastName" >                                    
        </mat-form-field>                         
                    
        <span><strong>SSN</strong></span>  
        <mat-form-field fxFlex="100%">
            <input matInput placeholder="XXX-XX-XXXX" formControlName="ssn">                                    
        </mat-form-field>              
    
        <span><strong>Date of Birth</strong></span>  
        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
            <input matInput [matDatepicker]="dobPicker" placeholder="Date of Birth" formControlName="dateOfBirth" (focus)="dobPicker.open()">
            <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
            <mat-datepicker #dobPicker></mat-datepicker>
            <mat-error *ngIf="personDataForm.get('dateOfBirth').invalid">
                Please enter your Date of Birth
            </mat-error>
        </mat-form-field>                               
      </div>         
    </form>      
  </div>  
  <mat-card [style.background-color]="uiData.firm.color" fxLayout="row wrap" fxLayoutGap="8px">                
    <button mat-raised-button color="primary" [disabled]="!selectedStaffMember" (click)="onSubmit()">{{firmPersonToAdd.id === 0 ? 'Add' : 'Update'}}</button>
    <button mat-raised-button (click)="onCancel()">Cancel</button>
  </mat-card>
</div>