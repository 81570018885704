import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAccountAffiliation } from 'app/models/common/accountAffiliation';
import { IComplaint } from 'app/models/complaint/complaint';
import { IComplaintAssociation } from 'app/models/complaint/complaintAssociation';
import { IFirm } from 'app/models/firms/firm';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { UserService } from 'app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IPersonFilter } from 'app/models/filters/personFilter';

@Component({
  selector: 'app-add-edit-association',
  templateUrl: './add-edit-association.component.html',
  styleUrls: ['./add-edit-association.component.scss']
})
export class AddEditAssociationComponent implements OnInit {  
  public firmList:any = [];
  public atpList:any = [];
  public individualList:any = [];
  public filteredFirmList:any = [];
  public filteredAtpList:any = [];
  public filteredIndividualList:any = [];
  public selectedIndividual:number;
  public selectedIndividualString:string;
  public selectedFirm:number;
  public selectedFirmString:string;
  public selectedAtp:number;
  public selectedAtpString:string;
  public complaintAssociation: IComplaintAssociation;
  public status:string = "Active";
  public complaintId: number;
  private debounceString: Subject<string> = new Subject<string>();
  public peopleFilter: IPersonFilter = { filterStatus: '', filterString: '', filterAffiliation: '', expirationStartDate: '', expirationEndDate: ''};

  constructor(private dialogRef: MatDialogRef<AddEditAssociationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{complaintId:number},
    public commonService:CommonDataService,
    public firmService:FirmService,
    public individualService:PersonService,
    public atpService:AtpService,
    private toastr: ToastrService,
    public complaintService: ComplaintService) { this.initializeDebounceFunction();}

    initializeDebounceFunction(): void {
      this.debounceString.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(value => {      
        this.filterPeople(value.trim().toLowerCase());   
      });
    }

  ngOnInit(): void {    
    this.complaintId = this.data.complaintId !== null ? this.data.complaintId : null;
    this.firmService.getFirms().subscribe(result=>{
      this.firmList = result.map(x=>{ return {name :x.name, id: x.id}});    
      this.firmList.sort((a, b) => a.name.localeCompare(b.name));   
      this.filteredFirmList = this.firmList;    
    },error=>{});
    this.atpService.getATPs().subscribe(result=>{
      this.atpList = result.map(x=>{ return {name : x.name, id: x.id}});   
      this.atpList.sort((a, b) => a.name.localeCompare(b.name));     
      this.filteredAtpList = this.atpList;    
    },error=>{});
  
  }

  updatePersonDebounceString(event: any){    
    this.debounceString.next(event.target.value);    
  }

  updateAtpDebounceString(event: any){    
    this.debounceString.next(event.target.value);    
  }

  updateFirmDebounceString(event: any){    
    this.debounceString.next(event.target.value);    
  }

  filterFirms(event: any) {
    const value = event.target.value;
    if (!value) {
      return;
    }   
    const filterValue = value.toLowerCase();
    this.filteredFirmList = this.firmList.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  filterPeople(value: any) {
    if (value.length < 3) {
      return;
    }
    else{
      this.peopleFilter.filterString = value;
      this.individualService.getPeopleFiltered(this.peopleFilter).subscribe(result=>{
        this.individualList = result.map(x=>{ return {firstName :x.firstName,lastName: x.lastName, id:x.id}});  
        this.filteredIndividualList = this.individualList;    
      },error=>{this.toastr.error("An error occured getting the atp staff: ", error);});
    }
  }  

  filterIndividuals(event: any) {
    const value = event.target.value;
    if (!value) {
      this.filteredIndividualList = [...this.individualList];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredIndividualList = this.individualList.filter(user => user.name.toLowerCase().includes(filterValue));
    this.filteredIndividualList.sort((a, b) => a.name.localeCompare(b.name)); 
  }

  filterAtps(event: any) {
    const value = event.target.value;
    if (!value) {
      this.filteredAtpList = [...this.atpList];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredAtpList = this.atpList.filter(user => user.name.toLowerCase().includes(filterValue));
    this.filteredAtpList.sort((a, b) => a.name.localeCompare(b.name)); 
  }

  onSelectionIndividualChange(ev) {
    let value = this.individualList.find(x => x.id === ev.option.value);
    this.selectedIndividual = value.id;
    this.selectedIndividualString = value.firstName + ' ' + value.lastName;
    console.log(value.id);
  }

  onSelectionFirmChange(ev) {
    let value = this.firmList.find(x => x.id === ev.option.value);
    this.selectedFirm = value.id;
    this.selectedFirmString = value.name;
    console.log(value.id);
  }

  onSelectionAtpChange(ev) {
    let value = this.atpList.find(x => x.id === ev.option.value);
    this.selectedAtp = value.id;
    this.selectedAtpString = value.name;
    console.log(value.id);
  }

  onCancel(): void {
    this.dialogRef.close(this.complaintId);
  }
  
  onSubmit(): void {  
    if(this.selectedIndividual || this.selectedAtp || this.selectedFirm){
      var assocationList :IComplaintAssociation[] = [];
      if(this.selectedIndividual){
        var object:IComplaintAssociation = {
          id: 0,
          complaintId: this.complaintId,
          type: '',
          name: '',
          typeId: 0
        };
        object.type = "Person";
        object.typeId = this.selectedIndividual;
        object.name = this.selectedIndividualString;
        assocationList.push(object);
      }
      if(this.selectedAtp){
        var object:IComplaintAssociation = {
          id: 0,
          complaintId: this.complaintId,
          type: '',
          name: '',
          typeId: 0
        };
        object.type = "ATP";
        object.typeId = this.selectedAtp;
        object.name = this.selectedAtpString;
        assocationList.push(object);
      }
      if(this.selectedFirm){
        var object:IComplaintAssociation = {
          id: 0,
          complaintId: this.complaintId,
          type: '',
          name: '',
          typeId: 0
        };
        object.type = "Firm";
        object.typeId = this.selectedFirm;
        object.name = this.selectedFirmString;
        assocationList.push(object);
      }
      this.complaintService.saveComplaintAssociation(assocationList).subscribe(result=>{
        this.toastr.success("Successfully added Complaint Assocation");
        this.dialogRef.close(true);
      },error=>{
        this.toastr.error("An error occured saving the Complaint Assocation: ", error);
      }); 
   
      this.dialogRef.close(this.complaintId);
    }    
    else{
      this.dialogRef.close(this.complaintId);
    } 
  }
}
