import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-add-edit-individual-address',
  templateUrl: './add-edit-individual-address.component.html'
})
export class AddEditIndividualAddressComponent{
  public loading: boolean = false;
  public pageSize: number = 50;    
  public dataSource: MatTableDataSource<any>;

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['id','lastName', 'inactive', 'dateOfBirth'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'5'},
        {displayName:'Control Number',columnName:'lastName', type:'string', size:'45'},        
        {displayName:'Status',columnName:'inactive', type:'boolean', size:'20'},
        {displayName:'Expiration Date',columnName:'dateOfBirth', type:'date', size:'18'},          
      ],
      data: []      
    }
  }
  
  constructor(public sharedService: SharedService,) { }

  filterTable(){}

}
