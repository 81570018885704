<h2 mat-dialog-title [innerHTML]="sanitizedData(data.title)"></h2>
<div mat-dialog-content>
  <div [innerHTML]="sanitizedData(data.message)"></div>
  &nbsp;
  <mat-form-field style="width: 100%;">
    <mat-label>Certificate Control Number</mat-label>
    <input matInput [(ngModel)]="userInput" [disabled]="disableInput">
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="center" style="margin-top: 4px;">
  <button mat-raised-button [mat-dialog-close]="false">No</button>  
  <button mat-raised-button color="primary" [mat-dialog-close]="userInput">Yes</button>
</div>