

<div fxLayout="row">
<mat-card
  fxFlex="30"
  fxFlex.lt-sm="100"
  [ngClass]="{
    'bg-gray-700': sharedService.darkMode,
    'bg-indigo-50': !sharedService.darkMode
  }"
>

  <mat-card-title>
    User Profile
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">User Name</span>
        <span fxFlex></span>
        <span>{{user.principalName}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Full Name</span>
        <span fxFlex></span>
        <span>{{user.fullName}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">First Name</span>
        <span fxFlex></span>
        <span>{{user.firstName}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Last Name</span>
        <span fxFlex></span>
        <span>{{user.lastName}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Email</span>
        <span fxFlex></span>
        <span>{{user.email}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" *ngIf="showGroups">
        <span style="font-size: larger">Groups</span>
        <span fxFlex></span>
        <div fxLayout="column" fxLayoutGap="8px">
          <span *ngFor="let group of user.groups">{{group}}</span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
</div>
  <!-- roles -->
  <div fxLayout="row" *ngIf="user.roles != undefined && user.roles != null && user.roles.length > 0">
    <mat-card
    fxFlex="40"
    fxFlex.lt-sm="100"
    [ngClass]="{
      'bg-gray-700': sharedService.darkMode,
      'bg-indigo-50': !sharedService.darkMode
    }">
    <mat-card-title> Roles </mat-card-title>
    <mat-card-content>
      <ul>
        <li *ngFor="let role of user.roles">{{role}}</li>
      </ul>
    </mat-card-content>
  </mat-card>
  </div>
