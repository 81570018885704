<div fxLayout="column">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            {{auditActionData.id !== 0 ? 'Edit Action ' + auditActionData.id : 'Add Action'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">
        <form [formGroup]="auditActionDataForm" fxLayout="column">
            <div fxLayout="row" fxLayout.lt-md="column">
                <mat-card fxFlex="100" fxLayout="column">
                    <mat-card-content>                                                    
                        <div fxLayout="column" fxFlex="100">                                     
                            <span><strong>Audit Action</strong></span>                                                       
                            <mat-form-field>
                                <mat-label>Audit Action</mat-label>                                    
                                <mat-select formControlName="auditAction">   
                                    <mat-option *ngFor="let status of getAuditActions()" [value]="status">{{ status }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <span><strong>Action Date</strong></span>                                                            
                            <mat-form-field style="width: 100%;">
                                <input matInput [matDatepicker]="actionDateTimePicker" placeholder="Action Date" formControlName="actionDate" (focus)="actionDateTimePicker.open()">
                                <mat-datepicker-toggle matSuffix [for]="actionDateTimePicker"></mat-datepicker-toggle>
                                <mat-datepicker #actionDateTimePicker></mat-datepicker>
                            </mat-form-field>
                            
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </form>        
    </div>
    <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{auditActionData.id !== 0 ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>