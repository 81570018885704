import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-add-edit',
  templateUrl: './address-add-edit.component.html',
  styleUrls: ['./address-add-edit.component.scss']
})
export class AddressAddEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
