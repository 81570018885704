<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>

<div *ngIf="!sharedService.loading && !loading" [ngStyle]="{'--entity-color' : uiData.atp.color}">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            <mat-icon>{{uiData.atp.icon}}</mat-icon> {{atp.id !== 0 ? atp.id + ': ' + atp.name : 'Add New Accredited Training Provider'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">
        <form [formGroup]="atpForm">
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxFlex="100">
                    <mat-card>
                        <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    ATP Information
                                </ng-template>
                                <ng-container>
                                    <mat-card class="custom-mat-card">
                                        <mat-card-title>
                                            <div fxLayout="row">
                                                <span>Accredited Training Provider Information</span>
                                            </div>
                                        </mat-card-title>
                                        
                                        <mat-card-content fxLayout="row" fxLayout.lt-md="column">
                                            <div fxLayout="column" fxLayoutGap="8px" fxFlex="100">
                                                <strong>ID: {{atp.id}}</strong>              
                                                <form [formGroup]="atpForm">
                                                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                                    <div fxLayout="column" fxFlex="50" fxLayoutGap="8px">                                                                      
                                                        <mat-form-field>
                                                            <input matInput placeholder="ATP Name" formControlName="name" >                                    
                                                        </mat-form-field>                                                                              
                                                        <mat-form-field>
                                                            <input matInput placeholder="Also Known As" formControlName="nameAKA" >                                    
                                                        </mat-form-field> 
                                                        <mat-form-field>
                                                            <input matInput placeholder="Atp Oregon Id" formControlName="atporId" >                                    
                                                        </mat-form-field> 
                                                        <span><strong>ATP Inactive</strong></span>
                                                        <mat-checkbox  formControlName="inactive">
                                                            <span class="mat-checkbox-label">
                                                                ATP Inactive
                                                            </span>
                                                        </mat-checkbox>                                                                                 
                                                    </div> 

                                                    <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">                                                                               
                                                        <mat-form-field>
                                                            <mat-select placeholder="Affiliated Firm" formControlName="firm" [panelClass]="'custom-select-panel'">
                                                              <mat-option *ngFor="let firm of firms" [value]="firm">
                                                                {{ firm ? firm.name : 'None' }}
                                                              </mat-option>
                                                            </mat-select>
                                                          </mat-form-field>
                                                        <mat-form-field>
                                                            <mat-select placeholder="RRP Accreditation Status" formControlName="rrpAccredStatus">
                                                              <mat-option *ngFor="let status of accreditationStatusOptions" [value]="status">
                                                                {{ status }}
                                                              </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>                                                                          
                                                        <mat-form-field>
                                                            <mat-select placeholder="LBPA Accreditation Status" formControlName="lbpaAccredStatus">
                                                              <mat-option *ngFor="let status of accreditationStatusOptions" [value]="status">
                                                                {{ status }}
                                                              </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>                                                                          
                                                        <span><strong>Private ATP</strong></span>
                                                        <mat-checkbox  formControlName="privateCompany">
                                                            <span class="mat-checkbox-label">
                                                                Private ATP
                                                            </span>
                                                        </mat-checkbox>                                                                                                                                                                 
                                                    </div> 
                                                  </div>
                                                </form>
                                              </div>                                      
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </mat-tab>

                            <div *ngIf="atp.id !== 0">   
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.course.icon}}</mat-icon> &nbsp; Courses ({{atpCourseTable.layout.data.length}})
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Courses</span>
                                                </div>
                                            </mat-card-title>

                                            <mat-card-content>
                                                <div fxLayout="column">
                                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                            <div fxLayoutGap="10px">
                                                                <strong class="noMarginHeader">Results ({{atpCourseTable.layout.data.length}})</strong>                                                                 
                                                                <mat-form-field style="width:80px;">
                                                                    <input (keyup)="filterCourseTable()" placeholder="Filter Id" [(ngModel)]="filterATPCourseString" [ngModelOptions]="{standalone: true}" matInput/>
                                                                </mat-form-field>
                                                                
                                                                <mat-form-field style="width:120px">
                                                                    <mat-select multiple placeholder="Filter Discipline" [formControl]="filterATPCourseDisciplines" (selectionChange)="filterCourseTable()">
                                                                        <mat-option *ngFor="let discipline of atpCourseDisciplines" [value]="discipline">
                                                                            {{ discipline }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>            

                                                                <mat-form-field style="width:120px">
                                                                    <mat-select multiple placeholder="Filter Pre Note" [formControl]="filterATPCoursePreNote" (selectionChange)="filterCourseTable()">
                                                                        <mat-option *ngFor="let choice of atpCourseNoteBooleans" [value]="choice">
                                                                            {{ choice }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>            

                                                                <mat-form-field style="width:120px">
                                                                    <mat-select multiple placeholder="Filter Post Note" [formControl]="filterATPCoursePostNote" (selectionChange)="filterCourseTable()">
                                                                        <mat-option *ngFor="let choice of atpCourseNoteBooleans" [value]="choice">
                                                                            {{ choice }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>            

                                                                <mat-form-field style="width:120px">
                                                                    <mat-select multiple placeholder="Filter Cancelled" [formControl]="filterATPCourseCancelled" (selectionChange)="filterCourseTable()">
                                                                        <mat-option *ngFor="let choice of atpCourseCancelled" [value]="choice">
                                                                            {{ choice }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>   
                                                                
                                                                <mat-form-field style="width:120px">
                                                                    <mat-select placeholder="Filter Date Type" [formControl]="filterATPDateType" (selectionChange)="filterCourseTable()">
                                                                        <mat-option *ngFor="let choice of ['Course Start','Course End']" [value]="choice">
                                                                            {{ choice }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>   

                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>Filter After Date</mat-label>
                                                                    <input matInput [matDatepicker]="startPicker" placeholder="Filter After Date" [(ngModel)]="filterStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterCourseTable()" (focus)="startPicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #startPicker></mat-datepicker>
                                                                </mat-form-field>

                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>Filter Before Date</mat-label>
                                                                    <input matInput [matDatepicker]="endPicker" placeholder="Filter Before Date" [(ngModel)]="filterEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterCourseTable()" (focus)="endPicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #endPicker></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>                                     
                                                            <div>                        
                                                            <button mat-raised-button color="primary" (click)="editCourse(null)">
                                                                <mat-icon>add</mat-icon> Add Course
                                                            </button>
                                                            </div>
                                                        </div>
                            
                                                        <br />
                                            
                                                        <mat-table [dataSource]="atpCourseDataSource" matSort #CourseTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="atpCourseTable.layout.columns"></mat-header-row>                  
                                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: atpCourseTable.layout.columns" (click)="editCourse(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                                
                                                            <ng-container *ngFor="let cellData of atpCourseTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                                <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                    <ng-container [ngSwitch]="cellData.type">
                                                                        <ng-container *ngSwitchCase="'date'">
                                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'bool'">
                                                                            <mat-checkbox [checked]="row[cellData.columnName]" disabled></mat-checkbox>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            {{ displayLimit(row[cellData.columnName], '80') }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </mat-cell>
                                                            </ng-container> 
                                                        </mat-table>
                                                                        
                                                        <br />
                                                        <mat-paginator [pageSize]="atpCourseTablePageSize" [pageSizeOptions]="[5, 10, 50, atpCourseTable.layout.data.length]" #CourseTablePaginator="matPaginator"
                                                            showFirstLastButtons></mat-paginator>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>   
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{'assignment'}}</mat-icon> &nbsp; Audits ({{atpAuditTable.layout.data.length}})
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Audits</span>
                                                </div>
                                            </mat-card-title>
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                            <div fxLayoutGap="5px">
                                                                <strong class="noMarginHeader">Results ({{atpAuditTable.layout.data.length}})</strong>                                                                 
                                                                <mat-form-field style="width:150px;">
                                                                    <input (keyup)="filterAuditsTable()" placeholder="Filter Results" [(ngModel)]="filterATPAuditString" [ngModelOptions]="{standalone: true}" matInput/>                                                                                            
                                                                </mat-form-field> 
                                                                
                                                                <mat-form-field style="width:150px">
                                                                    <mat-select multiple placeholder="Filter Audit type" [formControl]="filterATPAuditType" (selectionChange)="filterAuditsTable()">
                                                                        <mat-option *ngFor="let type of atpAuditType" [value]="type">
                                                                            {{ type }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                                <mat-form-field style="width:180px">
                                                                    <mat-select multiple placeholder="Fiter Audit Organization" [formControl]="filterATPAuditOrganization" (selectionChange)="filterAuditsTable()">
                                                                        <mat-option *ngFor="let type of atpAuditOrganization" [value]="type">
                                                                            {{ type }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>Start Date</mat-label>
                                                                    <input matInput [matDatepicker]="startAuditPicker" placeholder="Audit Date Range Start" [(ngModel)]="filterAuditStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterAuditsTable()" (focus)="startAuditPicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="startAuditPicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #startAuditPicker></mat-datepicker>
                                                                </mat-form-field>

                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>End Date</mat-label>
                                                                    <input matInput [matDatepicker]="endAuditPicker" placeholder="Audit Date Range End" [(ngModel)]="filterAuditEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterAuditsTable()" (focus)="endAuditPicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="endAuditPicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #endAuditPicker></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>                                     
                                                            <div>                        
                                                            <button mat-raised-button color="primary" (click)="editAudit(null)">
                                                                <mat-icon>add</mat-icon> Add Audit
                                                            </button>
                                                            </div>
                                                        </div>
                            
                                                        <br />
                                            
                                                        <mat-table [dataSource]="atpAuditDataSource" matSort #AuditTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="atpAuditTable.layout.columns"></mat-header-row>                  
                                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: atpAuditTable.layout.columns" (click)="editAudit(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                                
                                                            <ng-container *ngFor="let cellData of atpAuditTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                                <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                    <ng-container [ngSwitch]="cellData.type">
                                                                        <ng-container *ngSwitchCase="'date'">
                                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'bool'">
                                                                            <mat-checkbox [checked]="row[cellData.columnName]" disabled></mat-checkbox>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </mat-cell>
                                                            </ng-container> 

                                                            <ng-container matColumnDef="actions">
                                                                <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" fxFlex="7">
                                                                    <div style="display: flex; justify-content: space-between; width: 100px;">
                                                                        <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteAuditEntry(row); $event.stopPropagation()">
                                                                            <mat-icon>delete</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </mat-cell>
                                                            </ng-container>
                                                        </mat-table>
                                                                        
                                                        <br />
                                                        <mat-paginator [pageSize]="atpAuditTablePageSize" [pageSizeOptions]="[5, 10, 50, atpAuditTable.layout.data.length]" #AuditTablePaginator="matPaginator"
                                                            showFirstLastButtons></mat-paginator>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.certificate.icon}}</mat-icon> &nbsp; Accreditation ({{atpAccreditationTable.layout.data.length}})
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Accreditations</span>
                                                </div>
                                            </mat-card-title>
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                        <div fxLayoutGap="5px">
                                                            <strong class="noMarginHeader">Results ({{atpAccreditationTable.layout.data.length}})</strong>                                                                 
                                                            <mat-form-field style="width:80px;">
                                                                <input (keyup)="filterAccreditationTable()" placeholder="Filter Id" [(ngModel)]="filterATPAccreditationString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                            </mat-form-field>    
                                                            
                                                            <mat-form-field style="width:150px">
                                                                <mat-select multiple placeholder="Filter Endorsement" [formControl]="filterATPAccreditationEndorsement" (selectionChange)="filterAccreditationTable()">
                                                                    <mat-option *ngFor="let type of atpAccredtiationEndorsementList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-select multiple placeholder="Filter Stage" [formControl]="filterATPAccreditationStage" (selectionChange)="filterAccreditationTable()">
                                                                    <mat-option *ngFor="let type of atpAccredtiationStageList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-select multiple placeholder="Filter Status" [formControl]="filterATPAccreditationStatus" (selectionChange)="filterAccreditationTable()">
                                                                    <mat-option *ngFor="let type of atpAccredtiationStatusList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-select placeholder="Filter Date" [formControl]="filterATPAccreditationDateType" (selectionChange)="filterAccreditationTable()">
                                                                    <mat-option *ngFor="let type of atpAccredtiationDateTypeList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-label>Start Date</mat-label>
                                                                <input matInput [matDatepicker]="startAccrediationPicker" placeholder="Date Range Start" [(ngModel)]="filterAccreditationStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterAccreditationTable()" (focus)="startAccrediationPicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="startAccrediationPicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #startAccrediationPicker></mat-datepicker>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-label>End Date</mat-label>
                                                                <input matInput [matDatepicker]="endAccreditationPicker" placeholder="Date Range End" [(ngModel)]="filterAccreditationEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterAccreditationTable()" (focus)="endAccreditationPicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="endAccreditationPicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #endAccreditationPicker></mat-datepicker>
                                                            </mat-form-field>
                                                        </div>                                     
                                                        <div>                        
                                                          <button mat-raised-button color="primary" (click)="editAccreditation(null)">
                                                              <mat-icon>add</mat-icon> Add Accreditation
                                                          </button>
                                                        </div>
                                                    </div>
                          
                                                    <br />
                                        
                                                    <mat-table [dataSource]="atpAccreditationDataSource" matSort #AccreditationTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="atpAccreditationTable.layout.columns"></mat-header-row>                  
                                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: atpAccreditationTable.layout.columns" (click)="editAccreditation(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                            
                                                        <ng-container *ngFor="let cellData of atpAccreditationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                <ng-container [ngSwitch]="cellData.type">
                                                                    <ng-container *ngSwitchCase="'date'">
                                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="'bool'">
                                                                        <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchDefault>
                                                                        {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                                    </ng-container>
                                                                </ng-container>
                                                            </mat-cell>
                                                        </ng-container> 

                                                        <ng-container matColumnDef="actions">
                                                            <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" fxFlex="7">
                                                                <div style="display: flex; justify-content: space-between; width: 100px;">
                                                                    <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteAccreditationEntry(row); $event.stopPropagation()">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </div>
                                                            </mat-cell>
                                                        </ng-container>
                                                    </mat-table>
                                                                    
                                                    <br />
                                                    <mat-paginator [pageSize]="atpAccreditationTablePageSize" [pageSizeOptions]="[5, 10, 50, atpAccreditationTable.layout.data.length]" #AccreditationTablePaginator="matPaginator"
                                                        showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab> 
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.endorsement.icon}}</mat-icon> &nbsp; Endorsements ({{atpEndorsementTable.layout.data.length}})
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Endorsements</span>
                                                </div>
                                            </mat-card-title>
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                        <div fxLayoutGap="5px">
                                                            <strong class="noMarginHeader">Results ({{atpEndorsementTable.layout.data.length}})</strong>                                                             
                                                            <mat-form-field style="width:80px;">
                                                                <input (keyup)="filterEndorsementTable()" placeholder="Filter Id" [(ngModel)]="filterATPEndorsementString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                            </mat-form-field>   
                                                            
                                                            <mat-form-field style="width:150px">
                                                                <mat-select multiple placeholder="Filter Endorsement" [formControl]="filterATPEndorsementType" (selectionChange)="filterEndorsementTable()">
                                                                    <mat-option *ngFor="let type of atpEndorsementType" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-label>Start Date</mat-label>
                                                                <input matInput [matDatepicker]="startEndorsementPicker" placeholder="Date Range Start" [(ngModel)]="filterEndorsementStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterEndorsementTable()" (focus)="startEndorsementPicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="startEndorsementPicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #startEndorsementPicker></mat-datepicker>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:100px">
                                                                <mat-label>End Date</mat-label>
                                                                <input matInput [matDatepicker]="endEndorsementPicker" placeholder="Date Range End" [(ngModel)]="filterEndorsementEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterEndorsementTable()" (focus)="endEndorsementPicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="endEndorsementPicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #endEndorsementPicker></mat-datepicker>
                                                            </mat-form-field>

                                                            <mat-checkbox style="width: 200px;" [formControl]="filterEndorsementShowExpired" (change)="filterEndorsementTable()">Show Expired</mat-checkbox>

                                                        </div>                                     
                                                    </div>
                          
                                                    <br />
                                        
                                                    <mat-table [dataSource]="atpEndorsementDataSource" matSort #EndorsementTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="atpEndorsementTable.layout.columns"></mat-header-row>                  
                                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: atpEndorsementTable.layout.columns" (click)="viewEndorsement(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                            
                                                        <ng-container *ngFor="let cellData of atpEndorsementTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                <ng-container [ngSwitch]="cellData.type">
                                                                    <ng-container *ngSwitchCase="'date'">
                                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="'bool'">
                                                                        <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchDefault>
                                                                        {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                                    </ng-container>
                                                                </ng-container>
                                                            </mat-cell>
                                                        </ng-container> 
                                                    </mat-table>
                                                                    
                                                    <br />
                                                    <mat-paginator [pageSize]="atpEndorsementTablePageSize" [pageSizeOptions]="[5, 10, 50, atpEndorsementTable.layout.data.length]" #EndorsementTablePaginator="matPaginator"
                                                        showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>   
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.contact.icon}}</mat-icon> &nbsp; Contact Information
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Contact Information</span>
                                                </div>
                                            </mat-card-title>
                                            <mat-card-content>                                                    
                                                <div fxLayout="row" fxLayout.lt-md="column" fxFlex>
                                                    <mat-card class="custom-mat-card" fxFlex="50">
                                                      <mat-card-title>Address</mat-card-title>
                                                        <form [formGroup]="addressForm">
                                                        <mat-card-content>                                                    
                                                          <div fxLayout="column">                                                                     
                                                            <mat-form-field>
                                                                <input matInput placeholder="Street Apt/Unit/Suite" formControlName="street">
                                                            </mat-form-field>
                                                                
                                                            <mat-form-field>
                                                                <input matInput placeholder="City"  formControlName="city">
                                                            </mat-form-field>
                                            
                                                            <mat-form-field>
                                                                <mat-label>State</mat-label>
                                                                <mat-select formControlName="state">
                                                                <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                                </mat-select>                                
                                                            </mat-form-field>
                                            
                                                            <mat-form-field>
                                                                <input matInput placeholder="Zip" formControlName="zip">
                                                            </mat-form-field>
                                            
                                                            <mat-form-field *ngIf="addressForm.get('state').value === 'OR'">                                
                                                                <mat-label>County</mat-label>
                                                                <mat-select formControlName="county" >
                                                                <mat-option>-- None --</mat-option>
                                                                <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                                                </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>                                
                                                          </div>
                                                        </mat-card-content>
                                                        </form>                                                                                                                                            
                                                    </mat-card> 
                                                  
                                                    <mat-card class="custom-mat-card" fxFlex="50">
                                                      <mat-card-title>
                                                        <div fxLayout="column">
                                                            <span fxFlex>Contact Information</span>
                                                        </div>
                                                      </mat-card-title>
                                                      <form [formGroup]="contactForm">
                                                      <mat-card-content>
                                                          <div fxLayout="column">
                                                            <mat-form-field>
                                                              <input matInput placeholder="Primary Phone" formControlName="phone">
                                                          </mat-form-field>
                              
                                                          <mat-form-field>
                                                            <input matInput placeholder="Alternate Phone" formControlName="cell">
                                                          </mat-form-field>
                              
                                                          <mat-form-field>
                                                            <input matInput placeholder="Fax Phone" formControlName="fax">
                                                          </mat-form-field>
                              
                                                          <mat-form-field>
                                                            <input matInput placeholder="Email" formControlName="email">
                                                          </mat-form-field>
                              
                                                          <mat-form-field>
                                                            <input matInput placeholder="Website" formControlName="website">
                                                          </mat-form-field>                                    
                                                        </div>                            
                                                      </mat-card-content>
                                                      </form>
                                                    </mat-card>
                                                  </div>                 
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>  

                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.notes.icon}}</mat-icon> &nbsp; Notes/Deficiencies ({{noteTableData.length + deficiencyTableData.length}})
                                    </ng-template>
                                    <ng-container>                                        
                                        <mat-accordion multi >
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <span><h4>ATP Notes - {{noteTableData.length}}</h4></span>
                                                </mat-panel-title>                                           
                                                </mat-expansion-panel-header>
                                                <div fxLayout="column">
                                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                            <div fxLayoutGap="5px">
                                                                <strong class="noMarginHeader">Results ({{noteTableData.length}})</strong> 
                                                                    
                                                                <mat-form-field style="width:200px;">
                                                                    <input (keyup)="filterNoteTable()" placeholder="Filter Results" [(ngModel)]="filterATPNoteString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                                </mat-form-field>      

                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>Start Date</mat-label>
                                                                    <input matInput [matDatepicker]="startNotePicker" placeholder="Date Range Start" [(ngModel)]="filterNoteStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterNoteTable()" (focus)="startNotePicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="startNotePicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #startNotePicker></mat-datepicker>
                                                                </mat-form-field>
    
                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>End Date</mat-label>
                                                                    <input matInput [matDatepicker]="endNotePicker" placeholder="Date Range End" [(ngModel)]="filterNoteEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterNoteTable()" (focus)="endNotePicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="endNotePicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #endNotePicker></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>                                     
                                                            <div>                        
                                                                <button mat-raised-button color="primary" (click)="editNoteTableEntry(null)">
                                                                    <mat-icon>add</mat-icon> Add Note
                                                                </button>                                        
                                                            </div>
                                                        </div>
                                    
                                                        <br />
                                            
                                                        <mat-table [dataSource]="atpNoteDataSource" matSort #NoteTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="noteTableColumns"></mat-header-row>                  
                                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: noteTableColumns" (click)="editNoteTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                                                
                                                            <ng-container *ngFor="let cellData of noteTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                                                <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                    <ng-container [ngSwitch]="cellData.type">
                                                                        <ng-container *ngSwitchCase="'date'">
                                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'bool'">
                                                                            <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            <span style="white-space: pre-wrap;">{{ displayLimit(row[cellData.columnName], "256") }}</span>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </mat-cell>
                                                            </ng-container> 
                                    
                                                            <ng-container matColumnDef="actions">
                                                                <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" fxFlex="7">
                                                                    <div style="display: flex; justify-content: space-between; width: 100px;">
                                                                        <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteNoteEntry(row); $event.stopPropagation()">
                                                                            <mat-icon>delete</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </mat-cell>
                                                            </ng-container>
                                                        </mat-table>
                                                                        
                                                        <br />
                                                        <mat-paginator [pageSize]="noteTablePageSize" [pageSizeOptions]="[5, 10, 50, noteTableData.length]" #NoteTablePaginator="matPaginator"
                                                            showFirstLastButtons></mat-paginator>
                                                    </div>
                                                  </div>
                                            </mat-expansion-panel>

                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        <span><h4>ATP Deficiencies - {{deficiencyTableData.length}}</h4></span>
                                                    </mat-panel-title>                                           
                                                </mat-expansion-panel-header>
                                                <div fxLayout="column">
                                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                            <div fxLayoutGap="5px">
                                                                <strong class="noMarginHeader">Results ({{deficiencyTableData.length}})</strong> 

                                                                <mat-form-field style="width:200px;">
                                                                    <input (keyup)="filterDeficiencyTable()" placeholder="Filter Results" [(ngModel)]="filterATPDeficiencyString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                                </mat-form-field>      

                                                                <mat-form-field style="width:150px">
                                                                    <mat-select multiple placeholder="Filter Resolved" [formControl]="filterATPDeficiencyResolved" (selectionChange)="filterDeficiencyTable()">
                                                                        <mat-option *ngFor="let type of atpDeficiencyBoolList" [value]="type">
                                                                            {{ type }}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>

                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>Start Date</mat-label>
                                                                    <input matInput [matDatepicker]="startDeficiencyPicker" placeholder="Date Range Start" [(ngModel)]="filterDeficiencyStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterDeficiencyTable()" (focus)="startDeficiencyPicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="startDeficiencyPicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #startDeficiencyPicker></mat-datepicker>
                                                                </mat-form-field>
    
                                                                <mat-form-field style="width:100px">
                                                                    <mat-label>End Date</mat-label>
                                                                    <input matInput [matDatepicker]="endDeficiencyPicker" placeholder="Date Range End" [(ngModel)]="filterDeficiencyEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterDeficiencyTable()" (focus)="endDeficiencyPicker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="endDeficiencyPicker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #endDeficiencyPicker></mat-datepicker>
                                                                </mat-form-field>
                                                            </div>                                     
                                                            <div>                        
                                                                <button mat-raised-button color="primary" (click)="editDeficiencyTableEntry(null)">
                                                                    <mat-icon>add</mat-icon> Add Deficiency
                                                                </button>                                        
                                                            </div>
                                                        </div>
                                    
                                                        <br />
                                            
                                                        <mat-table [dataSource]="atpDeficiencyDataSource" matSort #DeficiencyTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="deficiencyTableColumns"></mat-header-row>                  
                                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: deficiencyTableColumns" (click)="editDeficiencyTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                                                
                                                            <ng-container *ngFor="let cellData of deficiencyTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                                                <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                    <ng-container [ngSwitch]="cellData.type">
                                                                        <ng-container *ngSwitchCase="'date'">
                                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'bool'">
                                                                            <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            <span style="white-space: pre-wrap;">{{ displayLimit(row[cellData.columnName], "256") }}</span>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </mat-cell>
                                                            </ng-container> 
                                    
                                                            <ng-container matColumnDef="actions">
                                                                <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" fxFlex="7">
                                                                    <div style="display: flex; justify-content: space-between; width: 100px;">
                                                                        <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteDeficiencyEntry(row); $event.stopPropagation()">
                                                                            <mat-icon>delete</mat-icon>
                                                                        </button>
                                                                    </div>
                                                                </mat-cell>
                                                            </ng-container>
                                                        </mat-table>
                                                                        
                                                        <br />
                                                        <mat-paginator [pageSize]="deficiencyTablePageSize" [pageSizeOptions]="[5, 10, 50, deficiencyTableData.length]" #DeficiencyTablePaginator="matPaginator"
                                                            showFirstLastButtons></mat-paginator>
                                                    </div>
                                                  </div>
                                                </mat-expansion-panel>
                                        </mat-accordion>
                                    </ng-container>
                                </mat-tab>

                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.staff.icon}}</mat-icon> &nbsp; Staff ({{atpStaffTable.layout.data.length}})
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Staff</span>
                                                </div>
                                            </mat-card-title>
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                        <div fxLayoutGap="5px">
                                                            <strong class="noMarginHeader">Results ({{atpStaffTable.layout.data.length}})</strong>                                                                
                                                            <mat-form-field style="width:200px;">
                                                                <input (keyup)="filterStaffTable()" placeholder="Filter Name" [(ngModel)]="filterATPStaffString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                            </mat-form-field> 
                                                            
                                                            <mat-form-field style="width:150px">
                                                                <mat-select multiple placeholder="Filter POC" [formControl]="filterATPStaffPOC" (selectionChange)="filterStaffTable()">
                                                                    <mat-option *ngFor="let type of atpStaffBoolList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:150px">
                                                                <mat-select multiple placeholder="Filter Instructor" [formControl]="filterATPStaffInstructor" (selectionChange)="filterStaffTable()">
                                                                    <mat-option *ngFor="let type of atpStaffBoolList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:200px">
                                                                <mat-select multiple placeholder="Filter Primary Instructor" [formControl]="filterATPStaffPrimaryInstructor" (selectionChange)="filterStaffTable()">
                                                                    <mat-option *ngFor="let type of atpStaffBoolList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>

                                                            <mat-form-field style="width:200px">
                                                                <mat-select multiple placeholder="Filter Training Manager" [formControl]="filterATPStaffTrainingManager" (selectionChange)="filterStaffTable()">
                                                                    <mat-option *ngFor="let type of atpStaffBoolList" [value]="type">
                                                                        {{ type }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>                                     
                                                        <div>                        
                                                          <button mat-raised-button color="primary" (click)="editStaff(null)">
                                                              <mat-icon>add</mat-icon> Add Staff
                                                          </button>
                                                        </div>
                                                    </div>
                          
                                                    <br />
                                        
                                                    <mat-table [dataSource]="atpStaffDataSource" matSort #StaffTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="atpStaffTable.layout.columns.concat('Action')"></mat-header-row>                  
                                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: atpStaffTable.layout.columns.concat('Action')" (click)="editStaff(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                            
                                                        <ng-container *ngFor="let cellData of atpStaffTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                <ng-container [ngSwitch]="cellData.type">
                                                                    <ng-container *ngSwitchCase="'date'">
                                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="'bool'">
                                                                        <mat-checkbox [checked]="row[cellData.columnName]" disabled="true"></mat-checkbox>
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchDefault>
                                                                        {{ displayLimit(row[cellData.columnName], '80') }}
                                                                    </ng-container>
                                                                </ng-container>
                                                            </mat-cell>
                                                        </ng-container> 

                                                        <ng-container matColumnDef="Action">
                                                            <mat-header-cell *matHeaderCellDef fxFlex="15" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" fxFlex="15" fxFlex.lt-sm="100" style="height: 45px">
                                                                <button title="Edit {{row['Name']}}" mat-icon-button color="primary" aria-label="Edit Person" (click)="editPerson(row); $event.stopPropagation()">
                                                                    <mat-icon>edit</mat-icon>
                                                                </button>
                                                                <button title="Delete {{row['Name']}}" mat-icon-button color="warn" aria-label="Delete Person" (click)="deleteStaff(row); $event.stopPropagation()">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>
                                                            </mat-cell>
                                                          </ng-container>
                                                    </mat-table>
                                                                    
                                                    <br />
                                                    <mat-paginator [pageSize]="atpStaffTablePageSize" [pageSizeOptions]="[5, 10, 50, atpStaffTable.layout.data.length]" #StaffTablePaginator="matPaginator"
                                                        showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>  
                                <!-- Payments tab -->   
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.payment.icon}}</mat-icon> &nbsp; Payments
                                    </ng-template>
                                    <ng-container>
                                        <div *ngIf="atp.id === 0" class="text-red-400"><strong><br />ATP Needs to be Saved</strong></div>
                                                <div fxLayout="column" fxLayoutGap="8px" *ngIf="atp.id > 0">
                                                    <app-manage-payments [@slideLeft]
                                                               [entityId]="atp.id.toString()"
                                                               [entityType]="paymentService.AtpEntityType"
                                                               [allowMultiplePayments]='true'
                                                               [editingPayment]='true'
                                                               (notifyParent)="paymentUpdated($event)"
                                                               [allowDelete]='true'
                                                               [cardColor]="cardColor"
                                                               [changeIndicator]="changeIndicator">
                                          </app-manage-payments>                
                                                </div>
                                    </ng-container>
                                </mat-tab> 
                                <!-- Complaints tab -->   
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{'create_new_folder'}}</mat-icon> Complaints ({{complaints.length}})
                                    </ng-template>
                                    <mat-card class="custom-mat-card">
                                        <mat-card-title>
                                            <div fxLayout="row">
                                                <span>Complaints</span>
                                            </div>
                                        </mat-card-title>
                                        <mat-card-content>
                                    <ng-container>
                                        <div *ngIf="atp.id === 0" class="text-red-400"><strong><br />ATP Needs to be Saved</strong></div>
                                                <div fxLayout="column" fxLayoutGap="8px" *ngIf="atp.id > 0">
                                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                        <div></div>                                                        
                                                        <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
                                                            <button mat-raised-button color="primary" title="Add Complaint" (click)="editComplaint(null)">
                                                              <mat-icon>add</mat-icon> Add Complaint
                                                            </button>                
                                                          </div>
                                                    </div>
                                                    <mat-table [dataSource]="complaintDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                        <ng-container *ngFor="let cellData of complaintsTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                            <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                                                <ng-container *ngIf="cellData.type === 'date'">
                                                                    <div *ngIf="isMobile" fxLayout="row">  
                                                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                                        <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                                    </div>
                                                                    <div *ngIf="!isMobile">  
                                                                        {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                                    </div>
                                                                  </ng-container>
                                                                  <ng-container *ngIf="cellData.type === 'boolean'">
                                                                    <div *ngIf="isMobile" fxLayout="row">
                                                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                                        <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                                    </div>
                                                                    <div *ngIf="!isMobile">
                                                                        <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                                    </div>                            
                                                                  </ng-container>
                                                                  <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                                                    <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                                        <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                                                            <div *ngIf="isMobile" fxLayout="row">  
                                                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                                                <span>{{row[cellData.columnName]}}</span>
                                                                            </div>
                                                                            <div *ngIf="!isMobile">  
                                                                                {{row[cellData.columnName]}}
                                                                            </div>                                    
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #notStatus> 
                                                                    <div *ngIf="isMobile" fxLayout="row">                                    
                                                                        <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                                        <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                                                    </div>
                                                                    <div *ngIf="!isMobile">
                                                                        {{displayLimit(row[cellData.columnName], cellData.size)}}
                                                                    </div> 
                                                                    </ng-template> 
                                                                  </ng-container>
                                                            </mat-cell>
                                                        </ng-container>    
                                                        <!-- Column definitions -->
                                                        <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintsTable.layout.columns"></mat-header-row>
                                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintsTable.layout.columns"  
                                                        (click)="editComplaint(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>
                                                      </mat-table>             
                                                </div>
                                    </ng-container>
                                </mat-card-content>
                                </mat-card>
                                </mat-tab>                           
                            </div>                                                     
                        </mat-tab-group>
                    </mat-card>
                </div>              
            </div>
        </form>
    </div>
    <mat-card [style.background-color]="uiData.atp.color">
        <button mat-raised-button color="primary" aria-label="Save" style="margin-right: 10px;" (click)="saveAtp()">
            {{atp.id === 0 ? 'Add ATP' : 'Save'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit" style="margin-right: 10px;">Exit</button>
    </mat-card>
</div>