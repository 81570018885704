import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFirm } from 'app/models/firms/firm';
import { IFirmLeadCredential } from 'app/models/firms/firmLeadCredentials';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { AddEditFirmStaffComponent } from '../add-edit-firm-staff/add-edit-firm-staff.component';
import { FIRM_CREDENTIALS, FIRM_CREDENTIAL_MAPPING, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';

@Component({
  selector: 'app-add-edit-firm-credential',
  templateUrl: './add-edit-firm-credential.component.html',
  styleUrls: ['./add-edit-firm-credential.component.scss']
})
export class AddEditFirmCredentialComponent implements OnInit {
  public firmCredentials: string[] = Object.values(FIRM_CREDENTIALS);
  public selectedCredential: string = "";
  public credentialToAdd: IFirmLeadCredential = {id:0, firmLeadCredentialType:"", firm: null};
  public uiData = LEAD_BASED_PAINT;
  public credentialMapping = FIRM_CREDENTIAL_MAPPING;  
  public firmValidatedCredential: {isValid: boolean, staffIds:any[], totalStaff: number, expirationDate: string} = {isValid: false, staffIds: [], totalStaff: 0, expirationDate: ""};
  
  constructor(
    private dialogRef: MatDialogRef<AddEditFirmStaffComponent>,    
    public firmService: FirmService,
    @Inject(MAT_DIALOG_DATA) public data:{firm:IFirm, credential:IFirmLeadCredential, firmCredentials:IFirmLeadCredential[], staffCerts:any[]},      
    private toastr: ToastrService
  ) 
  { 
    if(this.data.credential !== null){
      this.credentialToAdd = this.data.credential;
      this.selectedCredential = this.data.credential.firmLeadCredentialType;
      this.validateFirmCredentials();
    }
    else{
      this.credentialToAdd.firm = this.data.firm;
    }            
  }

  ngOnInit(): void {
    this.firmCredentials = [this.data?.credential?.firmLeadCredentialType].concat(Object.values(FIRM_CREDENTIALS).filter(credential => !this.data.firmCredentials.some(currentCredential => currentCredential.firmLeadCredentialType === credential)));
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
  onSubmit(): void {
    this.credentialToAdd.firmLeadCredentialType = this.selectedCredential;
    this.firmService.saveFirmLeadCredential(this.credentialToAdd).subscribe(result=>{
      this.toastr.success("Successfully added firm credential");
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("An error occurred trying to add the firm credential: ", error);
    });
  }

  onCredentialChange(): void {
    this.firmValidatedCredential = {isValid: false, staffIds: [], totalStaff: 0, expirationDate: ""};
    this.validateFirmCredentials();
  }

  validateFirmCredentials(): void {
    var value = this.firmCredentialsMappedToPersonCerts();      
    
    this.firmValidatedCredential = {
      isValid: value.isValid,
      staffIds: value.staffIds,
      totalStaff: value.staffIds.length,
      expirationDate: value.expirationDate
    }    
  }

  firmCredentialsMappedToPersonCerts() {
    const returnObject = { isValid: false, staffIds: [], expirationDate: "" };
    
    this.data.staffCerts?.forEach(member => {
        member.certs.forEach(cert => {
            const credentialTypes = this.credentialMapping[this.selectedCredential];
            
            if (credentialTypes && credentialTypes.includes(cert.codeType)) {
              returnObject.isValid = true;
              
              let staffObject = {
                  id: cert.person?.id, 
                  display:  cert.person?.id + ': ' + cert.person?.firstName + ' ' + cert.person?.lastName, 
                  expiration: cert.expirationDate, 
                  codeType: cert.codeType
              };
              
              if (!returnObject.staffIds.some(staff => staff.id === cert.person.id)) {
                  if (returnObject.expirationDate === "") {
                      returnObject.expirationDate = cert.expirationDate;
                      returnObject.staffIds.unshift(staffObject); // Add to the beginning if expirationDate is just set
                  } else {
                      const Date1 = new Date(returnObject.expirationDate);
                      const Date2 = new Date(cert.expirationDate);
                      if (Date1 < Date2) {
                          returnObject.expirationDate = Date2.toISOString();
                          returnObject.staffIds.unshift(staffObject); // Add to the beginning if the new expirationDate is later
                      } else {
                          returnObject.staffIds.push(staffObject); // Add to the end if the new expirationDate is not later
                      }
                  }
              }
          }
        });
    });    
    return returnObject;
  }
}
