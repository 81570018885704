import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, InteractionType,
         PopupRequest, RedirectRequest } from "@azure/msal-browser";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { SharedService } from "app/services/core/shared.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  userRoles: string[] = [];
  public rolesUpdated: Subject<string[]> = new Subject<string[]>();
  private readonly _destroying$ = new Subject<void>();
  public loading: boolean = false;
  msalBroadcastService: any;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private http: HttpClient,
    private sharedService: SharedService,
    private router: Router) {
  }

  public get username(): string {
    let account = this.authService.instance.getActiveAccount();
    if (account) {
      return this.formatName(account.idTokenClaims["given_name"])
             + " " +
             this.formatName(account.idTokenClaims["family_name"]);
    }
    return null;
  }

  public get isAdmin(): boolean {
    return this.userRoles.includes('Admin');
  }

  public get isTrainingAdmin(): boolean {
    return this.userRoles.includes('Training Admin');
  }

  public get isWorkerPermitAdmin(): boolean {
    return this.userRoles.includes('Worker Permit Admin');
  }

  public get isFacilitatorAdmin(): boolean {
    return this.userRoles.includes('Facilitator Admin');
  }

  public get isManufacturerAdmin(): boolean {
    return this.userRoles.includes('Manufacturer Admin');
  }

  public get isServiceCenterAdmin(): boolean {
    return this.userRoles.includes('Service Center Admin');
  }

  public get isTestingLabAdmin(): boolean {
    return this.userRoles.includes('Testing Lab Admin');
  }

  public get userId(): string {
    let account = this.authService.instance.getActiveAccount();
    if (account) {
      return account.idTokenClaims["oid"]
    }
    return null;
  }

  private formatName(name: string): string {
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
  }

  public login() {
    this.loading = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.setActiveAccount();
            this.loadUserInformation();
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.setActiveAccount();
            this.loadUserInformation();
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  public setActiveAccount(): void {
    let account: AccountInfo = this.authService.instance.getAllAccounts()[0];
    if (environment.groups.length > 0) {
      const inGroup = environment.groups.some((group) =>
        account.idTokenClaims["groups"].includes(group)
      );
      if (inGroup) {
        this.authService.instance.setActiveAccount(account);
      } else {
        sessionStorage.clear();
        this.router.navigateByUrl("/sessions/403");
      }
    } else {
      this.authService.instance.setActiveAccount(account);
    }
  }

  public isAuthenticated(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  public getUserRoles(): Observable<string[]> {
    return this.http.get<string[]>(environment.privateApi + "UserProfile/user/roles");
  }

  public getUserNameClaim(): Observable<string> {
    return this.http.get<string>(environment.privateApi + "UserProfile/user/claim/name", { responseType: 'text' as 'json' });
  }

  public rolesChanged$ = this.rolesUpdated.asObservable();

  public loadUserInformation(): void {
    this.sharedService.loading = true;
    this.getUserRoles().subscribe(
      (response) => {
        this.userRoles = response;
        this.rolesUpdated.next(response);
      },
      (error) => {
        console.error("auth error: " + error.statusText);
        this.sharedService.loading = false;
      },
      () => {
        this.getUserNameClaim().subscribe(
          (response) => {
            this.sharedService.setUserNameClaim(response);
            this.sharedService.loading = false;
          },
          (error) => {
            console.error("auth error: " + error.statusText);
            this.sharedService.loading = false;
          },
          () => {
            this.sharedService.updateUser();
            
            this.router.navigateByUrl(this.router.url);
          }
        );
      }
    );
  }

  public isAuthorized(roles?: string[]): boolean {
    if (roles != null && roles.length > 0) {
      return (this.isAuthenticated() && this.userHasAtLeastOneRole(roles));
    }
    return this.isAuthenticated();
  }

  public userHasAtLeastOneRole(roles: string[]): boolean {
    if (roles == null || roles.length == 0 || this.userRoles.length == 0) {
      return false;
    }

    for (let role of roles) {
      if (this.userRoles.findIndex((r) => r == role) >= 0) {
        return true;
      }
    }
    return false;
  }

  public logout(): void {
    this.router.navigateByUrl('/home').then(
      complete => this.authService.logout()
    );
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
