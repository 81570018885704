import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AddressAddEditComponent } from './add-edit/address-add-edit.component';
import { AddressDeleteComponent } from './delete/address-delete.component';
import { IAddress } from 'app/models/common/address';
import { AddressService } from 'app/services/common/address-service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  processing: boolean = false;
  loading: boolean = false;
  pageSize = 50;  
  addresses: IAddress[] = []
  
  displayedColumns: string[] = ['Street', 'City', 'State','Zip','County','Type','actions'];
  dataSource = new MatTableDataSource<IAddress>(this.addresses);
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  filterTable(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  constructor(public dialog: MatDialog, 
              private addressService: AddressService,
              public sharedService: SharedService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    //this.loadAddresses();
  }

  loadAddresses() {
    this.loading = true;
    this.addressService.getAddresses().subscribe(
      result => {
        this.addresses = result;
        this.loading = false;
      },
      error => {
        this.toastr.error(error.message);
        this.loading = false;
      },
      () => {
        this.dataSource = new MatTableDataSource<IAddress>(this.addresses);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    ); 
  }

  editAddress(addressId: number) {
    const dialogRef = this.dialog.open(AddressAddEditComponent, {
      width: '90%',
      data: addressId,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadAddresses();
    });
  }

  deleteAddress(addressDetailsId: number) { 
    const dialogRef = this.dialog.open(AddressDeleteComponent, {
      width: '90%',
      data: addressDetailsId,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadAddresses();
    });   
  }
}
