<div fxLayout="column" [ngStyle]="{'--entity-color' : uiData.firm.color}">
    <mat-card [style.background-color]="uiData.firm.color">
        <mat-card-title>
            {{firmPersonContactData.id !== 0 ? 'Edit Contact Information' : 'Add New Contact Information'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">        
        <div fxLayout="column" fxFlex>
            <mat-form-field>
                <mat-label>Select Contact Person</mat-label>
                <input matInput [(ngModel)]="selectedStaffMember" [matAutocomplete]="auto" (input)="updateDebounceString($event)">        
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayStaffMember">
                    <mat-option *ngFor="let staffMember of filteredStaffList" [value]="staffMember">
                        {{ staffMember.id }}: {{ staffMember.firstName }} {{ staffMember.lastName }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>              
    </div>
    <mat-card [style.background-color]="uiData.firm.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{firmPersonContactData.id !== 0 ? 'Save' : 'Add'}}
        </button>        
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>
