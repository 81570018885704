<div fxLayout="column">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            {{noteData.id !== 0 ? 'Edit Note ' + noteData.id : 'Add Note'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">
        <form [formGroup]="noteDataForm" fxLayout="column">
            <div fxLayout="column">
                <div fxFlex="100" fxLayout="row" fxLayout.lt-md="column">
                    <div fxLayout="column" fxFlex="100">                                     
                        <span><strong>Contact Type</strong></span>  
                        <mat-form-field>
                            <input matInput formControlName="contactType" placeholder="Contact Type">
                        </mat-form-field>                              
                    </div>
                    &nbsp;&nbsp;
                    <div fxLayout="column" fxFlex="100">                                     
                        <span><strong>Date</strong></span>                                                            
                        <mat-form-field style="width: 100%;">
                            <input matInput [matDatepicker]="letterDateTimePicker" placeholder="Date" formControlName="date" (focus)="letterDateTimePicker.open()">
                            <mat-datepicker-toggle matSuffix [for]="letterDateTimePicker"></mat-datepicker-toggle>
                            <mat-datepicker #letterDateTimePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div fxflex="100" fxLayout="column">
                    <div fxLayout="column" fxFlex="100">  
                        <span><strong>Description</strong></span>
                        <mat-form-field appearance="fill">
                            <textarea matInput formControlName="notes" placeholder="Type your description here" style="height: 200px;"></textarea>
                        </mat-form-field>                       
                    </div>
                </div>
            </div>
        </form>                
    </div>
    <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{noteData.id !== 0 ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>