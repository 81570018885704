import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IUser } from 'app/auth/auth.models';
import { UserService } from 'app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from "@env/environment";
import { SharedService } from 'app/services/core/shared.service';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-user-administration',
  templateUrl: './user-administration.component.html',
  styleUrls: ['./user-administration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserService],
})

export class UserAdministrationComponent implements OnInit {
  users: IUser[] = [];
  loading : boolean = false;
  allowAddUsers: boolean = environment.allowAddUsers;
  public environment = environment;

  constructor(public dialog: MatDialog,
    public userService: UserService,
    public sharedService: SharedService,
    public authService: AuthService,
    private toastr: ToastrService ) {}

    displayedColumns: string[] = ['userName','fullName','email', 'roles', 'actions'];
    dataSource = new MatTableDataSource<IUser>(this.users);

    @ViewChild('TableUsersPaginator') paginator: MatPaginator;
    @ViewChild('TableUsersSort') sort: MatSort;

  ngOnInit(): void {
    this.loadUsers();
  }

  filterTable(event: Event) {
    let value = (event.target as HTMLInputElement).value;
    this.dataSource.filter = value.trim().toLowerCase();
  }

  removeUser(email: string) {
    this.userService.removeUser(email).subscribe(u => {
      this.loadUsers();
      this.toastr.success("Removed user: " + email);
    },
    error => {
      console.error('remove error: ' + error.statusText);
    });
  }

  loadUsers() {
    this.loading = true;
    this.userService.getUsers().subscribe(u => {
      this.users = u;
      this.loading = false;
    },
    error => {
      console.error('error: ' + error.statusText);
      this.loading = false;
    },
    () => {
      this.dataSource.data = this.users;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  openRemoveUserDialog(user: IUser) {
    const dialogRef = this.dialog.open(DialogRemoveUserComponent, {
      panelClass: this.sharedService.userTheme === 'dark' ? 'theme-dark' : '',
      data: {
          user: user
      },
   });
    dialogRef.afterClosed().subscribe(result => {
      if(result == "Yes") {
        this.removeUser(user.email);
      }
    });
  }

  openEditUserDialog(user: IUser) {
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      panelClass: this.sharedService.userTheme === 'dark' ? 'theme-dark' : '',
      data: {
        user: user
      },
   });
   dialogRef.afterClosed().subscribe(
    result => this.userDialogClosed(result)
  );
  }

  openAddUserDialog() {
    const dialogRef = this.dialog.open(DialogAddUserComponent, {
      panelClass: this.sharedService.userTheme === 'dark' ? 'theme-dark' : '',
      data: {
      },
   });
    dialogRef.afterClosed().subscribe(
      result => this.userDialogClosed(result)
    );
  }

  userDialogClosed(result: any): void{
    if(result !== "Cancel") {
      this.loadUsers();
    }
  }

}

//Remove User Dialog
@Component({
  selector: 'dialog-remove-user',
  templateUrl: 'remove-user.html',
})
export class DialogRemoveUserComponent {
  constructor( @Inject(MAT_DIALOG_DATA) public data: any) { }
}

//Add User Dialog
@Component({
  selector: 'dialog-add-user',
  templateUrl: 'add-user.html',
  providers: [UserService],
})
export class DialogAddUserComponent implements OnInit  {
  user: IUser = {
    userName: null,
    fullName: null,
    email: null,
    mobile: null,
    phone: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    manager: null,
    jobTitle: null,
    active: true,
    roles: [],
    isAuthenticated: false,
    timestamp: null,
  };
  filter: string = "";
  userFullName: string = "";
  processing: boolean = false;
  useAdIdentity: boolean = environment.useAdIdentity;
  roles: string[] = [];
  constructor(public userService: UserService,
              private toastr: ToastrService,
              public sharedService: SharedService,
              private dialogRef: MatDialogRef<DialogAddUserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  ngOnInit(): void {
    this.filter = "";

    //load roles
    this.userService.getRoles().subscribe( r =>
      {
        this.roles = r;
      },
      error => {
        console.error('error: ' + error.statusText);
        this.toastr.error("Unable to load user roles");
      });
  }

  findUser() {
    this.processing = true;
    this.userService.findUser(this.filter).subscribe(u => {
      this.user = u;
      if(this.user == null){
        this.toastr.error("Unable to find user: " + this.filter);
      }
      this.processing = false;
    },
    error => {
      console.error('error: ' + error.statusText);
      this.processing = false;
    });
  }

  addLocalUser() {
    this.user.userName = this.filter;
    this.user.email = this.filter;
    this.addUser();
  }

  addUser() {
    this.processing = true;
    if(!this.useAdIdentity) {
        this.user.fullName = this.userFullName;
    }
    this.userService.addUser(this.user).subscribe(u => {
      this.processing = false;
      this.toastr.success("Add user: " + this.user.email);
      this.user = null;
      this.filter = "";
      this.dialogRef.close();
    },
    error => {
      console.error('error: ' + error.statusText);
      this.processing = false;
      this.toastr.error("Unable to add user: " + this.user.email);
    });
  }

  updateRole(role: string, checkbox: MatCheckboxChange) {
    if(checkbox.checked){
      this.user.roles.push(role);
    }
    else {
      let index = this.user.roles.indexOf(role);
      if(index >= 0) {
        this.user.roles.splice(index, 1)
      }
    }
  }
}

//Edit User Dialog
@Component({
  selector: 'dialog-edit-user',
  templateUrl: 'edit-user.html',
  providers: [UserService],
})
export class DialogEditUserComponent implements OnInit  {
  user: IUser = null;
  processing: boolean = false;
  roles: string[] = [];
  constructor(public userService: UserService,
              private toastr: ToastrService,
              public sharedService: SharedService,
              private dialogRef: MatDialogRef<DialogAddUserComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }
  ngOnInit(): void {
    this.user = null;
    this.userService.getRoles().subscribe( r =>
      {
        this.roles = r;
      },
      error => {
        console.error('error: ' + error.statusText);
        this.toastr.error("Unable to load user roles");
      });


    //load user
    this.processing = true;
    this.userService.getUser(this.data.user.email).subscribe( u =>
      {
        this.user = u;
        this.processing = false;
      },
      error => {
        console.error('error: ' + error.statusText);
        this.toastr.error("Unable to user: " + this.data.user.email);
        this.processing = false;
      })

  }

  userHasRole(role: string){
    if(this.user.roles.indexOf(role) >= 0){
      return true;
    }
    return false;
  }

  saveUser() {
    this.processing = true;
    this.userService.saveUser(this.user).subscribe(u => {
      this.processing = false;
      this.toastr.success("Saved user: " + this.user.email);
      this.user = null;
      this.dialogRef.close();
    },
    error => {
      console.error('error: ' + error.statusText);
      this.processing = false;
      this.toastr.error("Unable to save user: " + this.user.email);
    });
  }

  updateUserRole(role: string, checkbox: MatCheckboxChange) {
    if(checkbox.checked){
      this.user.roles.push(role);
    }
    else {
      let index = this.user.roles.indexOf(role);
      if(index >= 0) {
        this.user.roles.splice(index, 1)
      }
    }
  }
}
