<div fxLayout="column" fxFlex>  
    <h1 mat-dialog-title>Association With</h1>
    <mat-form-field>
        <mat-label>Individual</mat-label>
        <input matInput type="text" placeholder="Selected Individual"  [matAutocomplete]="autoIndividual" 
        name="selectedIndividual" (input)="updatePersonDebounceString($event)" >
        <mat-autocomplete #autoIndividual="matAutocomplete" (optionSelected)="onSelectionIndividualChange($event)" >
          <mat-option *ngFor="let individual of filteredIndividualList"  [value]="individual.id">{{individual.id + ': ' + individual.firstName + ' ' + individual.lastName}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Firm</mat-label>
        <input matInput type="text" placeholder="Selected Firm"  [matAutocomplete]="autoFirm" 
        name="selectedFirm" (input)="filterFirms($event)">
        <mat-autocomplete #autoFirm="matAutocomplete" (optionSelected)="onSelectionFirmChange($event)">
          <mat-option *ngFor="let firm of filteredFirmList" [value]="firm.id">{{firm.id + ': ' + firm.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field> 
    <mat-form-field>
        <mat-label>ATP</mat-label>
        <input matInput type="text" placeholder="Selected ATP" [matAutocomplete]="autoAtp" 
        name="selectedAtp" (input)="filterAtps($event)">
        <mat-autocomplete #autoAtp="matAutocomplete" (optionSelected)="onSelectionAtpChange($event)">
          <mat-option *ngFor="let atp of filteredAtpList" [value]="atp.id">{{atp.id + ': ' + atp.name}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>     
    <div>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary"  [disabled]="!(selectedIndividual || selectedFirm || selectedAtp)" (click)="onSubmit()">Add</button>
    </div>
</div>
