import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPerson } from 'app/models/People/person';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { AddEditIndividualComponent } from './add-edit-individual/add-edit-individual.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { CommonDataService } from 'app/services/common/common-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { IPersonFilter } from 'app/models/filters/personFilter';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent implements OnInit {
  public isMobile: boolean = false;
  public loading: boolean = true;
  public pageSize: number = 50;  
  public individuals: IPerson[] = [];
  public dataSource: MatTableDataSource<any>;
  public filterString: string = '';
  public filteredIndividuals: IPerson[] = [];
  public filteredActive = new FormControl('Active');
  public filterType = new FormControl('Firm');  
  public expirationStartDate = new FormControl(new Date());
  public expirationEndDate = new FormControl(new Date());
  public firmStaff: IPerson[] = [];

  public personFilter: IPersonFilter = {
    filterString: '', 
    filterStatus: '', 
    filterAffiliation: '', 
    expirationStartDate: '',
    expirationEndDate: '',
  };

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['id', 'inactive', 'firstName', 'lastName', 'middleName', 'title', 'expirationDate'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'10'},
        {displayName:'First Name',columnName:'firstName', type:'string', size:'15'},        
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'20'},          
        {displayName:'Middle Name',columnName:'middleName', type:'string', size:'15'},     
        {displayName:'Title',columnName:'title', type:'string', size:'8'},           
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'15'},          
      ],
      data: []      
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public personService: PersonService,
    public firmService: FirmService,
    public toastr: ToastrService) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.loadIndividuals();
  }

  ngOnInit(): void {}

  loadIndividuals(): void {
    this.filterString = this.commonService.individualManagementFilter.filterString;
    this.filteredActive = this.commonService.individualManagementFilter.filteredActive;
    this.expirationStartDate = this.commonService.individualManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.individualManagementFilter.expirationEndDate;
    this.filterTable();
  }
  
  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredIndividuals);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editPerson(person: IPerson) {    
    const dialogRef = this.dialog.open(AddEditIndividualComponent, {
      width: '90%',
      data: {person: person},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }

  filterTable() { 
    this.commonService.individualManagementFilter.filterString = this.filterString;
    this.commonService.individualManagementFilter.filteredActive = this.filteredActive;
    this.commonService.individualManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.individualManagementFilter.expirationEndDate = this.expirationEndDate;

    this.personFilter.filterString = this.filterString;
    this.personFilter.filterStatus = this.filteredActive.value;
    this.personFilter.filterAffiliation = this.filterType.value;
    this.personFilter.expirationStartDate = this.expirationStartDate.value?.toISOString().substring(0,10);;
    this.personFilter.expirationEndDate = this.expirationEndDate.value?.toISOString().substring(0,10);;
    
    this.personService.getPeopleFiltered(this.personFilter).subscribe(result=>{            
      this.individuals = result.map(individual => {
        if (individual.certificates && individual.certificates.length > 0) {
          // Find the furthest expiration date
          const expirationDate = individual.certificates
            .map(certificate => new Date(certificate.expirationDate))
            .reduce((a, b) => a > b ? a : b);
          // Return the individual with the added expirationDate field
          return { ...individual, expirationDate: expirationDate.toISOString() };
        } else {
          // If there are no certificates, return the individual without modification
          return individual;
        }
      });
      this.filteredIndividuals = this.individuals;        
      this.updateDataSource();
      this.loading = false;      
    },error=>{this.toastr.error('Error loading people filtered', error)});    
  }

  getFirmActiveTypes(){
    return ['Active','InActive','Both'];
  }

  displayLimit(text: any, limit: string){
    var newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  getFilterTypeValues() {    
    return ['Firm','Student','ATP','No Affiliation'];
  }

  clearFilters(){
    this.filterString = '';
    this.filterType.setValue('Firm');
    this.filteredActive.setValue('Active');
    this.expirationEndDate.reset();
    this.expirationStartDate.reset();
    this.filterTable();
  }
}
