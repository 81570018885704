<h2 mat-dialog-title>
  <mat-icon class="icon-20" >person_add</mat-icon> Add User
</h2>
<div *ngIf="useAdIdentity">
  <div  mat-dialog-content>Enter employee id or email address below to find user<br /><br /></div>
  <mat-form-field  [style.width.px]=327>
    <input matInput placeholder="Enter employee information" [(ngModel)]="filter">
  </mat-form-field>
</div>
<div *ngIf="!useAdIdentity">
  <div *ngIf="!useAdIdentity" mat-dialog-content>Enter employee information<br /><br /></div>
  <mat-form-field  [style.width.px]=327>
    <input matInput placeholder="Enter employee email" [(ngModel)]="filter">
  </mat-form-field>
  <br />
  <mat-form-field  [style.width.px]=327>
    <input matInput placeholder="Enter employee name" [(ngModel)]="userFullName">
  </mat-form-field>
</div>
<div mat-dialog-actions *ngIf="useAdIdentity">
  <button mat-button (click)="findUser()" [disabled]="processing || filter == null || filter.length == 0">Find</button>
  <button mat-button mat-dialog-close="Cancel" aria-label="Cancel">Cancel</button>
</div>
<div *ngIf="user?.email != null || !useAdIdentity">
  <br />
  <mat-card [ngClass]="{
    'bg-gray-700': sharedService.darkMode,
    'bg-indigo-50': !sharedService.darkMode}">
      <div fxLayout="column" fxLayoutGap="8px grid">

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.email != null && user?.email.length > 0">
          <em class="text-muted">Email:  &nbsp;&nbsp;</em>
          <span>{{user?.email}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.address != null && user?.address.length > 0">
          <em class="text-muted">Address:  &nbsp;&nbsp;</em>
          <span>{{user?.address}} {{user?.city}} {{user?.state}} {{user?.zip}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.mobile != null && user?.mobile.length > 0">
          <em class="text-muted">Mobile:  &nbsp;&nbsp;</em>
          <span>{{user?.mobile}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.phone != null && user?.phone.length > 0">
          <em class="text-muted">Phone:  &nbsp;&nbsp;</em>
          <span>{{user?.phone}}</span>
        </div>

        <div fxFlex="50" fxFlex.lt-md="100" *ngIf="user?.jobTitle != null && user?.jobTitle.length > 0">
          <em class="text-muted">Title:  &nbsp;&nbsp;</em>
          <span>{{user?.jobTitle}}</span>
        </div>

        <div fxFlex="100" fxFlex.lt-md="100" >
          <br />
          <em class="text-muted p-r-16">Roles:</em>
          <br />
          <span *ngFor="let role of roles">
            <mat-checkbox (change)="updateRole(role, $event)">{{role}}</mat-checkbox><br />
          </span>
        </div>
      </div>

      <div>
        <br />
        <button *ngIf="useAdIdentity" mat-raised-button color="primary" (click)="addUser()" [disabled]="processing || user == null" color="primary">Add User</button>
        <button *ngIf="!useAdIdentity" mat-raised-button color="primary" (click)="addLocalUser()" [disabled]="processing || user == null" color="primary">Add User</button>
      </div>
  </mat-card>

</div>
