<div fxLayout="column">
  <mat-card [style.background-color]="uiData.firm.color">
      <mat-card-title>
          {{'Add Firm Administrator'}}
      </mat-card-title>
  </mat-card>
  <div class="scroll-container">   
    <div fxLayout="column">
      <mat-form-field>
          <mat-label>Select User</mat-label>
          <input matInput type="text" placeholder="Selected User" [(ngModel)]="selectedUser" [matAutocomplete]="auto" name="selectedUser" required (input)="filterAdministrators($event)">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let user of filteredUserList" [value]="user">{{user}}</mat-option>
          </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="status" name="status" required>
            <mat-option value="Active">Active</mat-option>
            <mat-option value="Inactive">Inactive</mat-option>
          </mat-select>
      </mat-form-field>      
    </div>
  </div>
  <mat-card [style.background-color]="uiData.firm.color" fxLayout="row wrap" fxLayoutGap="8px">                
    <button mat-raised-button color="primary" [disabled]="!selectedUser" (click)="onSubmit()">Add</button>
    <button mat-raised-button (click)="onCancel()">Cancel</button>
  </mat-card>
</div>

