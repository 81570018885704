<div *ngIf="loading">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>



<div fxLayout="column" *ngIf="!loading">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" class="matero-col" fxLayoutAlign="start">
            <h2 class="noMarginHeader">
                <mat-icon>dashboard</mat-icon> Dashboard
            </h2>
        </div>
    </div>    
    <mat-card>
      <div fxLayout="row" fxLayout.lt-lg="column">
        <mat-card fxFlex="33" fxFlex.lt-lg="100" class="firm-mat-card">
          <mat-card class="firm-filled-mat-card">
            <mat-card-title>
              <div fxLayout="column">
                <div>
                  Firms
                </div>

                <div>
                  <mat-button-toggle-group [(ngModel)]="firmFilterInterval" (ngModelChange)="onFirmFilterChanged()">                    
                    <mat-button-toggle value="Weekly" style="font-size: 10px;">Weekly</mat-button-toggle>
                    <mat-button-toggle value="Monthly" style="font-size: 10px;">Monthly</mat-button-toggle>
                    <mat-button-toggle value="Quarterly" style="font-size: 10px;">Quarterly</mat-button-toggle>
                    <mat-button-toggle value="Yearly" style="font-size: 10px;">Yearly</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-title>
          </mat-card>
          
          <div>
            <div style="padding: 5px" *ngIf="!firmLoading">
              <mat-card-content>
                <div>
                  <div>
                    <strong>Active Firms</strong>
                  </div>
                  <div class="custom-list">   
                    <a class="blue-text" (click)="goToFirms()">Expiring: {{firmDashboardData.ccbExpireCount}}</a>                                                                         
                  </div>
                </div>
              </mat-card-content>
          
              <mat-card-content>
                <div>
                  <div>
                    <strong>Applications</strong>
                  </div>
                  <div class="custom-list">
                    <a class="blue-text" (click)="goToFirmApplications('new')">New Applications: {{firmDashboardData.newApplicationCount}}</a>
                    <a class="blue-text" (click)="goToFirmApplications('review')">Awaiting Review: {{firmDashboardData.applicationAwaitingReviewCount}}</a>
                    <a class="blue-text" (click)="goToFirmApplications('renewal')">Renewals: {{firmDashboardData.applicationRenewalCount}}</a>
                    <a class="blue-text" (click)="goToFirmApplications('expiring')">Expiring: {{firmDashboardData.applicationExpiringCount}}</a>
                  </div>
                </div>
              </mat-card-content>
            </div>
            <div class="loading-overlay" *ngIf="firmLoading" style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </mat-card>

        &nbsp;
          
        <mat-card fxFlex="33" fxFlex.lt-lg="100" class="individual-mat-card">
          <mat-card class="individual-filled-mat-card">
            <mat-card-title>
              <div fxLayout="column">
                <div>
                  Individuals
                </div>

                <div>
                  <mat-button-toggle-group [(ngModel)]="individualFilterInterval" (ngModelChange)="onIndividualFilterChanged()">                    
                    <mat-button-toggle value="Weekly" style="font-size: 10px;">Weekly</mat-button-toggle>
                    <mat-button-toggle value="Monthly" style="font-size: 10px;">Monthly</mat-button-toggle>
                    <mat-button-toggle value="Quarterly" style="font-size: 10px;">Quarterly</mat-button-toggle>
                    <mat-button-toggle value="Yearly" style="font-size: 10px;">Yearly</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
            </mat-card-title>
          </mat-card>
          
          <div>
            <div style="padding: 5px" *ngIf="!personLoading">
              <mat-card-content>
                <div>
                  <div>
                    <strong>Active Individuals</strong>
                  </div>
                  <div class="custom-list">                                                          
                    <a class="blue-text" (click)="goToIndividuals()">Expiring: {{personDashboardData.certificatesExpiring}}</a>
                  </div>
                </div>
              </mat-card-content>
          
              <mat-card-content>
                <div>
                  <div>
                    <strong>Applications</strong>
                  </div>
                  <div class="custom-list">
                    <a class="blue-text" (click)="goToIndividualApplications('new')">New Applications: {{personDashboardData.newApplicationCount}}</a>
                    <a class="blue-text" (click)="goToIndividualApplications('review')">Awaiting Review: {{personDashboardData.applicationAwaitingReviewCount}}</a>
                    <a class="blue-text" (click)="goToIndividualApplications('renewal')">Renewals: {{personDashboardData.applicationRenewalCount}}</a>
                    <a class="blue-text" (click)="goToIndividualApplications('expiring')">Expiring: {{personDashboardData.applicationExpiringCount}}</a>
                  </div>
                </div>
              </mat-card-content>
            </div>
            <div class="loading-overlay" *ngIf="personLoading" style="display: flex; justify-content: center; align-items: center; height: 100%;">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </mat-card>  
  </div>
</mat-card>

<section *ngIf="!loading">
  <div fxFlex="100" fxFlex.lt-md="100" fxLayout="row" fxLayout.lt-md="column">
    <div class="matero-col" fxFlex.gt-sm="17" fxFlex.gt-xs="17" fxFlex="17">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': paymentsPending?.length == 0, 'bg-red-400':paymentsPending?.length > 0}">
        <mat-card-title class="f-s-12 f-w-100">Payments Pending</mat-card-title>
        <mat-card-content>
          <h2 (click)="goToPendingPayments()">{{paymentsPending?.length}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
    &nbsp;
    <div class="matero-col" fxFlex.gt-sm="17" fxFlex.gt-xs="17" fxFlex="17">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': paymentsAwaitingClearance?.length == 0, 'bg-red-400':paymentsAwaitingClearance?.length > 0}">
        <mat-card-title class="f-s-12 f-w-100">Payments Awaiting Clearance</mat-card-title>
        <mat-card-content>
          <h2 (click)="goToAwaitingClearancePayments()">{{paymentsAwaitingClearance?.length}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
    &nbsp;
    <div class="matero-col" fxFlex.gt-sm="18" fxFlex.gt-xs="18" fxFlex="18">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': firmDashboardData.applicationOutstandingCount == 0, 'bg-red-400':firmDashboardData.applicationOutstandingCount > 0}">
        <mat-card-title class="f-s-12 f-w-100">Outstanding Firm Applications</mat-card-title>
        <mat-card-content>
          <h2 (click)="goToFirmApplications('outstanding')">{{firmDashboardData.applicationOutstandingCount}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
    &nbsp;
    <div class="matero-col" fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="20">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': personDashboardData.applicationOutstandingCount == 0, 'bg-red-400':personDashboardData.applicationOutstandingCount > 0}">
        <mat-card-title class="f-s-12 f-w-100">Outstanding Individual Applications</mat-card-title>
        <mat-card-content>
          <h2 (click)="goToIndividualApplications('outstanding')">{{personDashboardData.applicationOutstandingCount}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
