
<form [formGroup]="complaintOwnerTenantForm">
    <div fxLayout="column" fxLayoutGap="8px">
        <span><strong>{{this.data.selected !== null? 'Edit' : 'Add'}} Owner/Tenant</strong></span>         
        <div fxLayout="column" fxFlex="50" fxLayoutGap="8px"> 
            <br> 
        <mat-checkbox  formControlName="isOwner">   
            <span class="mat-checkbox-label">
                Is Owner
            </span>                                               
        </mat-checkbox> 
        </div>                    
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="First" formControlName="firstName" >                                    
            </mat-form-field>
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Last" formControlName="lastName" >                                    
            </mat-form-field>
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Email" formControlName="email" >                                    
            </mat-form-field>
        </div>  
        <div fxLayout="column" class="m-t-8">         
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" >
                <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                    <input matInput placeholder="Street Apt/Unit/Suite" formControlName="address">
                </mat-form-field>
                    
                <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                    <input matInput placeholder="City"  formControlName="city">
                </mat-form-field>

                <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state">
                    <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                    </mat-select>                                
                </mat-form-field>                                        
                <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                    <input matInput placeholder="Zip" formControlName="zip">
                </mat-form-field>  
                <mat-form-field fxFlex="30" fxFlex.lt-md="100" *ngIf="complaintOwnerTenantForm.get('state').value === 'OR'">                                
                    <mat-label>County</mat-label>
                    <mat-select formControlName="county" >
                    <mat-option>-- None --</mat-option>
                    <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                    </mat-option>
                    </mat-select>
                </mat-form-field> 
            </div>                
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">  
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Phone" formControlName="phoneNumber" >                                    
            </mat-form-field>
            <mat-form-field fxFlex="50%">
                <input matInput placeholder="Cell" formControlName="cellNumber" >                                    
            </mat-form-field>      
            <mat-form-field fxFlex="50%">
                <input matInput placeholder="Fax" formControlName="fax" >                                    
            </mat-form-field>      
        </div>  
        <div>
            <button mat-raised-button (click)="onCancel()">Cancel</button>
            <button mat-raised-button color="primary" [disabled]="!complaintOwnerTenantForm.valid" (click)="onSubmit()">{{this.data.selected !== null ? 'Save' : 'Add'}}</button>
        </div>              
    </div>
</form>  
