import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddFirmApplicationPersonComponent } from 'app/components/application/firm/add-firm-application/add-firm-application-person/add-firm-application-person.component';
import { IFirmApplicationPerson } from 'app/models/firms/firm-application-person';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-individual-application-training',
  templateUrl: './add-individual-application-training.component.html',
  styleUrls: ['./add-individual-application-training.component.scss']
})
export class AddIndividualApplicationTrainingComponent implements OnInit {
  buttonText: string = "";
  loading: boolean = false;
  processing: boolean = false;  
  postErrorMessages: string = "";  
  DisciplineList:string[] = ['Risk Assessor', 'Supervisor', 'Worker', 'Project Designer','Inspector']
  objectKeys = Object.keys;
  formFields = {};

  lbpaFormFields = {
    certificateId:{name: 'Certificate Number', type: 'string'},
    firstName:{name: 'First Name', type: 'string'},
    lastName:{name: 'Last Name', type: 'string'},
    discipline:{name: 'Certificate Type', type: 'option', data: ['Risk Assessor', 'Supervisor', 'Worker', 'Project Designer','Inspector']},
    firmIsPaying:{name: 'Cover Fee', type: 'boolean'},
  }

  rrpFormFields = {
    certificateId:{name: 'Certificate Number', type: 'string'},
    firstName:{name: 'First Name', type: 'string'},
    lastName:{name: 'Last Name', type: 'string'},
    atp:{name: 'Training Provider', type: 'string'},
    trainingDate:{name: 'Training Date', type: 'date'},    
  }

  individual: IFirmApplicationPerson = {
    id: 0,
    applicationId: 0,
    application: null,
    person: null,
    firstName: '',
    lastName: '',
    certificateId: '',
    discipline: '',
    atp: null,
    trainingDate: null,
    firmIsPaying: false,
  }
  applicationType: string = "";
  individualForm: FormGroup = null;

  constructor( 
    public firmService: FirmService,     
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {individualData: IFirmApplicationPerson, applicationType: string},
    private dialogRef: MatDialogRef<AddFirmApplicationPersonComponent>) {
    this.loading = true;
    this.individual = this.data ? this.data.individualData : this.individual;
    this.applicationType = this.data.applicationType;
    this.loadfirm();
  }

  ngOnInit(): void { 
    // ngOnInit
  }

  loadfirm() {
    this.loading = true;  
    
    if (this.individual?.id > 0) {
      this.buttonText = "Update Individual";      
    }
    else {
      this.buttonText = "Add Individual";            
    }
    this.setFormValues();
    this.loading = false;
  }

  onSubmit() {
    if (!this.individualForm.valid) {
      this.postErrorMessages = "Individual Name is required";
      this.toastr.error("Please fix validation errors");
      return;
    }
    
    this.getFormValues();
    
    this.firmService.saveFirmApplicationPerson(this.individual).subscribe(
      result => {
        this.dialogRef.close();    
      },
      error => {
        
      }
    )
  }

  getFormValues(){
    for (const controlName in this.individual) {
      if (this.individualForm.controls.hasOwnProperty(controlName)) {
        this.individual[controlName] = this.individualForm.controls[controlName].value;        
      } 
    }  
  }

  setFormValues() {
    this.individualForm = new FormGroup({});
    this.formFields = this.applicationType == 'lbpa' ? this.lbpaFormFields : this.rrpFormFields;
    
    for (const key in this.formFields) {        
      this.individualForm.addControl(key, new FormControl('', Validators.required));      
    }

    for (const controlName in this.individual) {            
      if (this.individualForm.controls.hasOwnProperty(controlName)) {        
        this.individualForm.controls[controlName].setValue(this.individual[controlName]);
      } 
    } 
  }
}
