<mat-toolbar class="matero-toolbar">
  <button mat-icon-button class="matero-toolbar-button"
    *ngIf="(!environment.collapsibleSidebar || isMobile) && environment.displaySidebar && isAuthenticated()" (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <span *ngIf="!sharedService.mobile && !sharedService.tablet" fxFlex class="p-l-16" style="color: #005595">
    Oregon Healthy Environments Portal        
  </span>

  <span *ngIf="sharedService.tablet" fxFlex class="p-l-16" style="color: #005595">
    Healthy Environments
  </span>

  <span *ngIf="(sharedService.mobile) && isAuthenticated()" fxFlex class="p-l-16" style="color: #005595">
    OHEP
  </span>

  <div class="download-container" *ngIf="commonService.progress > 0 && commonService.progress < 100">
    <mat-icon class="download-icon">download</mat-icon>
    <div class="spinner-container">
      <mat-progress-spinner
        *ngIf="commonService.progress < 100"
        diameter="40"
        mode="determinate"
        [value]="commonService.progress">
      </mat-progress-spinner>
      <div class="progress-text">{{ commonService.progress }}%</div>
    </div>    
  </div>

  <app-translate *ngIf="environment.translateText"></app-translate>

  <div fxLayout="row" fxLayoutGap="8px">

    <button mat-icon-button *ngIf="environment.showThemeToggle && sharedService.darkMode && !isAuthenticated()"
      class="matero-toolbar-button" (click)="toggleTheme()" aria-label="Toggle Theme" matTooltip="Select Light Theme"
      matTooltipPosition="below" [matTooltipShowDelay]="750">
      <mat-icon class="light-theme-icon">brightness_7</mat-icon>
    </button>

    <button mat-icon-button *ngIf="environment.showThemeToggle && !sharedService.darkMode && !isAuthenticated()"
      class="matero-toolbar-button" (click)="toggleTheme()" aria-label="Toggle Theme" matTooltip="Select Dark Theme"
      matTooltipPosition="below" [matTooltipShowDelay]="750">
      <mat-icon class="dark-theme-icon">brightness_3</mat-icon>
    </button>

    <button mat-icon-button *ngIf="!isAuthenticated()" (click)="login()" class="matero-toolbar-button"
      aria-label="Login" matTooltip="Login" matTooltipPosition="below" [matTooltipShowDelay]="750">
      <mat-icon class="dark-theme-icon">login</mat-icon>
    </button>

    <div *ngIf="isAuthenticated()" fxLayout="row" fxLayoutAlign="start center" class="m-r-8"
      [matMenuTriggerFor]="userMenu">
      <span>{{ username }}</span>
    </div>
    <mat-menu #userMenu="matMenu">

      <button mat-menu-item class="matero-toolbar-button" aria-label="View Profile" matTooltip="View Profile"
        matTooltipPosition="below" routerLink='/profile' [matTooltipShowDelay]="750">
        <span class="p-r-8">View Profile</span>
        <mat-icon class="light-theme-icon">account_circle</mat-icon>
      </button>

      <button mat-menu-item *ngIf="environment.showThemeToggle && sharedService.darkMode" class="matero-toolbar-button"
        (click)="toggleTheme()" aria-label="Toggle Theme" matTooltip="Select Light Theme" matTooltipPosition="below"
        [matTooltipShowDelay]="750">
        <span class="p-r-8">Toggle Theme</span>
        <mat-icon class="light-theme-icon">light_mode</mat-icon>
      </button>

      <button mat-menu-item *ngIf="environment.showThemeToggle && !sharedService.darkMode" class="matero-toolbar-button"
        (click)="toggleTheme()" aria-label="Toggle Theme" matTooltip="Select Dark Theme" matTooltipPosition="below"
        [matTooltipShowDelay]="750">
        <span class="p-r-8">Toggle Theme</span>
        <mat-icon class="dark-theme-icon">brightness_3</mat-icon>
      </button>

      <button mat-menu-item *ngIf="isAuthenticated()" (click)="logout()" class="matero-toolbar-button"
        aria-label="Logout" matTooltip="Logout" matTooltipPosition="below" [matTooltipShowDelay]="750">
        <span class="m-r-16">Sign-Out</span>
        <mat-icon>logout</mat-icon>
      </button>
    </mat-menu>

  </div>

</mat-toolbar>
