<div fxLayout="column" fxFlex>  
    <h1 mat-dialog-title>{{this.data.selected !== null? 'Edit' : 'Add'}} Inspection/Followup/Narrative</h1>  
    <div fxLayout="row" fxLayoutGap="16px">
        <mat-card fxFlex="50%">
            <span><strong>Date:</strong></span> <br>
            <mat-form-field>
                <input matInput [matDatepicker]="inspectionDatePicker" placeholder="" (focus)="datePicker.open()" [value]="defaultDate" (dateChange)="onDateSelected($event)">
                <mat-datepicker-toggle matSuffix [for]="inspectionDatePicker"></mat-datepicker-toggle>
            </mat-form-field>    
            <mat-datepicker #inspectionDatePicker></mat-datepicker>         

            <div fxLayout="column">
                <span><strong>Type:</strong></span>
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedInspectionType" placeholder="">
                        <mat-option *ngFor="let type of inspectionTypeOptions" [value]="type.value">{{type.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
            <div fxLayout="column">
                <span><strong>Scope:</strong></span>
                <div class="grid-columns">
                    <ng-container *ngFor="let row of scopeTypeOptions">
                        <mat-checkbox value="{{row.value}}" [checked]="row.checked" (change)="handleScopeTypeSelection($event)">{{row.name}}</mat-checkbox>
                    </ng-container>
                </div> 
            </div>
        </mat-card>
        <mat-card fxFlex="50%">
            <span><strong>Details:</strong></span> <br>
            <mat-form-field appearance="outline" style="width: 100%;">                                                        
            <textarea matInput [(ngModel)]="complaintInspectionDetails" rows="15" cols="40" style="white-space: pre-wrap;"></textarea>
            </mat-form-field>
        </mat-card>
    </div>
   
    <div>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!complaintInspectionDetails" (click)="onSubmit()">{{this.data.selected !== null? 'Save' : 'Add'}}</button>
    </div>
</div>