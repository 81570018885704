import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { IComplaintAction } from 'app/models/complaint/complaintAction';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-action',
  templateUrl: './add-edit-action.component.html',
  styleUrls: ['./add-edit-action.component.scss']
})
export class AddEditActionComponent implements OnInit {
    actionTypeOptions = [
        {value:'Verbal', name:'Verbal Warning', checked:false},
        {value:'Written', name:'Written Warning', checked:false},
        {value:'VOG', name:'Verbal Order', checked:false},
        {value:'WrittenOrder', name:'Written Order', checked:false},
        {value:'RevokedCertification', name:'Revoked Certification', checked:false},
        {value:'IssuedCivilPenalty', name:'Issued Civil Penalty', checked:false},
        {value:'Other', name:'Other', checked:false}
      ];
  public complaintActionTypeOther: string;
  public otherActionSelected: boolean = false;
  public otherActionDetails: string;
  public complaintActionToAdd: IComplaintAction = {
    id: 0, complaintId: 0,
    actionType: '',
    actionDate: undefined,
    lastUpdatedBy: '',
    lastUpdated: undefined,
    other: false
  };
  public defaultDate: Date = new Date();
  @ViewChild('datePicker') datePicker: MatDatepicker<any>;
  
  constructor(private dialogRef: MatDialogRef<AddEditActionComponent>,
    public complaintService: ComplaintService,
    @Inject(MAT_DIALOG_DATA) public data:{complaintId:number,  selected:IComplaintAction},      
    private toastr: ToastrService) {
      this.defaultDate.setFullYear(this.defaultDate.getFullYear());;
    }

  ngOnInit(): void {    
    this.complaintActionToAdd.complaintId = this.data.complaintId;     
    if(this.data.selected !== null){
      this.complaintActionToAdd.id = this.data.selected.id;
      this.defaultDate = new Date(this.data.selected.actionDate);   
    if (this.data.selected.actionType !== '') {
      const selectedActionType = this.data.selected.actionType;      
      const selectedAction = this.actionTypeOptions.find(option => option.name === this.data.selected.actionType);
      if (selectedAction) {
        selectedAction.checked = true;        
      }
      if(this.data.selected.other){
        const selectedAction = this.actionTypeOptions.find(option => option.value === "Other");
        if (selectedAction) {
          selectedAction.checked = true;        
        }
        this.otherActionSelected = this.data.selected.other;
        this.complaintActionTypeOther = this.data.selected.actionType;
        this.otherActionDetails = this.data.selected.actionType;
      }
    }

    }
  }

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value.toISOString();
    this.complaintActionToAdd.actionDate = selectedDate;    
  }
  
  //handle radio button selection   
  handleActionTypeSelection(event: MatRadioChange){
        this.actionTypeOptions.forEach(option => {
        option.checked = false;
        });
        if(event.source.value == 'Other'){
            this.otherActionSelected = event.source.checked;            
        }
        this.complaintActionToAdd.actionType = event.source.value;
    }
   
  onCancel(): void {
    this.dialogRef.close(false);
  }
  
  onSubmit(): void {
    if(this.complaintActionToAdd.actionType != null && this.complaintActionToAdd.actionType != ""){   
        if(this.complaintActionToAdd.actionType == 'Other'){
            if(this.otherActionDetails != null && this.otherActionDetails != ""){
            this.complaintActionToAdd.other = true;
            this.complaintActionToAdd.actionType = this.otherActionDetails; 
            }
            else{
            this.toastr.error("please enter other action type")
            return;
            }
        }
        this.complaintActionToAdd.actionDate = this.defaultDate.toISOString(); 
        
        this.complaintService.saveComplaintAction(this.complaintActionToAdd).subscribe(response => {
            if(response){
            this.toastr.success("Action added successfully");
            this.dialogRef.close(true);
            }
        });
    }
   
  }
}
