<mat-card [style.background]="cardColor">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">{{title}} </span>
        <span *ngIf="editingPayment" class="f-s-14">Click Entry to Edit </span>
        <button *ngIf="(payments.length == 0 || allowMultiplePayments) && allowAdd" type="button" [disabled]="!editingPayment" mat-icon-button color="primary"
                matTooltip="Add New Payment" matTooltipShowDelay=800 aria-label="Add New Payment" (click)="addNewPayment()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="paymentsSource" matSort #contactSort="matSort" aria-describedby="owners" class="mat-elevation-z8" >
        <ng-container matColumnDef="localReferenceId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Local Reference Id</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">localReferenceId</span>
            {{ payment.localReferenceId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="orderNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Order Number</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Order Number</span>
            {{ payment.orderNumber }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Type</span>
            {{ paymentService.getPaymentName(payment.entityType, payment.type)}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Status</span>
            {{ payment.status }} &nbsp; <mat-icon *ngIf="payment.paid">done</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paymentCleared">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Payment Cleared</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Cleared</span>
            {{ payment.paymentCleared }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Amount</span>
            {{ payment.amount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paymentMethod">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Method</span>
            {{ payment.paymentMethod }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sku">
          <mat-header-cell *matHeaderCellDef mat-sort-header>SKU</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">SKU</span>
            {{ payment.sku }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Comments</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Comments</span>
            <span style="white-space: pre-wrap">{{ payment.comment }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef=""></mat-header-cell>
          <mat-cell *matCellDef="let payment">
            <button mat-icon-button color="warn" aria-label="Delete Payment" *ngIf="allowDelete"
              (click)="deletePayment(payment.id, payment.type)"  [disabled]="!editingPayment">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-raised-button type="button" *ngIf="!payment.paid" color="primary" aria-label="Mark as Cleared" matTooltip="Mark as paid"
              (click)="markAsCleared(payment)" [disabled]="!editingPayment">
              Mark as Cleared
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="paymentColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: paymentColumns">
        </mat-row>
      </mat-table>
    </div>


  </mat-card-content>
</mat-card>
