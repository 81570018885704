import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  env = environment;
  constructor() { 
    // constructor
  }

  ngOnInit(): void {
    // ngOnInit
  }
  
}