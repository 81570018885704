<div fxLayout="column">
    <mat-card [style.background-color]="uiData.firm.color">
        <mat-card-title>
            {{'Add Firm Credential'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">     
        <mat-form-field style="width: 100%;">
            <mat-label>Credential</mat-label>
            <mat-select [(ngModel)]="selectedCredential" (selectionChange)="onCredentialChange()"> 
                <mat-option *ngFor="let credential of firmCredentials" [value]="credential">{{ credential }}</mat-option>
            </mat-select>
        </mat-form-field> 
        
        <div class="text-red-400" *ngIf="firmValidatedCredential.staffIds.length === 0">
            No staff members associated with this firm are certified to perform the selected service
        </div>
        <div *ngIf="firmValidatedCredential.staffIds.length !== 0">
            <div>
                <strong>Expiration Date</strong> is based on the certified staff member with the <strong>latest</strong> expiration date.
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px;">
                <strong>({{firmValidatedCredential.staffIds.length}}) - Certified Staff for the Selected Credential:</strong>
            </div>
            <ol class="staff-list">
                <li *ngFor="let staff of firmValidatedCredential.staffIds">
                    <div fxLayout="row wrap" fxLayoutGap="15px">
                        <div><strong>Name: </strong>{{staff.display}}</div>
                        <div><strong>Certificate: </strong>{{staff.codeType}}</div>
                        <div><strong>Expiration: </strong>{{staff.expiration | date: 'MM/dd/yyyy'}}</div>
                    </div>
                </li>
            </ol>
        </div>
    </div>  
    <mat-card [style.background-color]="uiData.firm.color" fxLayout="row wrap" fxLayoutGap="8px">            
        <button mat-raised-button color="primary" style="margin-right: 10px;" [disabled]="!selectedCredential" (click)="onSubmit()">Submit</button>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
    </mat-card>    
</div>

  