import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpCourseStudent } from 'app/models/atp/atpCourseStudent';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { AddEditCourseStudentComponent } from '../add-edit-course-student/add-edit-course-student.component';
import { IAtpAudit } from 'app/models/atp/atpAudit';
import { IAtpAuditAction } from 'app/models/atp/atpAuditAction';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';

@Component({
  selector: 'app-add-edit-audit-action',
  templateUrl: './add-edit-audit-action.component.html',
  styleUrls: ['./add-edit-audit-action.component.scss']
})
export class AddEditAuditActionComponent implements OnInit {
  public auditActionDataForm = null;
  public auditActionData: IAtpAuditAction = null;  
  public uiData = LEAD_BASED_PAINT;

  constructor(private personService:PersonService,
    @Inject(MAT_DIALOG_DATA) public data:{action:IAtpAuditAction, audit:IAtpAudit},
    public sharedService: SharedService,
    private atpService: AtpService,
    private commonService: CommonDataService,
    private toastr: ToastrService,   
    public dialog: MatDialog, 
    private dialogRef: MatDialogRef<AddEditCourseStudentComponent>,) { }

    ngOnInit(): void {
      this.setCourseDataFormFields();
      this.loadData();
    }
  
    loadData(){
      if(this.data.action !== null){
        this.auditActionData = this.data.action;         
      }
      else{
        this.auditActionData = {
          id: 0,
          atpAudit: this.data.audit,
          auditAction: "",
          actionDate: null,
        };     
      }
  
      for (const controlName in this.auditActionData) {
        if (this.auditActionDataForm.controls.hasOwnProperty(controlName)) {        
          this.auditActionDataForm.controls[controlName].setValue(this.auditActionData[controlName]);          
        }
      }    
    }
  
    setCourseDataFormFields(){       
      this.auditActionDataForm = new UntypedFormGroup({
        auditAction: new UntypedFormControl(""),
        actionDate: new UntypedFormControl(""),
      }); 
    }
  
    updateCourseStudentData(): void {    
      for (const controlName in this.auditActionData) {
        if (this.auditActionDataForm.controls.hasOwnProperty(controlName)) {
          this.auditActionData[controlName] = this.auditActionDataForm.controls[controlName].value;        
        }
      } 
    }
    
    getAuditActions(){
      return['Advisory Letter Issued','Enforcement Action','Penalty Issued']
    }

    savePrompt(){
      var isNew = this.auditActionData.id === 0;
      const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
        width: '400px',      
        data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> the Action?' : 'Are you sure you want to <strong>Update</strong> the Action?',title: isNew ? 'Add the Action' : 'Update the Action'},
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.updateCourseStudentData();
          this.saveData();
        }
      })
    }

    saveData(){
      this.atpService.saveATPAuditAction(this.auditActionData).subscribe(
        (data) => {
          this.toastr.success('Action saved successfully');
          this.dialogRef.close(true);
        },
        (error) => {
          this.toastr.error('Error saving Action');
        }
      );
    }
}
