import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class SharedService {
  public mobile: boolean = false;
  public tablet: boolean = false;
  private api: string = environment.privateApi;
  private changeTheme: Subject<string> = new Subject<string>();
  public darkMode: boolean = false;
  public userTheme: string = environment.theme;
  public userPreferencesLoaded: boolean = false;
  public userNameClaim: string = "";
  public loading: boolean = false;
  public today: Date = new Date(Date.now());
  public purple: string = '#ACA2D0';
  public blue: string = '#628ef4';
  public gold: string = '#ffbe2e';
  public green: string = '#70e17b';
  public pink: string = '#e9695f';
  public orange: string = '#EC891D';
  public red: string = '#fc4132'

  public applicationPaymentsTitle = "Application Payments";

  stateList: string[] = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL",
    "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT",
    "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI",
    "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY", "American Samoa" ,
    "Commonwealth of the Northern Mariana Islands", "Guam", "Puerto Rico", "US Virgin Islands",
    "Federated States of Micronesia", "Republic of the Marshall Islands", "Republic of Palau"
  ];

  countyList: string[] = [
    "Baker", "Benton", "Clackamas", "Clatsop", "Columbia", "Coos", "Crook",
    "Curry", "Deschutes", "Douglas", "Gilliam", "Grant", "Harney", "Hood River",
    "Jackson", "Jefferson", "Josephine", "Klamath", "Lake", "Lane", "Lincoln",
    "Linn", "Malheur", "Marion", "Morrow", "Multnomah", "Polk", "Sherman",
    "Tillamook", "Umatilla", "Union", "Wallowa", "Wasco", "Washington", "Wheeler",
    "Yamhill"
  ];

  loremIpsum: string = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
  in culpa qui officia deserunt mollit anim id est laborum.`

  public dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private httpClient: HttpClient,
              public dialog: MatDialog,
              public toastr: ToastrService) { }

  public openConfirm(message: string): void{
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: message,
      minWidth: '300px',
      maxWidth: !this.mobile? '700px' : '300px',
      maxHeight: this.mobile? '600px' : '700px',
      panelClass: this.darkMode ? "theme-dark" : "",
    });
  }

  public confirmed(): Observable<any>{
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }));
  }

  get isMobile(): boolean {
    return this.mobile;
  }

  get counties(): string[] {
    return this.countyList;
  }

  get states(): string[] {
    return this.stateList;
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text);
    this.toastr.success("Copied!");
  }

  themeChanged$ = this.changeTheme.asObservable();

  toggleTheme() {
    this.darkMode = this.userTheme === 'dark';
    this.changeTheme.next(this.userTheme);
  }

  updateUser(): void{
    if(!this.userPreferencesLoaded){
      this.loading = true;
      this.getUserTheme().subscribe(
        response => {
          this.userTheme = response;
        },
        error => console.log('error', error),
        () => {
          this.toggleTheme();
          this.userPreferencesLoaded = true;
          this.loading = false;
        }
      );
    }
  }

  updateUserTheme(): void {
    this.httpClient.post<any>(this.api + "UserProfile/UpdateUserTheme/" + this.userTheme, null).subscribe(
      () => { },
      error => console.log('error', error)
    );
  }

  getUserTheme(): Observable<string> {
    return this.httpClient.get<string>(this.api + "UserProfile/GetUserTheme", { responseType: 'text' as 'json' });
  }

  setUserNameClaim(name: string) {
    this.userNameClaim = name;
  }

  isCancelled(response: any): boolean {
    return response === 'cancel' || response === null || response === '' || response === undefined;
  }
}
