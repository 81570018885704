<div fxLayout="column" [ngStyle]="{'--entity-color' : color}">
    <mat-card [style.background-color]="color">
        <mat-card-title>
            {{addressData.id !== 0 ? 'Edit Address ' + addressData.id : 'Add New Address'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">

        <form [formGroup]="addressDataForm">
            <div fxLayout="column" fxFlex>
                <mat-form-field>
                    <mat-label>Address Type</mat-label>
                    <mat-select formControlName="contactType">
                      <mat-option *ngFor="let type of getAddressTypes()" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>                     
            
                <div fxLayout="column" *ngIf="addressDataForm.get('contactType').value === 'Physical'">                                                        
                    <mat-checkbox  formControlName="isCurrentPhysical">
                        Is Current Physical Address?
                    </mat-checkbox>                                                          
                </div>                

                <div fxLayout="column" *ngIf="addressDataForm.get('contactType').value === 'Mailing'">           
                    <mat-checkbox  formControlName="isCurrentMailing">
                        Is Current Mailing Address?
                    </mat-checkbox>                                                          
                </div>                

                <mat-form-field>
                    <mat-label>Street</mat-label>
                    <input matInput placeholder="Street" formControlName="street">
                </mat-form-field>
            
                <mat-form-field>
                    <mat-label>City</mat-label>
                    <input matInput placeholder="City" formControlName="city">
                </mat-form-field>
        
                <mat-form-field>                    
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state" >
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>Zip</mat-label>
                    <input matInput placeholder="Zip" formControlName="zip">
                </mat-form-field>  
                
                <mat-form-field *ngIf="addressDataForm.get('state').value === 'OR'">
                    <mat-label>County</mat-label>
                    <mat-select formControlName="county" >
                      <mat-option>-- None --</mat-option>
                      <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                    </mat-select>
                </mat-form-field>   
            </div>
        </form>
      
    </div>
    <mat-card [style.background-color]="color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{addressData.id !== 0 ? 'Save' : 'Add'}}
        </button>        
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>
