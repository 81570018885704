<section class="p-b-16">
    <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
      <button type="button" mat-stroked-button color="primary" (click)="pdf.saveAs(personCertificateModel.certicationNumber +'.pdf')">
        Export to PDF
      </button>
  
      <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
    </div>
  </section>
  <mat-dialog-content>
    <kendo-pdf-export #pdf paperSize="letter" [scale]="0.75" margin="1cm" forcePageBreak=".page-break">
                      <div *ngIf="selectedCertificates" fxLayout="column" class="body">
                                <div class="middle-border">
                                    <div class="certificate">
                                    <div class="inner-border">
                                    <h1>State of Oregon</h1>
                                    <h1>Oregon Health Authority</h1>
                                    <p><strong>{{personCertificateModel.firstName}} {{personCertificateModel.lastName}}</strong></p>
                                    <p>is certified by the Oregon Health Authority to conduct Lead-Based Paint Activities</p>
                                    <p><strong>{{personCertificateModel.codeType}}</strong></p>
                                    <div class="signature">
                                        <p>Certification Number : {{personCertificateModel.certicationNumber}}</p>
                                        <p>Issuance Date : {{personCertificateModel.issueDate  | date: 'MM/dd/yyyy'}} </p>
                                        <p>Expiration Date : {{personCertificateModel.expirationDate | date: 'MM/dd/yyyy'}}</p>
                                    </div>
                                    <div class="wrap">
                                        <img src="../../../../../assets/images/ORSeal1.png" style="width: 10%;"/>
                                        <img src="../../../../../assets/images/ohaLogoTransparent.png" style="width: 20%;" />
                                    </div>
                            </div>
                        </div>
                    </div>
                  </div> 
                  <div *ngIf="selectedCertificates" class="page-break">
                    <br><br>
                  </div>                 
                  <div *ngIf="selectedBadges" class="badge">
                    <h1>{{personCertificateModel.firstName}} {{personCertificateModel.lastName}}</h1>
                    <p class="name">{{personCertificateModel.codeType}}</p>
                    <p class="company">Lead Based Paint Activites</p>
                    <p class="cert-number">CERTIFICATION: #{{personCertificateModel.certicationNumber}} {{personCertificateModel.expirationDate | date: 'MM/dd/yyyy'}}</p>
                  </div>
                 
                  <div *ngIf="selectedBadges || selectedCertificates" class="page-break">
                        <br><br>
                  </div>
                      <div *ngIf="selectedLetters">
                        <div class="letterhead">
                          <div class="address">
                              PUBLIC HEALTH DIVISION<br>
                              Center for Health Protection<br>
                              <hr/>
                              Tina Kotek, Governor<br>
                          </div>
                          <div class="logo">
                            <img src="../../../../../assets/images/ohaLogoTransparent.png" style="width: 20%;" />
                          </div>
                      </div>
                      <div class="bodyhead">
                        <div class="persondetail">
                            Sent by email<br>
                            {{personCertificateModel.firstName}} {{personCertificateModel.lastName}}<br>
                            {{firmName}}<br>
                            {{personAddress.street}}<br>
                            {{personAddress.city}} {{personAddress.state}} {{personAddress.zip}}<br>
                        </div>
                        <div class="bodyaddress">                          
                            800 NE Oregon Street, Suite 640<br>
                            Portland, OR 97232<br>
                            Phone: (971)673-0440<br>
                            Fax: (971)673-0457<br>
                            TTY Nonvoice: (971)673-0372<br>
                        </div>
                    </div>
                      <div class="letter-content">

                        <p><strong>RE: LEAD-BASED PAINT ACTIVITIES, INDIVIDUAL CERTIFICATION No. {{personCertificateModel.certicationNumber}}</strong></p>
                          
                          <p>
                            This letter is your official notification that you have met the certification requirements under Oregon Administrative Rule (OAR) 333-069 to 
                            conduct lead-based paint activities in Oregon, in the following discipline: <strong>{{personCertificateModel.codeType}}</strong>.
                          </p>
                          <p>
                            In addition to this 3-year certification by the Oregon Health Authority (OHA), 
                            you are required to be licensed annually by the Construction Contractors Board (CCB) 
                            if you plan to conduct lead-based paint activities in Oregon unless specifically exempt from their licensing. 
                            For example, units of government/public sector organizations are typically exempt from CCB licensing. 
                            This applies to both firms and individuals. This license is different than, and in addition to, the standard contractor license issued by CCB. 
                            For more information on CCB lead-based paint licensing, visit their web site at www.oregon.gov/CCB or call 503-378-4621.
                          </p>  

                          <p>
                            As a certified individual in Oregon, you must meet all the requirements of OAR 333-069 and 40 Code of Federal Regulations 745 Subparts D and L, 
                            as adopted by reference, including work practice standards for conducting lead-based paint activities in target housing and child-occupied facilities. 
                            As a certified individual, you must perform work under a firm that is certified by OHA for conducting lead-based paint activities. 
                           <strong> Also, you are required to attend 8-hour refresher training at least once every three years.</strong>                            
                          </p>
                          <p>
                            Please note that your badge and certificate will expire on {{personCertificateModel.expirationDate | date: 'MM/dd/yyyy'}}.
                             Your badge will be mailed to you separately and must be on you while performing lead-based paint activities. 
                             If you wish to maintain your certification after {{personCertificateModel.expirationDate | date: 'MM/dd/yyyy'}}, 
                             submit an application postmarked at least 60 days before expiration and document that your lead-based paint activities training is current.
                          </p>
                          <p>
                            As a public service the Authority publishes a list of individuals performing lead-based paint activities in Oregon. 
                            The list is distributed statewide to consumers interested in lead-based paint services.
                             Only those individuals that are certified by the state are eligible to be on this list.
                          </p>
                          <p>
                            If you have any questions concerning your certification or other aspects of the Lead-Based Paint Program, please contact program staff at 971-673-0440.
                          </p>

                          <p>Sincerely,</p>
                          <p>{{loggedUser}}<br>
                          Lead-Based Paint Program<br>
                          Oregon Health Authority</p>
                      </div>
                    </div>
                    
    </kendo-pdf-export>
  </mat-dialog-content>