import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IComplaint } from 'app/models/complaint/complaint';
import { CommonDataService } from 'app/services/common/common-data.service';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { SharedService } from 'app/services/core/shared.service';
import { AddEditComplaintComponent } from './add-edit-complaint/add-edit-complaint.component';
import { getStatusColor } from '@shared/utils/app-static-data';


@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {
   public statusColor = getStatusColor;
    public isMobile: boolean = false;
    public loading: boolean = true;
    public pageSize: number = 50;  
    public complaints: IComplaint[] = [];
    public dataSource: MatTableDataSource<any>;
    public filterString: string = '';
    public filteredComplaints = [];
    public filteredStatus = new FormControl('Open');
    public filteredComplaintType = new FormControl('All');
  
    @ViewChild('TablePaginator') paginator: MatPaginator;
    @ViewChild('TableSort') sort: MatSort;
    
    complaintsTable = {
      icon: 'recent_actors',
      title:'Complaints',
      layout:{
        columns:['id', 'complainteeName', 'complainantName', 'status', 'complaintDate', 'lastUpdatedBy', 'lastUpdated'],
        container:[
          {displayName:'Id',columnName:'id', type:'string', size:'5'},
          {displayName:'Complaint Source Name',columnName:'complainteeName', type:'string', size:'20'},
          {displayName:'Complaint Target Name',columnName:'complainantName', type:'string', size:'20'},        
          {displayName:'Status',columnName:'status', type:'string', size:'10'},          
          {displayName:'Complaint Date',columnName:'complaintDate', type:'date', size:'8'},     
          {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'15'},           
          {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'8'},          
        ],
        data: []      
      }
    }
  
  constructor(private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public complaintService: ComplaintService) 
  {
    this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      });
      this.loadComplaints();
  }

  ngOnInit(): void {
  }

  loadComplaints(): void {
    this.filterString = this.commonService.complaintsFilter.filterString;
    this.filteredStatus = this.commonService.complaintsFilter.filteredStatus;
    this.filteredComplaintType = this.commonService.complaintsFilter.filteredComplaintType;

    this.complaintService.getComplaints().subscribe(result=>{      
      this.complaints = result;    
      this.filteredComplaints = result;  
      this.filterTable();
      this.loading = false;
    },error=>{})    
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredComplaints);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  getComplaintStatuses(){
    return ['Open','Closed','Both'];
  }

  getComplaintTypes(){
    return ['Atp','Firm','Individual','All'];
  }

  filterTable() { 
    this.commonService.complaintsFilter.filterString = this.filterString;
    this.commonService.complaintsFilter.filteredStatus = this.filteredStatus;
    this.commonService.complaintsFilter.filteredComplaintType = this.filteredComplaintType;
    
    var stringFilter = this.filterString.trim().toLowerCase()
    this.filteredComplaints = this.complaints.filter(
      x=> x.id.toString().includes(stringFilter) || 
      x.complainteeName.toLowerCase().includes(stringFilter) 
    );

    if (this.filteredStatus.value !== 'Both'){
      if(this.filteredStatus.value === 'Open' ){
        this.filteredComplaints = this.filteredComplaints.filter(x=>x.status === 'Open');
      }else if(this.filteredStatus.value === 'Closed'){ 
        this.filteredComplaints = this.filteredComplaints.filter(x=>x.status === 'Closed');
      }     
    } 
    if (this.filteredComplaintType.value !== 'All'){
      if(this.filteredComplaintType.value === 'Atp' ){
        this.filteredComplaints = this.filteredComplaints.filter(x=>x.associationTypes.includes('ATP'));
      }else if(this.filteredComplaintType.value === 'Firm'){ 
        this.filteredComplaints = this.filteredComplaints.filter(x=>x.associationTypes.includes('Firm'));
      } else if(this.filteredComplaintType.value === 'Individual'){ 
        this.filteredComplaints = this.filteredComplaints.filter(x=>x.associationTypes.includes('Person'));
      }    
    } 
    this.updateDataSource();
  }

  displayLimit(text: any, limit: string){
    var newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  editComplaint(complaint: IComplaint) {    
    if(complaint != null && complaint.id > 0){
      this.complaintService.getComplaintById(complaint.id).subscribe(result=>{   
        const dialogRef = this.dialog.open(AddEditComplaintComponent, {
          width: '90%',
          data: {complaint : result },
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {  
          this.loadComplaints();    
        });      
      },error=>{  
      });   
      }else{ const dialogRef = this.dialog.open(AddEditComplaintComponent, {
        width: '90%',
        data: {complaint : null },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {  
        this.loadComplaints();    
      });
    }   
  }
}
