import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { AddFirmAffiliationComponent } from 'app/components/individual/add-edit-individual/add-firm-affiliation/add-firm-affiliation.component';
import { IPerson } from 'app/models/People/person';
import { IAtp } from 'app/models/atp/atp';
import { IAtpPerson } from 'app/models/atp/atpPerson';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { AtpService } from 'app/services/atp/atp.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-staff',
  templateUrl: './add-edit-atp-staff.component.html',
  styleUrls: ['./add-edit-atp-staff.component.scss']
})
export class AddEditAtpStaffComponent {
  public personList:IPerson[] = [];
  public filteredPersonList: IPerson[] = [];
  public selectedPerson:IPerson;  
  public isContact:boolean = false;
  public isPrimaryInstructor:boolean = false;
  public isInstructor:boolean = false;
  public isTrainingManager:boolean = false;
  public uiData = LEAD_BASED_PAINT;
  private debounceString: Subject<string> = new Subject<string>();
  public peopleFilter: IPersonFilter = {filterAffiliation: '', filterStatus: '', filterString: '', expirationStartDate: '', expirationEndDate: ''}
  public atpPersonToAdd: IAtpPerson = {id: 0, person: null, atp: null, personType: null, isContact: false, isInstructor: false, isPrimaryInstructor: false, isTrainingManager: false};

  public createNew = 'Create a New Person'
  public personDataForm = null;

  constructor(private dialogRef: MatDialogRef<AddFirmAffiliationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{currentPeople:IPerson [],atp:IAtp, person: IAtpPerson},
    public personService: PersonService,
    public atpService: AtpService,
    private toastr: ToastrService,
    private renderer: Renderer2, 
    private el: ElementRef)  
  {
    this.initializeDebounceFunction();
    this.setPersonDataFormFields();

    this.atpPersonToAdd.atp = this.data.atp;
    if(this.data.person !== undefined){      
      this.isContact = this.data.person.isContact;
      this.isInstructor = this.data.person.isInstructor;
      this.isPrimaryInstructor = this.data.person.isPrimaryInstructor;
      this.isTrainingManager = this.data.person.isTrainingManager;
      this.selectedPerson = this.data.person.person;
      this.atpPersonToAdd = this.data.person;

      this.filteredPersonList = [this.data.person.person];
    }
    else{
      this.resetStaffList();
    }
  }

  initializeDebounceFunction(): void {
    this.debounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {      
      this.filterPeople(value.trim().toLowerCase());     
    });
  }

  updateDebounceString(event: any){    
    this.debounceString.next(event.target.value); 
    this.updateScrollContainerHeight();   
  }

  resetStaffList(): void {
    this.personList = [{id: 0, firstName: this.createNew, lastName: '', middleName: '', title: '', ssn: '', dateOfBirth: '', inactive: false, response: 'Unk', certificates: null}];        
    this.filteredPersonList = this.personList;
  }

  filterPeople(value: any) {
    if (value.length < 3) {
      this.resetStaffList();
    }
    else{
      this.peopleFilter.filterString = value;
      this.personService.getPeopleFiltered(this.peopleFilter).subscribe(result=>{
        this.personList = this.data.currentPeople.length ? result.filter(person => !this.data.currentPeople.some(item => item.id === person.id)) : result;                          
        this.filteredPersonList = this.personList; 
      },error=>{this.toastr.error("An error occured getting the atp staff: ", error);});
    }
  }  

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if(this.filteredPersonList.some(person => person.id === this.selectedPerson?.id)){    
      if (this.selectedPerson?.id === 0) {
        this.savePersonData();
      }
      else{
        this.saveATPMember();
      }
    }
    else{
      this.toastr.error("Failed to find the selected person")
    }
  }

  savePersonData(){
    this.selectedPerson = {id: 0, firstName: this.personDataForm.controls.firstName.value, lastName: this.personDataForm.controls.lastName.value, middleName: this.personDataForm.controls.middleName.value, title: this.personDataForm.controls.title.value, ssn: this.personDataForm.controls.ssn.value, dateOfBirth: this.personDataForm.controls.dateOfBirth.value, inactive: false, response: 'Unk', certificates: null};        
    this.personService.savePerson(this.selectedPerson).subscribe(result=>{
      this.selectedPerson = result;
      this.saveATPMember();
    },error=>{this.toastr.error("An error occured saving the new person: ", error);});    
  }

  saveATPMember(): void {
    this.atpPersonToAdd.person = this.selectedPerson;
    this.atpPersonToAdd.isContact = this.isContact;
    this.atpPersonToAdd.isInstructor = this.isInstructor;
    this.atpPersonToAdd.isPrimaryInstructor = this.isPrimaryInstructor;
    this.atpPersonToAdd.isTrainingManager = this.isTrainingManager;    
    this.atpService.saveATPPerson(this.atpPersonToAdd).subscribe(result=>{
      this.toastr.success("Successfully saved staff member")
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("An error occured saving the staff member: ", error);
    });      
  }

  updateScrollContainerHeight() {
    if (this.selectedPerson.id === 0) {
      const scrollContainer = this.el.nativeElement.querySelector('.scroll-container');
      this.renderer.setStyle(scrollContainer, 'height', '72vh');
    }
    else {
      const scrollContainer = this.el.nativeElement.querySelector('.scroll-container');
      this.renderer.setStyle(scrollContainer, 'height', '22vh');
    }
  }

  setPersonDataFormFields(){
    this.personDataForm = new UntypedFormGroup({      
      firstName: new UntypedFormControl("", [Validators.required]),
      lastName: new UntypedFormControl("", [Validators.required]),
      middleName: new UntypedFormControl(""),
      title: new UntypedFormControl(""),
      ssn: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{2})[-]?([0-9]{4}).*$/)]),
      dateOfBirth: new UntypedFormControl(null),            
    }); 
  }
  
  displayStaffMember(staffMember: any): string {
    if (staffMember) {
        if (staffMember.id === 0) {
            return staffMember.firstName;
        } else {
            return `${staffMember.id}: ${staffMember.firstName} ${staffMember.lastName}`;
        }
    } else {
        return '';
    }
  }
}
