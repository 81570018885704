<div fxLayout="column">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            {{'Upload Roster'}}
        </mat-card-title>
    </mat-card>
    <div>
        <mat-card>
            <mat-card-header>
            <mat-card-title>Upload Student Roster File</mat-card-title>
            </mat-card-header>
            <mat-card-content>
            <input type="file" (change)="onFileChange($event)">
            </mat-card-content>
        </mat-card>
        &nbsp;&nbsp;
        <mat-card *ngIf="didUpload || fileProcessed">
            <mat-card-content>
                <div fxLayout="column" fxFlex="100">                                     
                    <span *ngIf="fileProcessed"><strong>Students To Upload: {{toUploadCount}}</strong></span>                           
                    <span *ngIf="didUpload"><strong>Successfully Uploaded: {{successfullyUploadedCount}} </strong></span>                           
                    <span *ngIf="failedToUploadCount > 0"><strong>Failed to Upload: {{failedToUploadCount}}</strong></span>                           
                </div>                
            </mat-card-content>
        </mat-card>
    </div>
  <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
    <button mat-raised-button color="primary" aria-label="Save" (click)="uploadPrompt()">
        {{'Upload'}}
    </button>
    <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
</mat-card>
</div>