<div>
  <mat-card
  fxFlex="18"
  fxFlex.lt-sm="100"
  [ngClass]="{
    'bg-gray-700': sharedService.darkMode,
    'bg-indigo-50': !sharedService.darkMode
  }"
>
  <mat-card-title>
    API Information
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">API Name</span>
        <span fxFlex></span>
        <span>{{appInfo.name}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">API Version</span>
        <span fxFlex></span>
        <span>{{appInfo.version}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">API Environment</span>
        <span fxFlex></span>
        <span>{{appInfo.environment}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">API Build</span>
        <span fxFlex></span>
        <span>{{appInfo.build}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">API Release</span>
        <span fxFlex></span>
        <span>{{appInfo.release}}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
</div>

<div>
<mat-card
  fxFlex="18"
  fxFlex.lt-sm="100"
  [ngClass]="{
    'bg-gray-700': sharedService.darkMode,
    'bg-indigo-50': !sharedService.darkMode
  }"
>
  <mat-card-title>
    UI Information
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">UI Version</span>
        <span fxFlex></span>
        <span>{{environment.version}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">UI Environment</span>
        <span fxFlex></span>
        <span *ngIf="environment.production">Production</span>
        <span *ngIf="!environment.production">Non Production</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">UI Build</span>
        <span fxFlex></span>
        <span>{{environment.build}}</span>
      </div>
      </div>
  </mat-card-content>
</mat-card>
</div>