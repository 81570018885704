import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IPerson } from 'app/models/People/person';
import { IPersonAddress } from 'app/models/People/personAddress';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpCourseStudent } from 'app/models/atp/atpCourseStudent';
import { IAddress } from 'app/models/common/address';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

interface StudentData {
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  courseCertificateNum: string;
  courseTestScore: string;
  photoImageFileName: string;
  programName: string;
  programContactName: string;
  programAccreditationNum: string;
  programMailingAddress: string;
  programPhoneNumber: string;
  courseDiscipline: string;
  courseType: string;
  courseLeadInstructor: string;
  courseStartDate: string;
  courseEndDate: string;
  courseLocation: string;
}

@Component({
  selector: 'app-atp-course-student-upload',
  templateUrl: './atp-course-student-upload.component.html'  
})
export class AtpCourseStudentUploadComponent {
  public uiData = LEAD_BASED_PAINT;
  public toUploadCount = 0;
  public successfullyUploadedCount = 0;
  public failedToUploadCount = 0;
  public didUpload = false;
  public fileProcessed = false;
  public file: File | null = null;
  private studentDataList: StudentData[] = [];  

  constructor(private personService:PersonService,
    @Inject(MAT_DIALOG_DATA) public data:{course:IAtpCourse},
    public dialog: MatDialog, 
    private atpService:AtpService,
    private commonService:CommonDataService,
    private toastr: ToastrService) { }
  
  onFileChange(event: any) {
    this.fileProcessed = false;
    this.file = event.target.files[0];
    this.processFile();
    this.fileProcessed = true;
  }

  processFile() {
    if (this.file) {  
      this.studentDataList = [];    
      const reader: FileReader = new FileReader();
  
      reader.onload = (e: any) => {
        const data: string = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
        const sheetName: string = workbook.SheetNames[0];
        const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
        const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
        // Skip the first 20 rows to get to the header
        const headerRowIndex = 20;
        const headerRow = excelData[headerRowIndex - 1]; // Header row
        const headers: string[] = headerRow.slice(1); // Skip the first cell
  
        for (let i = headerRowIndex; i < excelData.length; i++) {
          const rowData = excelData[i];
          const studentData: StudentData = {} as StudentData;
          let skipRow = false; // Flag to skip adding data

          headers.forEach((header, index) => {
            const columnName = this.mapColumnName(header);
            if (columnName === 'dateOfBirth' || columnName === 'courseStartDate' || columnName === 'courseEndDate') {
              studentData[columnName] = this.convertToDate(rowData[index + 1]); // Skip the first cell
            } else if (columnName === 'courseTestScore') {              
              studentData[columnName] = this.convertTestScore(rowData[index + 1]);
            }else {
              studentData[columnName] = rowData[index + 1] || ''; // Skip the first cell
            }

            // Check if first name or last name is empty
            if ((columnName === 'firstName' || columnName === 'lastName') && !studentData[columnName]) {
              skipRow = true;
            }
          });
          
          if (!skipRow) {
            this.studentDataList.push(studentData);
          }
        }  
        this.toUploadCount = this.studentDataList.length;      
      };
  
      reader.readAsBinaryString(this.file);      
    }    
  }  

  mapColumnName(excelColumnName: string): keyof StudentData {    
    switch (excelColumnName) {
      case 'Last Name':
        return 'lastName';
      case 'First Name':
        return 'firstName';
      case 'Date of Birth':
        return 'dateOfBirth';
      case 'Street Address':
        return 'streetAddress';
      case 'City':
        return 'city';
      case 'State':
        return 'state';
      case 'Zip Code':
        return 'zipCode';
      case 'Course Certificate #':
        return 'courseCertificateNum';
      case 'Test Score':
        return 'courseTestScore';
      case 'Photo File Name':
        return 'photoImageFileName';
      default:
        return '' as keyof StudentData;
    }
  }
  
  convertToDate(value: number): string {  
    if(value !== undefined && value !== null){
      const excelEpoch = new Date(Date.UTC(1899, 11, 30)).getTime();
      const milliseconds = (value) * 24 * 3600 * 1000 + excelEpoch;    
      const date = new Date(milliseconds);      
      const formattedDate = date.toISOString().slice(0, 19);
      return formattedDate;
    }
    return new Date().toISOString().slice(0, 19);
  }
  
  convertTestScore(value: any): string {    
    if(value !== undefined && value !== null){
      let numericValue = parseFloat(value);
      if(numericValue <= 1) {
        numericValue *= 100;
      }      
      return numericValue.toString();
    }
    return null;
}

  uploadPrompt(){    
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Upload</strong> the Roster?',title: 'Upload the Roster'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.processData();
      }
    })    
  }

  processData() { 
    this.personService.getPeople().subscribe(result=>{      
      for(let studentData of this.studentDataList){        
        var existingPerson = result.filter(x=>x.lastName?.toLowerCase() === studentData?.lastName?.toLowerCase() && x?.firstName?.toLowerCase() === studentData?.firstName?.toLowerCase() && x?.dateOfBirth?.split("T")[0] === studentData?.dateOfBirth?.split("T")[0])[0];
        if(existingPerson !== undefined){
          this.saveCourseStudent(existingPerson, studentData);
        }
        else{
          var newPerson: IPerson = {
            id: 0,
            firstName: studentData.firstName,
            lastName: studentData.lastName,
            dateOfBirth: studentData.dateOfBirth,
            title: "",
            middleName: "",
            ssn: "",
            inactive: false,
            response: "",    
            certificates: null,        
          }

          var newAddress: IAddress = {
            id: 0,
            state: studentData.state,
            street: studentData.streetAddress,
            city: studentData.city,
            contactType: "Work",
            county: "",
            zip: studentData.zipCode.toString()
          }

          var newPersonAddress: IPersonAddress = {
            id: 0,
            person: null,
            address: null,
            isCurrentMailing: true,
            isCurrentPhysical: false,
          }

          this.personService.savePerson(newPerson).subscribe(result=>{
            newPersonAddress.person = result;
            this.saveCourseStudent(result, studentData);            
            this.commonService.saveAddress(newAddress).subscribe(result=>{
              newPersonAddress.address = result;              
              this.personService.savePersonAddress(newPersonAddress).subscribe(result=>{},error=>{
                this.failedToUploadCount++;
                this.toastr.error("Failed to save person address data");
              })},error=>{
                this.failedToUploadCount++;
                this.toastr.error("Failed to save address data");
              })
          },error=>{
            this.failedToUploadCount++;
            this.toastr.error("Failed to save person data");
          })          
        }
      }
      this.toastr.success("Roster Upload Complete");
      this.didUpload = true;
    },error=>{
      this.toastr.error("Failed to get people data");
    })    
  }

  saveCourseStudent(student: IPerson, studentData: any) {    
    var atpCourseStudent:IAtpCourseStudent = {
      id: 0,
      atpCourse: this.data.course,
      student: student, 
      photoImage: studentData.photoImageFileName,
      courseCertificateNumber: studentData.courseCertificateNum.toString(),
      testScore: parseInt(studentData.courseTestScore),      
    }

    this.atpService.saveATPCourseStudent(atpCourseStudent).subscribe(result=>{
      this.successfullyUploadedCount++;
    },error=>{
      this.failedToUploadCount++;
    })
  }
}
