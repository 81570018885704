import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '@env/environment';
import { IAtp } from 'app/models/atp/atp';
import { IAtpAccreditation } from 'app/models/atp/atpAccreditation';
import { IAtpAccreditationDocument } from 'app/models/atp/atpAccreditationDocument';
import { IAtpAddress } from 'app/models/atp/atpAddress';
import { IAtpAudit } from 'app/models/atp/atpAudit';
import { IAtpAuditAction } from 'app/models/atp/atpAuditAction';
import { IAtpAuditDocument } from 'app/models/atp/atpAuditDocument';
import { IAtpContactInformation } from 'app/models/atp/atpContactInformation';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpCourseAddress } from 'app/models/atp/atpCourseAddress';
import { IAtpCourseStudent } from 'app/models/atp/atpCourseStudent';
import { IAtpDeficiency } from 'app/models/atp/atpDeficiency';
import { IAtpNote } from 'app/models/atp/atpNote';
import { IAtpPerson } from 'app/models/atp/atpPerson';
import { IAtpStudentFilter } from 'app/models/filters/atpStudentFilter';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class AtpService {
  private api: string = environment.privateApi;
  actionType: Array<String>[] = [];
  errorMessages = [];

  public atpStudentFilterString: string = '';
  public atpStudentFilterATPString: string = '';
  public atpStudentSelectedDisciplines = new FormControl([]);  
  public atpStudentStartDate = new Date(new Date().setDate(new Date().getDate()-90))
  public atpStudentEndDate = new Date();

  constructor(private http?: HttpClient) { }

  getATPs(): Observable<IAtp[]> {
    return this.http.get<IAtp[]>(environment.privateApi + 'Atp/AllATPs', { withCredentials: true });
  }

  getATPById(id: number): Observable<IAtp> {    
    return this.http.get<IAtp>(this.api + 'Atp/GetATPById/' + id, { withCredentials: true });
  }

  getATPByAccreditationId(id: number): Observable<IAtp> {    
    return this.http.get<IAtp>(this.api + 'Atp/GetATPByAccreditationId/' + id, { withCredentials: true });
  }

  saveATP(atp: IAtp): Observable<any> {
    return this.http.post(environment.privateApi + 'Atp/SaveATP', atp, httpOptions);
  }

  getATPAddresses(id:number): Observable<IAtpAddress[]> {    
    return this.http.get<IAtpAddress[]>(this.api + 'Atp/GetATPAddresses/' + id, { withCredentials: true });
  }

  saveATPAddress(address: IAtpAddress): Observable<any> {
    return this.http.post<any>(environment.privateApi + 'Atp/SaveATPAddress', address, httpOptions);
  }

  getATPContactInformation(id:number): Observable<IAtpContactInformation> {    
    return this.http.get<IAtpContactInformation>(this.api + 'Atp/GetATPContactInformation/' + id, { withCredentials: true });
  }

  saveATPContactInformation(contactInformation: IAtpContactInformation): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPContactInformation', contactInformation, httpOptions);
  }

  getATPPeople(atpId:number): Observable<IAtpPerson[]> {    
    return this.http.get<IAtpPerson[]>(this.api + 'Atp/GetATPPeople/' + atpId, { withCredentials: true });
  }

  saveATPPerson(atpPerson: IAtpPerson): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPPerson', atpPerson, httpOptions);
  }

  deleteATPPerson(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPPerson/' + id, httpOptions);
  }

  getATPCourses(atpId:number): Observable<IAtpCourse[]> {    
    return this.http.get<IAtpCourse[]>(this.api + 'Atp/GetATPCourses/' + atpId, { withCredentials: true });
  }

  saveATPCourse(atpCourse: IAtpCourse): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPCourse', atpCourse, httpOptions);
  }

  getATPCourseAddresses(atpId:number): Observable<IAtpCourseAddress[]> {    
    return this.http.get<IAtpCourseAddress[]>(this.api + 'Atp/GetATPCourseAddresses/' + atpId, { withCredentials: true });
  }

  saveATPCourseAddress(atpCourse: IAtpCourseAddress): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPCourseAddress', atpCourse, httpOptions);
  }

  getATPAccreditations(atpId:number): Observable<IAtpAccreditation[]> {    
    return this.http.get<IAtpAccreditation[]>(this.api + 'Atp/GetATPAccreditations/' + atpId, { withCredentials: true });
  }

  saveATPAccreditation(atpAccreditation: IAtpAccreditation): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPAccreditation', atpAccreditation, httpOptions);
  }

  deleteATPAccreditation(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPAccreditation/' + id, httpOptions);
  }

  getATPAccreditationDocuments(accreditationId:number): Observable<IAtpAccreditationDocument[]> {    
    return this.http.get<IAtpAccreditationDocument[]>(this.api + 'Atp/GetATPAccreditationDocuments/' + accreditationId, { withCredentials: true });
  }

  saveATPAccreditationDocument(atpAccreditationDocument: IAtpAccreditationDocument): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPAccreditationDocument', atpAccreditationDocument, httpOptions);
  }

  deleteATPAccreditationDocument(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPAccreditationDocument/' + id, httpOptions);
  }

  getATPCourseStudents(courseId:number): Observable<IAtpCourseStudent[]> {    
    return this.http.get<IAtpCourseStudent[]>(this.api + 'Atp/GetATPCourseStudents/' + courseId, { withCredentials: true });
  }

  getAllStudents(filter: IAtpStudentFilter): Observable<IAtpCourseStudent[]> {    
    let params = new HttpParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key] !== null && filter[key] !== undefined) {
        params = params.append(key, filter[key]);
      }
    }    
    return this.http.get<IAtpCourseStudent[]>(this.api + 'Atp/GetAllStudents', { params, withCredentials: true });
  }

  getATPCourseStudentsByStudentId(studentId:number): Observable<IAtpCourseStudent[]> {        
    return this.http.get<IAtpCourseStudent[]>(this.api + 'Atp/GetATPCourseStudentsByStudentId/' + studentId, { withCredentials: true });
  }

  saveATPCourseStudent(atpAccreditationDocument: IAtpCourseStudent): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPCourseStudent', atpAccreditationDocument, httpOptions);
  }

  deleteATPCourseStudent(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPCourseStudent/' + id, httpOptions);
  }
  
  saveATPAudit(auditData: IAtpAudit): Observable<any> {
    return this.http.post(this.api + 'Atp/SaveAtpAudit', auditData, httpOptions);
  }

  getATPAudits(courseId:number): Observable<IAtpAudit[]> {    
    return this.http.get<IAtpAudit[]>(this.api + 'Atp/GetATPAudits/' + courseId, { withCredentials: true });
  }

  deleteATPAudit(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPAudit/' + id, httpOptions);
  }

  deleteATPAuditAction(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPAuditAction/' + id, httpOptions);
  }
  
  saveATPAuditAction(auditData: IAtpAuditAction): Observable<any> {
    return this.http.post(this.api + 'Atp/SaveAtpAuditAction', auditData, httpOptions);
  }

  getATPAuditActions(courseId:number): Observable<IAtpAuditAction[]> {    
    return this.http.get<IAtpAuditAction[]>(this.api + 'Atp/GetATPAuditActions/' + courseId, { withCredentials: true });
  }

  getATPAuditDocuments(auditId:number): Observable<IAtpAuditDocument[]> {    
    return this.http.get<IAtpAuditDocument[]>(this.api + 'Atp/GetATPAuditDocuments/' + auditId, { withCredentials: true });
  }

  saveATPAuditDocument(atpAuditDocument: IAtpAuditDocument): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPAuditDocument', atpAuditDocument, httpOptions);
  }

  deleteATPAuditDocument(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPAuditDocument/' + id, httpOptions);
  }

  getATPNotes(atpId:number): Observable<IAtpNote[]> {    
    return this.http.get<IAtpNote[]>(this.api + 'Atp/GetATPNotes/' + atpId, { withCredentials: true });
  }

  getATPAccreditationNotes(atpId:number): Observable<IAtpNote[]> {    
    return this.http.get<IAtpNote[]>(this.api + 'Atp/GetATPAccreditationNotes/' + atpId, { withCredentials: true });
  }

  saveATPNote(atpNote: IAtpNote): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPNote', atpNote, httpOptions);
  }
  
  deleteATPNote(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPNote/' + id, httpOptions);    
  }

  getATPDeficiencies(atpId:number): Observable<IAtpDeficiency[]> {    
    return this.http.get<IAtpDeficiency[]>(this.api + 'Atp/GetATPDeficiencies/' + atpId, { withCredentials: true });
  }

  getATPAccreditationDeficiencies(atpId:number): Observable<IAtpDeficiency[]> {    
    return this.http.get<IAtpDeficiency[]>(this.api + 'Atp/GetATPAccreditationDeficiencies/' + atpId, { withCredentials: true });
  }

  saveATPDeficiency(atpAuditDeficiency: IAtpDeficiency): Observable<any> { 
    return this.http.post(this.api + 'Atp/SaveATPDeficiency', atpAuditDeficiency, httpOptions);
  }
  
  deleteATPDeficiency(id: number): Observable<any> {    
    return this.http.get(this.api + 'Atp/DeleteATPDeficiency/' + id, httpOptions);
  }
}
