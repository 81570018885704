<div fxLayout="column">
    <mat-card [style.background-color]="uiData.firm.color">
        <mat-card-title>
            {{deficiency.id === 0 ? 'Add Deficiency' : 'Edit Deficiency'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">      
        <form [formGroup]="deficiencyForm" fxLayout="column">                        
            <div fxFlex="100%" fxLayout="column" *ngFor="let field of objectKeys(formFields)">
                <ng-container [ngSwitch]="formFields[field].type">
                    <div *ngSwitchCase="'text'">
                        <mat-form-field fxFlex>
                            <textarea matInput [formControlName]="field" [placeholder]="formFields[field].name" style="height: 200px;"></textarea>
                        </mat-form-field>
                    </div>
                    <div *ngSwitchCase="'boolean'">
                        <mat-checkbox [formControlName]="field">
                            {{ formFields[field].name }}
                        </mat-checkbox>
                    </div>
                </ng-container>
            </div>                                                                  
        </form>
    </div>
    <mat-card [style.background-color]="uiData.firm.color" fxLayout="row wrap" fxLayoutGap="8px">                
        <button mat-raised-button color="primary" (click)="onSubmit()">{{deficiency.id === 0 ? 'Add' : 'Update'}}</button>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
    </mat-card>
</div>