import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT, TESTING_CODE_TYPES } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { AddEditCourseStudentComponent } from 'app/components/atp/add-edit-atp/add-edit-course-student/add-edit-course-student.component';
import { IPerson } from 'app/models/People/person';
import { IPersonTesting } from 'app/models/People/personTesting';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-individual-testing',
  templateUrl: './add-edit-individual-testing.component.html',
  styleUrls: ['./add-edit-individual-testing.component.scss']
})
export class AddEditIndividualTestingComponent implements OnInit {
  public personTestingDataForm = null;
  public personTestingData: IPersonTesting = null;  
  public uiData = LEAD_BASED_PAINT;
  public filteredTestLocations: Observable<string[]>;

  constructor(private personService:PersonService,
    @Inject(MAT_DIALOG_DATA) public data:{testing:IPersonTesting, person:IPerson},
    public sharedService: SharedService,
    private toastr: ToastrService,   
    public dialog: MatDialog, 
    private dialogRef: MatDialogRef<AddEditCourseStudentComponent>,) { }

    ngOnInit(): void {
      this.setTestingDataFormFields();
      this.loadData();
      this.filteredTestLocations = this.personTestingDataForm.controls['testLocation'].valueChanges.pipe(
        startWith(''),
        map(value => this.filterTestLocations(value as string))
      );
    }
  
    loadData(){
      if(this.data.testing !== null){
        this.personTestingData = this.data.testing;         
      }
      else{
        this.personTestingData = {
          id: 0,       
          person: this.data.person,
          codeType: "",   
          stage: "",
          testDate: new Date().toISOString().substring(0,10),
          testLocation: "",
          testScore: 0,
          testSerial: "",
          testVersion: "",
          passFail: "",          
        };     
      }
  
      for (const controlName in this.personTestingData) {
        if (this.personTestingDataForm.controls.hasOwnProperty(controlName)) {        
          this.personTestingDataForm.controls[controlName].setValue(this.personTestingData[controlName]);          
        }
      }    
    }
  
    filterTestLocations(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.getTestLocations().filter(option => option.toLowerCase().includes(filterValue));
    }

    setTestingDataFormFields(){       
      this.personTestingDataForm = new UntypedFormGroup({
        codeType: new UntypedFormControl(""),        
        testDate: new UntypedFormControl(null,[Validators.required]),
        testLocation: new UntypedFormControl(""),
        testScore: new UntypedFormControl(0,[Validators.required]),
        testSerial: new UntypedFormControl(""),
        testVersion: new UntypedFormControl(""),
        passFail: new UntypedFormControl(""),
      }); 
    }
  
    updateTestingData(): void {    
      for (const controlName in this.personTestingData) {
        if (this.personTestingDataForm.controls.hasOwnProperty(controlName)) {
          this.personTestingData[controlName] = this.personTestingDataForm.controls[controlName].value;        
        }
      } 
    }
    
    getTestDisciplines(){
      return Object.values(TESTING_CODE_TYPES);
    }

    getTestVersions(){
      return['1','2','3','4','5']
    }

    getTestLocations(){
      return['Portland','Medford','Klamath Falls','Eugene','Salem','Bend','La Grande','Roseburg','Astoria','Pendleton']
    }

    getPassFail(){
      return['Pass','Fail']
    }

    savePrompt(){
      var isNew = this.personTestingData.id === 0;
      const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
        width: '400px',      
        data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> the Testing Data?' : 'Are you sure you want to <strong>Update</strong> the Testing Data?',title: isNew ? 'Add the Testing Data' : 'Update the Testing Data'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.updateTestingData();
          this.saveData();
        }
      })
    }

    saveData(){      
      this.personService.savePersonTesting(this.personTestingData).subscribe(
        (data) => {
          this.toastr.success('Testing saved successfully');
          this.dialogRef.close(true);
        },
        (error) => {
          this.toastr.error('Error saving Testing');
        }
      );
    }
}
