import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CERTIFICATE_STAGE_TYPES, CODE_TYPES, LEAD_BASED_PAINT, STAGE_TYPES } from '@shared/utils/app-static-data';
import { IPerson } from 'app/models/People/person';
import { IPersonCertificate } from 'app/models/People/person-certificate';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-individual-certificate',
  templateUrl: './add-edit-individual-certificate.component.html',
  styleUrls: ['./add-edit-individual-certificate.component.scss']
})
export class AddEditIndividualCertificateComponent implements OnInit {  
  public codeType: string[] = Object.values(CODE_TYPES);
  public stageType: string[] = Object.values(CERTIFICATE_STAGE_TYPES);
  public certificateData:IPersonCertificate = {id: 0, person: null, stage: "", codeType: "", controlNumber: "", issueDate: null, recertificationDate: null, expirationDate: null, dateOfLetter: null, application: null};
  public uiData = LEAD_BASED_PAINT;
  public certificateForm = new UntypedFormGroup({      
    stage: new UntypedFormControl({value: "", disabled: this.data.canEdit}, [Validators.required]),
    codeType: new UntypedFormControl({value: "", disabled: this.data.canEdit}, [Validators.required]),
    controlNumber: new UntypedFormControl({value: "", disabled: this.data.canEdit}, [Validators.required]),
    issueDate: new UntypedFormControl({value: "", disabled: this.data.canEdit}, [Validators.required]),
    recertificationDate: new UntypedFormControl({value: "", disabled: this.data.canEdit}, [Validators.required]),    
    expirationDate: new UntypedFormControl({value: "", disabled: this.data.canEdit}, [Validators.required]),    
    dateOfLetter: new UntypedFormControl({value: "", disabled: this.data.canEdit}),    
  }); 

  constructor(private dialogRef: MatDialogRef<AddEditIndividualCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{certificate: IPersonCertificate, person:IPerson, canEdit:boolean},
    public personService:PersonService,
    private toastr: ToastrService,) {
      this.fillFormData();
    }

  ngOnInit(): void {
  }

  updateCertificateData(): void {    
    for (const controlName in this.certificateData) {
      if (this.certificateForm.controls.hasOwnProperty(controlName)) {
        this.certificateData[controlName] = this.certificateForm.controls[controlName].value;        
      }
    } 
  }

  fillFormData(): void {
    if (this.data.certificate === null)
    {
      const dateData = new Date();    
      this.certificateData.issueDate = dateData.toISOString().substring(0, 10)
      this.certificateData.dateOfLetter = dateData.toISOString().substring(0, 10)
      dateData.setMonth(dateData.getMonth() + 30)
      this.certificateData.recertificationDate = dateData.toISOString().substring(0, 10)
      dateData.setMonth(dateData.getMonth() + 6)
      this.certificateData.expirationDate = dateData.toISOString().substring(0, 10)
      this.certificateData.stage = this.stageType[0];
      this.certificateData.person = this.data.person;
    }    
    else{
      this.certificateData = this.data.certificate;
      this.certificateData.issueDate = this.certificateData?.issueDate?.substring(0, 10);
      this.certificateData.dateOfLetter = this.certificateData?.dateOfLetter?.substring(0, 10);
      this.certificateData.recertificationDate = this.certificateData?.recertificationDate?.substring(0, 10);
      this.certificateData.expirationDate = this.certificateData?.expirationDate?.substring(0, 10);
    }    

    for (const controlName in this.certificateData) {
      if (this.certificateForm.controls.hasOwnProperty(controlName)) {
        this.certificateForm.controls[controlName].setValue(this.certificateData[controlName]);
      }
    } 
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.updateCertificateData();        
    this.personService.savePersonCertificate(this.certificateData).subscribe(result=>{
      this.toastr.success("Certificate Added Successfully");
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("There was an error adding the certificate: ", error);
    })    
  }
}
