import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FIRM_CONTACT_TYPES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { AddEditAtpCourseComponent } from 'app/components/atp/add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { IFirmAddress } from 'app/models/firms/firmAddress';
import { IFirmContactInformation } from 'app/models/firms/firmContactInformation';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-firm-contact-information',
  templateUrl: './add-edit-firm-contact-information.component.html',
  styleUrls: ['./add-edit-firm-contact-information.component.scss']
})
export class AddEditFirmContactInformationComponent implements OnInit {
  public contactInformationDataForm = null;
  public contactInformationData: any;
  public uiData = LEAD_BASED_PAINT;  
  public color = 'white';

  constructor(public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{contactInformation: any, entity: string},
    private toastr: ToastrService,
    private firmService: FirmService, 
    private personService: PersonService,
    private commonService: CommonDataService,   
    private dialogRef: MatDialogRef<AddEditFirmContactInformationComponent>,
    public dialog: MatDialog) {
      this.setColor();
    }

  ngOnInit(): void {
    this.initializeDataFormFields();
    this.loadData();
  }

  loadData(){  
    this.contactInformationData = this.data?.contactInformation ?? this.contactInformationData;    
    this.loadDataForm();
  }

  initializeDataFormFields(){       
    this.contactInformationDataForm = new UntypedFormGroup({
      contactType: new UntypedFormControl(""),
      phone: new UntypedFormControl(""),
      ext: new UntypedFormControl(""),
      cell: new UntypedFormControl(""),
      fax: new UntypedFormControl(""),
      email: new UntypedFormControl(""),      
      website: new UntypedFormControl(""),      
    }); 
  }

  loadDataForm(){
    for (const controlName in this.contactInformationData) {
      if (this.contactInformationDataForm.controls.hasOwnProperty(controlName)) {        
        this.contactInformationDataForm.controls[controlName].setValue(this.contactInformationData[controlName]);          
      }
    }  
    for (const controlName in this.contactInformationData.contactInformation) {
      if (this.contactInformationDataForm.controls.hasOwnProperty(controlName)) {        
        this.contactInformationDataForm.controls[controlName].setValue(this.contactInformationData.contactInformation[controlName]);          
      }
    }    
  }

  updateDataToSave(){
    for (const controlName in this.contactInformationData) {
      if (this.contactInformationDataForm.controls.hasOwnProperty(controlName)) {
        this.contactInformationData[controlName] = this.contactInformationDataForm.controls[controlName].value;        
      }
    } 
    for (const controlName in this.contactInformationData.contactInformation) {
      if (this.contactInformationDataForm.controls.hasOwnProperty(controlName)) {
        this.contactInformationData.contactInformation[controlName] = this.contactInformationDataForm.controls[controlName].value;        
      }
    }     
  }

  getContactTypes(){
    return Object.values(FIRM_CONTACT_TYPES);
  }

  savePrompt(){
    var isNew = this.contactInformationData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Contact Information?' : 'Are you sure you want to <strong>Update</strong> the Contact Information?',title: isNew ? 'Add the Contact Information' : 'Update the Contact Information'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();        
      }
    })
  }

  saveData() {    
    this.updateDataToSave();        

    switch(this.data.entity){
      case 'individual':
        this.commonService.saveContactInformation(this.contactInformationData.contactInformation).subscribe(result=>{
          this.contactInformationData.contactInformation = result;
          this.personService.savePersonContactInfo(this.contactInformationData).subscribe(result=>{
            this.contactInformationData = result;
            this.toastr.success('Contact Information saved successfully!');  
            this.dialogRef.close(true);      
          },error=>{this.toastr.error('Error saving Contact Information: ', error)})      
        },error=>{this.toastr.error('Error saving Contact Information: ', error)})
        break;
      case 'firm':
        this.commonService.saveContactInformation(this.contactInformationData.contactInformation).subscribe(result=>{
          this.contactInformationData.contactInformation = result;
          this.firmService.saveFirmContactInformation(this.contactInformationData).subscribe(result=>{
            this.contactInformationData = result;
            this.toastr.success('Contact Information saved successfully!');  
            this.dialogRef.close(true);      
          },error=>{this.toastr.error('Error saving Contact Information: ', error)})      
        },error=>{this.toastr.error('Error saving Contact Information: ', error)})
        break;
    }
  }

  setColor(){    
    switch(this.data.entity){
      case 'individual':
        this.color = this.uiData.individual.color;
        break;
      case 'firm':
        this.color = this.uiData.firm.color;
        break;
    }
    return 
  }
}
