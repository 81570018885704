import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ANIMATIONS, APPLICATION_STATUS, FIRM_ADDRESS_TYPES, FIRM_APPLICATION_TYPES, FIRM_CONTACT_TYPES, FIRM_CREDENTIAL_MAPPING, FIRM_SERVICE_FOR, LEAD_BASED_PAINT, getStatusColor } from '@shared/utils/app-static-data';
import { AddFirmApplicationComponent } from 'app/components/application/firm/add-firm-application/add-firm-application.component';
import { IFirm } from 'app/models/firms/firm';
import { IFirmApplication } from 'app/models/firms/firm-application';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { AddEditFirmAdministratorComponent } from './add-edit-firm-administrator/add-edit-firm-administrator.component';
import { IAccountAffiliation } from 'app/models/common/accountAffiliation';
import { IContactInformation } from 'app/models/common/contactInformation';
import { IFirmAddress } from 'app/models/firms/firmAddress';
import { IAddress } from 'app/models/common/address';
import { AddEditFirmStaffComponent } from './add-edit-firm-staff/add-edit-firm-staff.component';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { AddEditIndividualComponent } from 'app/components/individual/add-edit-individual/add-edit-individual.component';
import { IFirmCertificate } from 'app/models/firms/firmCertificate';
import { AddEditFirmCertificateComponent } from './add-edit-firm-certificate/add-edit-firm-certificate.component';
import { IFirmLeadCredential } from 'app/models/firms/firmLeadCredentials';
import { AddEditFirmCredentialComponent } from './add-edit-firm-credential/add-edit-firm-credential.component';
import { PersonService } from 'app/services/person/person.service';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IPerson } from 'app/models/People/person';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IPayment } from 'app/components/payments/models/payment';
import { AddEditFirmAddressComponent } from './add-edit-firm-address/add-edit-firm-address.component';
import { AddEditFirmContactInformationComponent } from './add-edit-firm-contact-information/add-edit-firm-contact-information.component';
import { IFirmContactInformation } from 'app/models/firms/firmContactInformation';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { IComplaint } from 'app/models/complaint/complaint';
import { AddEditComplaintComponent } from 'app/components/complaint/add-edit-complaint/add-edit-complaint.component';
import { PrintCertificateComponent } from '../../individual/add-edit-individual/print-certificate/print-certificate.component';
import { PrintFirmCertificateModalComponent } from './print-firm-certificate-modal/print-firm-certificate-modal.component';

@Component({
  selector: 'app-firm-add-edit',
  templateUrl: './firm-add-edit.component.html',
  styleUrls: ['./firm-add-edit.component.scss'],
  animations: ANIMATIONS,
})
export class FirmAddEditComponent {
  public isMobile: boolean = false;
  public loading: boolean = true;
  public uiData = LEAD_BASED_PAINT;
  public credentialMapping = FIRM_CREDENTIAL_MAPPING;
  public firm: IFirm = null;
  public firmForm = null;
  public firmContactForm = null;
  public firmPersonContactForm = null;
  public firmPhysicalAddressForm = null;
  public firmMailingAddressForm = null;    
  public statusColor = getStatusColor;
  public appStatus = APPLICATION_STATUS;
  public firmContactInformation: IContactInformation;
  public firmContactFirmPerson: IFirmPerson;
  public firmContactPerson: IPerson;
  public possibleFirmContactPersons: IPerson[] = [];
  public possibleFilteredFirmContactPersons: IPerson[] = [];

  public firmPersonContactInformation: IContactInformation;
  public firmAddresses: IFirmAddress[] = [];
  public firmMailingAddress: IAddress;
  public firmPhysicalAddress: IAddress; 

  public applicationFilterString: string = '';
  private applicationDebounceString: Subject<string> = new Subject<string>();  
  public applicationSelectedStatus = new FormControl([]);
  public applicationSelectedType = new FormControl([]);  
  public applicationDataSource: MatTableDataSource<any>;
  public changeIndicator: number = 0;
  public filteredApplications = [];     
  public firmApplicationTable = {
    layout:{
      columns:['id','userID', 'status', 'applicationType', 'applicationDate', 'lastUpdatedBy', 'lastUpdated','approverEmail','approvalDate'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'5'},
        {displayName:'Type',columnName:'applicationType', type:'string', size:'5'},
        {displayName:'User Id',columnName:'userID', type:'string', size:'17'},
        {displayName:'Status',columnName:'status', type:'string', size:'10'},
        {displayName:'Application Date',columnName:'applicationDate', type:'date', size:'10'},
        {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'14'},
        {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'8'},
        {displayName:'Approver Email',columnName:'approverEmail', type:'string', size:'14'},
        {displayName:'Approval Date',columnName:'approvalDate', type:'date', size:'10'},          
      ],
      data: []      
    }
  }
  public applicationPageSize: number = 5;   
  @ViewChild('ApplicationTablePaginator') applicationPaginator: MatPaginator;
  @ViewChild('ApplicationTableSort') applicationSort: MatSort;

  public firmAdministrators: IAccountAffiliation[] = [];
  public firmFilteredAdministrators: IAccountAffiliation[] = [];
  public administratorFilterString: string = '';
  private administratorDebounceString: Subject<string> = new Subject<string>();  
  public administratorStatusFilter: FormControl = new FormControl('Both');
  public administratorDataSource: MatTableDataSource<any>;
  public administratorTable = 
  {
    layout:{
      columns:['id', 'userID', 'status'],
      container:[        
        {displayName:'Id',columnName:'id', type:'string', size:'10'}, 
        {displayName:'User',columnName:'userID', type:'string', size:'70'}, 
        {displayName:'Status',columnName:'status', type:'string', size:'20'},                
      ],
      data: []      
    }
  };
  public administratorTablePageSize = 5;
  @ViewChild('AdministratorTablePaginator') administratorPaginator: MatPaginator;
  @ViewChild('AdministratorTableSort') administratorSort: MatSort;

  public firmStaff = [];
  public firmFilteredStaff = [];
  public staffFilterString: string = '';
  private staffDebounceString: Subject<string> = new Subject<string>();  
  public staffDataSource: MatTableDataSource<any>;
  public staffTable = 
  {
    layout:{
      columns:['personId', 'firstName', 'lastName', 'email', 'phone', 'cell', 'fax', 'isPOC'],
      container:[        
        {displayName:'Id',columnName:'personId', type:'number', size:'10'}, 
        {displayName:'First Name',columnName:'firstName', type:'string', size:'15'},
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'15'},
        {displayName:'Email',columnName:'email', type:'string', size:'10'},
        {displayName:'Phone',columnName:'phone', type:'string', size:'10'},
        {displayName:'Cell',columnName:'cell', type:'string', size:'10'},
        {displayName:'Fax',columnName:'fax', type:'string', size:'10'},        
        {displayName:'Point of Contact',columnName:'isPOC', type:'bool', size:'10'},
      ],
      data: []      
    }
  };
  public staffTablePageSize = 5;
  @ViewChild('StaffTablePaginator') staffPaginator: MatPaginator;
  @ViewChild('StaffTableSort') staffSort: MatSort;

  public firmCertificates: IFirmCertificate[] = [];
  public firmFilteredCertificates: IFirmCertificate[] = [];
  public certificateFilterString: string = '';
  private certificateDebounceString: Subject<string> = new Subject<string>();
  public certificateStageFilter: FormControl = new FormControl('Both');
  public certificatesDataSource: MatTableDataSource<any>;
  public certificateTable = 
  {
    layout:{
      columns:['id', 'codeType', 'stage', 'controlNumber','issueDate','expirationDate', 'dateOfLetter'],
      container:[        
        {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
        {displayName:'Discipline',columnName:'codeType', type:'string', size:'12'},
        {displayName:'Stage',columnName:'stage', type:'string', size:'12'},
        {displayName:'Control Number',columnName:'controlNumber', type:'string', size:'15'},
        {displayName:'Issue Date',columnName:'issueDate', type:'date', size:'12'},
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'12'},        
        {displayName:'Date of Letter',columnName:'dateOfLetter', type:'date', size:'12'},
      ],
      data: []      
    }
  };
  public certificateTablePageSize = 5;
  @ViewChild('CertificateTablePaginator') certificatePaginator: MatPaginator;
  @ViewChild('CertificateTableSort') certificateSort: MatSort;

  public firmCredentials: IFirmLeadCredential[] = [];
  public credentialFilterString: string = '';
  private credentialDebounceString: Subject<string> = new Subject<string>();
  public firmValidatedCredentials: any[] = [];
  public firmFilteredCredentials: any[] = [];
  public credentialDataSource: MatTableDataSource<any>;
  public credentialTable = 
  {
    layout:{
      columns:['firmLeadCredentialType', 'isValid', 'totalStaff','staffIds', 'expirationDate'],
      container:[        
        {displayName:'Credential Type',columnName:'firmLeadCredentialType', type:'string', size:'30'},
        {displayName:'Certified',columnName:'isValid', type:'bool', size:'13'},
        {displayName:'Total Certified Staff',columnName:'totalStaff', type:'string', size:'15'},
        {displayName:'Certified Staff',columnName:'staffIds', type:'string', size:'20'},        
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'15'},
      ],
      data: []      
    }
  };
  public credentialTablePageSize = 5;
  @ViewChild('CredentialTablePaginator') credentialPaginator: MatPaginator;
  @ViewChild('CredentialTableSort') credentialSort: MatSort;  
  public cardColor: string = this.sharedService.gold + '20';

  public addressTableColumns = ['id', 'contactType', 'street', 'city', 'state', 'zip', 'isCurrentPhysical', 'isCurrentMailing', 'actions']
  public addressTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'9'}, 
    {displayName:'Type',columnName:'contactType', type:'string', size:'10'},
    {displayName:'Street',columnName:'street', type:'string', size:'21'},
    {displayName:'City',columnName:'city', type:'string', size:'15'},
    {displayName:'State',columnName:'state', type:'string', size:'7'},
    {displayName:'Zip',columnName:'zip', type:'string', size:'7'},
    {displayName:'Current Physical',columnName:'isCurrentPhysical', type:'bool', size:'15'},
    {displayName:'Current Mailing',columnName:'isCurrentMailing', type:'bool', size:'15'},    
  ];
  public addressTableData = [];
  public addressFilteredTableData = []
  public addressTablePageSize = 5;
  public filterAddressString: string = ''; 
  public filterAddressType = new FormControl([]);
  public filterAddressPhysical = new FormControl([]);
  public filterAddressMailing = new FormControl([]); 
  public addressTypeList = Object.values(FIRM_ADDRESS_TYPES);
  public addressBoolList = Object.values(['Unchecked','Checked']);
  public addressDataSource: MatTableDataSource<any>;
  @ViewChild('AddressTablePaginator') addressPaginator: MatPaginator;
  @ViewChild('AddressTableSort') addressSort: MatSort;

  public contactInformationTableColumns = ['id', 'contactType', 'phone', 'cell', 'fax', 'email', 'website', 'actions']
  public contactInformationTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Type',columnName:'contactType', type:'string', size:'15'},
    {displayName:'Phone',columnName:'phone', type:'string', size:'15'},
    {displayName:'Cell',columnName:'cell', type:'string', size:'15'},
    {displayName:'Fax',columnName:'fax', type:'string', size:'15'},
    {displayName:'Email',columnName:'email', type:'string', size:'15'},
    {displayName:'Website',columnName:'website', type:'string', size:'15'},     
  ];
  public contactInformationTableData = []
  public contactInformationFilteredTableData = []
  public contactInformationTablePageSize = 5;
  public filterContactInformationString: string = ''; 
  public filterContactInformationType = new FormControl([]);
  public contactInformationTypeList = Object.values(FIRM_CONTACT_TYPES);
  public contactInformationDataSource: MatTableDataSource<any>;
  @ViewChild('ContactInformationTablePaginator') contactInformationPaginator: MatPaginator;
  @ViewChild('ContactInformationTableSort') contactInformationSort: MatSort;

    complaintsTable = {
        icon: 'recent_actors',
        title: 'Complaints',
        layout: {
            columns: ['id', 'complainteeName', 'complainantName', 'status', 'complaintDate', 'lastUpdatedBy', 'lastUpdated'],
            container: [
                { displayName: 'Id', columnName: 'id', type: 'string', size: '10' },
                { displayName: 'Complaint Source Name', columnName: 'complainteeName', type: 'string', size: '20' },
                { displayName: 'Complaint Target Name', columnName: 'complainantName', type: 'string', size: '20' },
                { displayName: 'Status', columnName: 'status', type: 'string', size: '10' },
                { displayName: 'Complaint Date', columnName: 'complaintDate', type: 'date', size: '15' },
                { displayName: 'Last Updated By', columnName: 'lastUpdatedBy', type: 'string', size: '15' },
                { displayName: 'Last Updated', columnName: 'lastUpdated', type: 'date', size: '10' },
            ],
            data: []
        }
    }

    public complaints: IComplaint[] = [];
    public complaintDataSource: MatTableDataSource<any>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IFirm,
  private breakpointObserver: BreakpointObserver,
  private dialogRef: MatDialogRef<FirmAddEditComponent>,
  public firmService: FirmService,
  public personService: PersonService,
  public commonService: CommonDataService,
  public paymentService: PaymentService,
  private toastr: ToastrService,
  public sharedService: SharedService,
  public complaintService: ComplaintService,
  public dialog: MatDialog)
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.firm = this.data
    this.initializeForms();    
    this.initializeDebounceFunctions();    
    this.fillFirmForm();
    this.loadAdditionalFirmData();
    this.loading=false;    
  }

  paymentUpdated(payments: IPayment[]) {
  }
     
  initializeForms(): void {
    this.firmForm = new UntypedFormGroup({      
      isPrivate: new UntypedFormControl(false), 
      inactive: new UntypedFormControl(false), 
      name: new UntypedFormControl("", [Validators.required]),
      certificationNumber: new UntypedFormControl(""), 
      servicesAvailable: new UntypedFormControl(""),            
      propertyManagementLicense: new UntypedFormControl(""),           
      ccb: new UntypedFormControl(""),
      ccbExpirationDate: new UntypedFormControl(""),             
    }); 

    this.firmContactForm = new UntypedFormGroup({               
      phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      cell: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      fax: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),        
      website: new UntypedFormControl(""),              
    });

    this.firmPersonContactForm = new UntypedFormGroup({               
      title: new UntypedFormControl({value: "", disabled: true}),
      firstName: new UntypedFormControl({value: "", disabled: true}, [Validators.required]),
      middleName: new UntypedFormControl({value: "", disabled: true}),
      lastName: new UntypedFormControl({value: "", disabled: true}, [Validators.required]),  
      phone: new UntypedFormControl({value: "", disabled: true}, [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      cell: new UntypedFormControl({value: "", disabled: true}, [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      fax: new UntypedFormControl({value: "", disabled: true}, [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
      email: new UntypedFormControl({value: "", disabled: true}, [Validators.required, Validators.email]),      
    });
    
    this.firmPhysicalAddressForm = new UntypedFormGroup({
      street: new UntypedFormControl("", [Validators.required]),
      city: new UntypedFormControl("", [Validators.required]),
      state: new UntypedFormControl("", [Validators.required]),
      zip: new UntypedFormControl("", [Validators.required,Validators.pattern(/^\d{5}(-\d{4})?$/)]),
      county: new UntypedFormControl(""),             
    });

    this.firmMailingAddressForm = new UntypedFormGroup({
      street: new UntypedFormControl("", [Validators.required]),
      city: new UntypedFormControl("", [Validators.required]),
      state: new UntypedFormControl("", [Validators.required]),
      zip: new UntypedFormControl("", [Validators.required,Validators.pattern(/^\d{5}(-\d{4})?$/)]),
      county: new UntypedFormControl(""),
    })
  }
  
  initializeDebounceFunctions(): void {
    this.certificateDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.certificateFilterString = value.trim().toLowerCase();
      this.filterCertificateTable();
    });

    this.staffDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.staffFilterString = value.trim().toLowerCase();
      this.filterStaffTable();
    });

    this.administratorDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.administratorFilterString = value.trim().toLowerCase();
      this.filterAdministratorTable();
    });

    this.applicationDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applicationFilterString = value.trim().toLowerCase();
      this.filterApplicationTable();
    });

    this.credentialDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.credentialFilterString = value.trim().toLowerCase();
      this.filterCredentialTable();
    });
  }

  loadAdditionalFirmData(): void {
    this.firmService.getAllFirmApplications().subscribe(
      res=>{        
        this.firmApplicationTable.layout.data = res.filter(x=>x.firm?.id === this.firm.id);
        this.filteredApplications = res;        
        this.filterApplicationTable();        
      },
      error=>{this.toastr.error("There was an error getting the firm applications: ", error)}
    );

    this.refreshFirmContactInformations();
    this.refreshFirmAddresses();
    this.refreshFirmStaff()
    this.refreshFirmCertificates();

    this.firmService.getFirmLeadCredentials(this.firm.id).subscribe(result=>{      
      this.firmCredentials = result;  
      this.firmValidatedCredentials = this.firmCredentials;                   
      this.getStaffCerts();
    },error=>{this.toastr.error("There was an error getting the firm credentials: ", error)})

    this.getComplaintAssociations();

    this.commonService.getFirmAffiliations(this.firm.id).subscribe(result=>{
      this.firmAdministrators = result;
      this.filterAdministratorTable();      
    },error=>{this.toastr.error("There was an error getting the firm affiliations: ", error)});    
  }

  getComplaintAssociations(){
    this.complaintService.getComplaintsByAssociations(this.firm.id,"Firm").subscribe(result=>{      
      this.complaints = result; 
      this.complaintDataSource = new MatTableDataSource<any>(this.complaints);
    },error=>{})  
  }
  refreshFirmAffiliations(): void {
    this.commonService.getFirmAffiliations(this.firm.id).subscribe(result=>{
      this.firmAdministrators = result;
      this.filterAdministratorTable();
    },error=>{this.toastr.error("There was an error getting the firm affiliations: ", error)})
  }

  refreshFirmApplications(): void {
    this.firmService.getAllFirmApplications().subscribe(
      res=>{        
        this.firmApplicationTable.layout.data = res.sort((a, b) => new Date(b.applicationDate).getTime() - new Date(a.applicationDate).getTime()).filter(x=>x.firm?.id === this.firm.id);               
        this.filterApplicationTable();        
      },
      error=>{this.toastr.error("There was an error getting the firm applications: ", error)}
    )    
  }

  refreshFirmAddresses(): void {
    this.firmService.getFirmAddresses(this.firm.id).subscribe(result=>{   
      if(result != null) {
       this.addressTableData = result.map(a=>{return { original: a, id: a.id, contactType: a.address.contactType, street: a.address.street, city: a.address.city, state: a.address.state, zip: a.address.zip, isCurrentPhysical: a.isCurrentPhysical, isCurrentMailing: a.isCurrentMailing}});
       this.filterAddressTable();
      }        
    },error=>{this.toastr.error("There was an error getting the firm addresses: ", error)},
    () => {
      this.fillFirmMailing();
      this.fillFirmPhysical();      
    });
  }

  refreshFirmContactInformations(): void {
    this.firmService.getFirmContactInformations(this.firm.id).subscribe(result=>{
      this.contactInformationTableData = result.map(a=>{return { original: a, id: a.id, contactType: a.contactInformation.contactType, phone: a.contactInformation.phone, cell: a.contactInformation.cell, fax: a.contactInformation.fax, email: a.contactInformation.email, website: a.contactInformation.website}});;      
      this.filterContactInformationTable();
    },error=>{this.toastr.error("There was an error getting the firm contact information: ", error)},
    () => {
      this.fillFirmContactInformation();
    });
  }

  refreshFirmStaff(): void {
    this.firmService.getFirmPeopleAndContactInformation(this.firm.id).subscribe(result=>{      
      this.firmStaff = result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));       
      this.staffTable.layout.data = this.firmStaff.map(staff => {
          let personId, person, contactInformationId, contactInformation;
  
          if (staff.person) {
              ({id: personId, ...person} = staff.person);
          }
  
          if (staff.contactInformation) {
              ({id: contactInformationId, ...contactInformation} = staff.contactInformation);
          }
  
          return {...staff, ...person, personId, ...contactInformation, contactInformationId};
      });  
      this.filterStaffTable();
      this.getStaffCerts()    
    },error=>{this.toastr.error("There was an error getting the firm applications: ", error)});
  }

  refreshFirmCertificates(): void {
    this.firmService.getFirmCertificates(this.firm.id).subscribe(result=>{
      this.firmCertificates = result.sort((a, b) => new Date(b.issueDate).getTime() - new Date(a.issueDate).getTime());
      this.filterCertificateTable();
    },error=>{this.toastr.error("There was an error getting the firm applications: ", error)})
  }

  refreshFirmCredentials(): void {
    this.firmService.getFirmLeadCredentials(this.firm.id).subscribe(result=>{
      this.firmCredentials = result.sort((a, b) => a.firmLeadCredentialType.localeCompare(b.firmLeadCredentialType));      
      this.firmValidatedCredentials = this.firmCredentials;       
      this.getStaffCerts()
    },error=>{this.toastr.error("There was an error getting the firm applications: ", error)})
  }

  exportPlan() {
    this.dialog.open(PrintCertificateComponent, {
      data: {
        //selectedComplaint: this.complaint
      },
      role: 'dialog',
      ariaLabel: 'Complaint',
      minWidth: !this.sharedService.mobile ? '1000px' : '300px',
      maxWidth: !this.sharedService.mobile ? '980px' : '300px'
    });
  }

  getStaffCerts(): void {    
    if(this.firmStaff.length > 0 && this.firmCredentials.length > 0)
    {      
      var credentials:any[] = [];      
      
      credentials = this.firmStaff.map(entry=>{return {person: entry.person, certs: entry.certificates}});
      this.validateFirmCredentials(credentials);
      this.filterCredentialTable();


    }
    else{
      this.filterCredentialTable();
    }
  }

  validateFirmCredentials(staffCerts: any): void {
    this.firmValidatedCredentials.forEach(credential=>{
      var value = this.firmCredentialsMappedToPersonCerts(credential, staffCerts);      
      credential.isValid = value.isValid;
      credential.staffIds = value.staffIds;
      credential.totalStaff = value.staffIds.length;
      credential.expirationDate = value.expirationDate;
    })    
  }

  firmCredentialsMappedToPersonCerts(credential, staff) {
    const returnObject = { isValid: false, staffIds: [], expirationDate: "" };
    
    staff.forEach(member => {
        member.certs.forEach(cert => {
            const credentialTypes = this.credentialMapping[credential.firmLeadCredentialType];
            
            if (credentialTypes && credentialTypes.includes(cert.codeType)) {
              returnObject.isValid = true;
              
              let staffObject = {
                  id: cert.person?.id, 
                  display:  cert.person?.id + ': ' + cert.person?.firstName + ' ' + cert.person?.lastName, 
                  expiration: cert.expirationDate, 
                  codeType: cert.codeType
              };
              
              if (!returnObject.staffIds.some(staff => staff.id === cert.person.id)) {
                  if (returnObject.expirationDate === "") {
                      returnObject.expirationDate = cert.expirationDate;
                      returnObject.staffIds.unshift(staffObject); // Add to the beginning if expirationDate is just set
                  } else {
                      const Date1 = new Date(returnObject.expirationDate);
                      const Date2 = new Date(cert.expirationDate);
                      if (Date1 < Date2) {
                          returnObject.expirationDate = Date2.toISOString();
                          returnObject.staffIds.unshift(staffObject); // Add to the beginning if the new expirationDate is later
                      } else {
                          returnObject.staffIds.push(staffObject); // Add to the end if the new expirationDate is not later
                      }
                  }
              }
          }
        });
    });    
    return returnObject;
  }

  fillFirmForm(): void {
    for (const controlName in this.firm) {
      if (this.firmForm.controls.hasOwnProperty(controlName)) {
        this.firmForm.controls[controlName].setValue(this.firm[controlName]);
      }
    }  
  }

  fillFirmContactInformation(): void {
    for (const controlName in this.firmContactInformation) {
      if (this.firmContactForm.controls.hasOwnProperty(controlName)) {
        this.firmContactForm.controls[controlName].setValue(this.firmContactInformation[controlName]);
      }
    } 
  }

  fillFirmMailing(): void {
    for (const controlName in this.firmMailingAddress) {
      if (this.firmMailingAddressForm.controls.hasOwnProperty(controlName)) {
        this.firmMailingAddressForm.controls[controlName].setValue(this.firmMailingAddress[controlName]);
      }
    } 
  }

  fillFirmPhysical(): void{
    for (const controlName in this.firmPhysicalAddress) {
      if (this.firmPhysicalAddressForm.controls.hasOwnProperty(controlName)) {
        this.firmPhysicalAddressForm.controls[controlName].setValue(this.firmPhysicalAddress[controlName]);
      }
    } 
  }

  fillFirmPersonContact(): void {                            
    for (const controlName in this.firmContactPerson) {      
      if (this.firmPersonContactForm.controls.hasOwnProperty(controlName)) {
        this.firmPersonContactForm.controls[controlName].setValue(this.firmContactPerson[controlName]);
      }
    } 

    for (const controlName in this.firmPersonContactInformation) {
      if (this.firmPersonContactForm.controls.hasOwnProperty(controlName)) {
        this.firmPersonContactForm.controls[controlName].setValue(this.firmPersonContactInformation[controlName]);
      }
    } 
  }

  displayContactPerson(contactPerson: any): string {
    return contactPerson ? `${contactPerson.id}: ${contactPerson.firstName === '' ? 'New' : contactPerson.firstName} ${contactPerson.lastName === '' ? 'Person' : contactPerson.lastName}` : '';
  }

  filterContactPerson(event: any) {
    const value = event.target.value;    
    if (!value) {
      this.possibleFilteredFirmContactPersons = [...this.possibleFirmContactPersons];
      return;
    }
    const filterValue = value.toLowerCase();
    this.possibleFilteredFirmContactPersons = this.possibleFirmContactPersons.filter(user => 
      (user.id + ' ' + user.firstName.toLocaleLowerCase() + ' ' + user.lastName.toLocaleLowerCase()).includes(filterValue)      
    );
  }

  onContactPersonChange() {  
    if(this.firmContactPerson.id !== 0){
      this.personService.getPersonContactInformationByPersonId(this.firmContactPerson.id).subscribe(res=>{
        this.firmPersonContactInformation = res.contactInformation;
        Object.keys(this.firmPersonContactForm.controls).forEach((controlName: string) => {
          this.firmPersonContactForm.get(controlName).disable();
        });
        this.fillFirmPersonContact();
      },err=>{});      
    }    
    else{
      this.firmContactPerson = {id: 0, lastName: '', firstName: '', middleName: '', title: '', response: '', inactive: false, dateOfBirth: null, ssn: '', certificates: null}      
      for (const key in this.firmPersonContactInformation) {
        if (Object.prototype.hasOwnProperty.call(this.firmPersonContactInformation, key)) {
          this.firmPersonContactInformation[key] = '';
        }
      }
      Object.keys(this.firmPersonContactForm.controls).forEach((controlName: string) => {
        this.firmPersonContactForm.get(controlName).enable();
      });
      this.fillFirmPersonContact();
    }
  }

  updateFirmData(): void {    
    for (const controlName in this.firm) {
      if (this.firmForm.controls.hasOwnProperty(controlName)) {
        this.firm[controlName] = this.firmForm.controls[controlName].value;        
      }
    } 
  }

  updateFirmContactData(): void {
    for (const controlName in this.firmContactInformation) {
      if (this.firmContactForm.controls.hasOwnProperty(controlName)) {
        this.firmContactInformation[controlName] = this.firmContactForm.controls[controlName].value;        
      }
    } 
  }

  updateFirmMailingAddressData(): void {
    for (const controlName in this.firmMailingAddress) {
      if (this.firmMailingAddressForm.controls.hasOwnProperty(controlName)) {
        this.firmMailingAddress[controlName] = this.firmMailingAddressForm.controls[controlName].value;        
      }
    } 
  }

  updateFirmPhysicalAddressData(): void {
    for (const controlName in this.firmPhysicalAddress) {
      if (this.firmPhysicalAddressForm.controls.hasOwnProperty(controlName)) {
        this.firmPhysicalAddress[controlName] = this.firmPhysicalAddressForm.controls[controlName].value;        
      }
    } 
  }

  saveChanges(){
    this.updateFirmData();
    this.firmService.saveFirm(this.firm).subscribe(result=>{
      this.toastr.success("Firm Updated");
    },error=>{})
  }
  
  exitComponent(){
    this.dialogRef.close();
  }
  
  displayLimit(text: any, limit: any){        
    var newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, parseInt(limit)) + "...";
    }        
    return newText;
  }

  getFirmServiceForTypeValues() {    
    return Object.values(FIRM_SERVICE_FOR);
  }
  
  getStatusTypeValues() {    
    return Object.values(APPLICATION_STATUS);
  }
  
  getApplicationTypeValues() {
    return Object.values(FIRM_APPLICATION_TYPES);
  }

  canRenewCertificate(firmCert: IFirmCertificate){
    return this.firmApplicationTable.layout.data.some(
      x=>x.previousApplication === firmCert.controlNumber 
      && x.status !== APPLICATION_STATUS.approved 
      && x.status !== APPLICATION_STATUS.cancelled
    )
  }

  openApplication(application: IFirmApplication, edit: boolean, renew?: boolean, firmCert?: IFirmCertificate){     
    if(firmCert !== null && firmCert !== undefined){
      application = this.firmApplicationTable.layout.data.find(x=>x.previousApplication === firmCert.controlNumber && x.status !== APPLICATION_STATUS.cancelled && x.status !== APPLICATION_STATUS.approved) ?? null;
    } 
    const dialogRef = this.dialog.open(AddFirmApplicationComponent, {
      width: '90%',
      data: {application,edit,firmData: {
        firm: this.firm, 
        mailingAddress: this.firmMailingAddress, 
        physicalAddress: this.firmPhysicalAddress,
        contactInformation: this.firmContactInformation,
        firmCurrentCertificates: this.firmCertificates,
        firmCertificateForRenewal: firmCert,
        renewal: renew, 
        firmCurrentCredentials: this.firmCredentials,
        firmContactPerson: this.firmContactPerson,
        firmContactPersonContactInformation: this.firmPersonContactInformation     
      }},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
      this.refreshFirmApplications();
      this.refreshFirmCertificates();
      this.changeIndicator++;
    });    
  }       

  updateApplicationsDebounceString(event: any){    
    this.applicationDebounceString.next(event.target.value);    
  }
  filterApplicationTable() {
    this.filteredApplications = this.firmApplicationTable.layout.data.filter(x=>(x.id + ' ' + x.userID).toLowerCase().includes(this.applicationFilterString.trim().toLowerCase()));    
    if (this.applicationSelectedStatus.value.length > 0){
      this.filteredApplications = this.filteredApplications.filter(x=>this.applicationSelectedStatus.value.includes(x.status));
    }  
    if (this.applicationSelectedType.value.length > 0){
      this.filteredApplications = this.filteredApplications.filter(x=>this.applicationSelectedType.value.includes(x.applicationType));
    } 
    this.updateApplicationDataSource();  
  }

  updateApplicationDataSource(){
    this.applicationDataSource = new MatTableDataSource<any>(this.filteredApplications);
    this.applicationDataSource.sort = this.applicationSort;
    this.applicationDataSource.paginator = this.applicationPaginator;    
  }

  updateAdministratorDebounceString(event: any){    
    this.administratorDebounceString.next(event.target.value);    
  }
  filterAdministratorTable(): void {
    this.firmFilteredAdministrators = this.firmAdministrators.filter(x=>
      (x.id + ' ' + x.userID).toLowerCase().includes(this.administratorFilterString)
      && ((this.administratorStatusFilter.value === "Both") ? true : (x.status === this.administratorStatusFilter.value))
    );    
    this.updateAdministratorSource();
  }

  updateAdministratorSource(){
    this.administratorDataSource = new MatTableDataSource<any>(this.firmFilteredAdministrators);
    this.administratorDataSource.sort = this.administratorSort;
    this.administratorDataSource.paginator = this.administratorPaginator;
  }

  editAdministrator(row: any): void {
    const dialogRef = this.dialog.open(AddEditFirmAdministratorComponent, {        
      data: {firm: this.firm, affiliations: this.firmAdministrators, affiliation: row},
      width: this.isMobile ? '90%' : '30%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshFirmAffiliations();
      }    
    });
  }
  
  updateStaffDebounceString(event: any){    
    this.staffDebounceString.next(event.target.value);    
  }
  filterStaffTable(): void {
    this.firmFilteredStaff = this.staffTable.layout.data.filter(x=>
      (x.id + ' ' + x.firstName + x.lastName).toLowerCase().includes(this.staffFilterString)
    );    
    this.updateStaffSource();
  }

  updateStaffSource(){
    this.staffDataSource = new MatTableDataSource<any>(this.firmFilteredStaff);
    this.staffDataSource.sort = this.staffSort;
    this.staffDataSource.paginator = this.staffPaginator;
  }

  editStaff(row: any): void {    
    if(row === null){
      const dialogRef = this.dialog.open(AddEditFirmStaffComponent, {        
        data: {firm: this.firm, firmPeople: this.firmStaff},
        width: this.isMobile ? '90%' : '30%',
        autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {    
        if (result){        
          this.refreshFirmStaff();
          this.refreshFirmCredentials();
        }    
      });
    }
    else{
      const dialogRef = this.dialog.open(AddEditIndividualComponent, {
        width: '90%',
        data: { person: row.person },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {      
        this.refreshFirmStaff();
        this.refreshFirmCredentials();
      });
    }
  }
  deleteStaff(row: any): void {             
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message:'Are you sure you want to remove the firm staff member: <strong>' + row.firstName + ' ' + row.lastName + '</strong>?',title:'Delete Firm Staff Member'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.firmService.deleteFirmPerson(row.id).subscribe(result=>{
          this.refreshFirmStaff();
          this.toastr.success("Successfully removed staff member");
        },error=>{
          this.toastr.error("There was an error removing the staff member: ", error);
        })
      }
    })  
  }

  updateCertificateDebounceString(event: any){    
    this.certificateDebounceString.next(event.target.value);    
  }
  filterCertificateTable(): void {
    this.firmFilteredCertificates = this.firmCertificates.filter(x=>
      (x.id + ' ' + x.codeType + ' ' + x.controlNumber).toLowerCase().includes(this.certificateFilterString)
      && ((this.certificateStageFilter.value === "Both") ? true : (x.stage === this.certificateStageFilter.value))      
    );        
    this.updateCertificateSource();
  }

  updateCertificateSource(){
    this.certificatesDataSource = new MatTableDataSource<any>(this.firmFilteredCertificates);
    this.certificatesDataSource.sort = this.certificateSort;
    this.certificatesDataSource.paginator = this.certificatePaginator;
  }

  editCertificate(row: any): void {
    const dialogRef = this.dialog.open(AddEditFirmCertificateComponent, {        
      data: {firm: this.firm, certificate: row, canEdit: this.canRenewCertificate(row)},
      width: this.isMobile ? '90%' : '30%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){        
        this.refreshFirmCertificates();
      }    
    });
  }

  updateCredentialDebounceString(event: any){    
    this.credentialDebounceString.next(event.target.value);    
  }
  filterCredentialTable(): void {
    this.firmFilteredCredentials = this.firmValidatedCredentials.filter(x=>x.firmLeadCredentialType.toLowerCase().includes(this.credentialFilterString))
    this.updateCredentialSource()
  }
  updateCredentialSource(){
    this.credentialDataSource = new MatTableDataSource<any>(this.firmFilteredCredentials);
    this.credentialDataSource.sort = this.credentialSort;
    this.credentialDataSource.paginator = this.credentialPaginator;
  }

  editCredential(row: any): void {    
    const dialogRef = this.dialog.open(AddEditFirmCredentialComponent, {        
      data: {firm: this.firm, credential: row, firmCredentials: this.firmCredentials, staffCerts: this.firmStaff.map(entry=>{return {person: entry.person, certs: entry.certificates}})},
      width: this.isMobile ? '90%' : '35%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){                
        this.refreshFirmCredentials();
      }    
    });
  }

  deleteCredential(row: any): void {     
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message:'Are you sure you want to remove the firm credential: <strong>' + row.firmLeadCredentialType + '</strong>?',title:'Delete Firm Credential'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.firmService.deleteFirmLeadCredential(row.id).subscribe(result=>{
          this.toastr.success("Successfully removed firm credential");
          this.refreshFirmCredentials();
        },error=>{
          this.toastr.error("An error occurred trying to remove the firm credential: ", error);
        })   
      }
    })    
  }

  editAddressTableEntry(row: any){    
    var firmAddress:IFirmAddress  = {id: 0, firm: this.firm, address: {id: 0, street: '', city: '', state: '', zip: '', county: '', contactType: ''}, isCurrentPhysical: false, isCurrentMailing: false};
    
    const dialogRef = this.dialog.open(AddEditFirmAddressComponent, {    
      width: this.isMobile ? '70%' : '30%',    
      data: {address: row?.original ?? firmAddress, entity: 'firm'}
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshFirmAddresses();        
      }    
    });  
  }
  deleteAddressEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Address at: <strong>' + row.street + '</strong>?',title: 'Delete the Address'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){        
        this.firmService.deleteFirmAddress(row.original.id).subscribe(result=>{
          this.commonService.deleteAddress(row.original.address.id).subscribe(result=>{
            this.toastr.success('Address deleted successfully');
            this.refreshFirmAddresses();
          },error=>{this.toastr.error('Error deleting Address: ', error)});
        },error=>{this.toastr.error('Error deleting Address: ', error)})
      }
    })
  }

  filterAddressTable(){
    this.addressFilteredTableData = this.addressTableData;

    this.addressFilteredTableData = this.addressFilteredTableData.filter(x => {      
      return x.id?.toString().includes(this.filterAddressString) ||      
        x.street?.toString().toLowerCase().includes(this.filterAddressString.toLowerCase()) ||
        x.city?.toString().toLowerCase().includes(this.filterAddressString.toLowerCase()) ||
        x.zip?.toString().toLowerCase().includes(this.filterAddressString.toLowerCase())
    });

    if(this.filterAddressType.value.length > 0){
      this.addressFilteredTableData = this.addressFilteredTableData.filter(x => this.filterAddressType.value.includes(x.contactType));
    }

    if(this.filterAddressPhysical.value.length > 0){
      this.addressFilteredTableData = this.addressFilteredTableData.filter(x => 
          (this.filterAddressPhysical.value.includes('Unchecked') && x.isCurrentPhysical === false) ||
          (this.filterAddressPhysical.value.includes('Checked') && x.isCurrentPhysical === true)
      );
    }

    if(this.filterAddressMailing.value.length > 0){
      this.addressFilteredTableData = this.addressFilteredTableData.filter(x => 
          (this.filterAddressMailing.value.includes('Unchecked') && x.isCurrentMailing === false) ||
          (this.filterAddressMailing.value.includes('Checked') && x.isCurrentMailing === true)
      );
    }

    this.updateAddressDataSource();
  }

  updateAddressDataSource(){    
    this.addressDataSource = new MatTableDataSource<any>(this.addressFilteredTableData);
    this.addressDataSource.paginator = this.addressPaginator;
    this.addressDataSource.sort = this.addressSort;    
  }

  editContactInformationTableEntry(row: any){
    var firmContactInformation:IFirmContactInformation = {id: 0, firm: this.firm, contactInformation: {id: 0, phone: '', cell: '', fax: '', email: '', website: '', contactType: '', ext: '', label: ''}};
    
    const dialogRef = this.dialog.open(AddEditFirmContactInformationComponent, {    
      width: this.isMobile ? '70%' : '30%',    
      data: {contactInformation: row?.original ?? firmContactInformation, entity: 'firm'}
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshFirmContactInformations();      
    });  
  }
  deleteContactInformationEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Contact Information with ID: <strong>' + row.id + '</strong>?',title: 'Delete the Contact Information'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.firmService.deleteFirmContactInformation(row.original.id).subscribe(result=>{
          this.commonService.deleteContactInformation(row.original.contactInformation.id).subscribe(result=>{
            this.toastr.success('Contact Information deleted successfully');
            this.refreshFirmContactInformations();
          },error=>{this.toastr.error('Error deleting Contact Information: ', error)});
        },error=>{this.toastr.error('Error deleting Firm Contact Information: ', error)})
      }
    })
  }

  filterContactInformationTable(){
    this.contactInformationFilteredTableData = this.contactInformationTableData;

    this.contactInformationFilteredTableData = this.contactInformationFilteredTableData.filter(x => {      
      return x.id?.toString().includes(this.filterContactInformationString) ||      
        x.phone?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.cell?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.fax?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.email?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.website?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase())
    });

    if(this.filterContactInformationType.value.length > 0){
      this.contactInformationFilteredTableData = this.contactInformationFilteredTableData.filter(x => this.filterContactInformationType.value.includes(x.contactType));
    }

    this.updateContactInformationDataSource();
  }

  updateContactInformationDataSource(){    
    this.contactInformationDataSource = new MatTableDataSource<any>(this.contactInformationFilteredTableData);
    this.contactInformationDataSource.paginator = this.contactInformationPaginator;
    this.contactInformationDataSource.sort = this.contactInformationSort;    
  }

  updateStaffMemberPOCStatus(row: any) {        
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
        data: { message: row.isPOC ? 'Are you sure you want to <strong>Remove</strong> the Contact Person: <strong>' + row.firstName + ' ' + row.lastName + '</strong>?' : 'Are you sure you want to <strong>Add</strong> the Contact Person: <strong>' + row.firstName + ' ' + row.lastName + '</strong>?', title: row.isPOC ? 'Remove the Contact Person' : 'Add the Contact Person' },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        row.isPOC = !row.isPOC;
        var firmPerson: IFirmPerson = {id: row.id, person: row.person, firm: this.firm, isPOC: row.isPOC, application: row.application};
        this.firmService.saveFirmPerson(firmPerson).subscribe(result=>{
          this.toastr.success('Point of Contact status updated successfully');
        },error=>{this.toastr.error('Error updating the Firm Staff: ', error)}) 
      }
    })
  }

  viewPrintCertificateModal(row: any){  
   const dialogRef = this.dialog.open(PrintFirmCertificateModalComponent, {      
    data: {codeType: row.codeType, firmName: row.firm.name, firmAddress: this.addressTableData[0], firmId: row.firm.id, expDate: row.expirationDate,issueDate: row.issueDate, contactName: this.firmStaff[0].person.firstName + ' ' + this.firmStaff[0].person.lastName},   
    width: this.isMobile ? '90%' : '30%',
    autoFocus: false,
    panelClass: this.sharedService.darkMode ? "theme-dark" : ""
  });
  dialogRef.afterClosed().subscribe(result => {
    if(result) {
      
    }        
  }); 
}

  editComplaint(complaint: IComplaint) {    
    if(complaint != null && complaint.id > 0){
      this.complaintService.getComplaintById(complaint.id).subscribe(result=>{   
        const dialogRef = this.dialog.open(AddEditComplaintComponent, {
          width: '90%',
          data: {complaint : result, associationId : this.firm.id, associationType: "Firm"},
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {  
          this.getComplaintAssociations();    
        });      
      },error=>{  
      });   
      }else{ const dialogRef = this.dialog.open(AddEditComplaintComponent, {
        width: '90%',
        data: {complaint : null, associationId : this.firm.id, associationType: "Firm" },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {  
        this.getComplaintAssociations();    
      });
    }   
  }
}

