import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "@env/environment";
import { MsalGuard } from "@azure/msal-angular";

import { DefaultLayoutComponent } from "./layout/default-layout/default-layout.component";
import { HomeComponent } from "./components/home/home.component";
import { UserInfoComponent } from './components/core/user-info/user-info.component';
import { AppInfoComponent } from "./components/core/app-info/app-info/app-info.component";
import { UserAdministrationComponent } from "./components/admin/user-management/user-administration.component";
import { AuthGuard } from "./auth/auth.guard";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { FirmComponent } from "./components/firm/firm.component";
import { FirmApplicationListComponent } from "./components/application/firm/firm-application-list.component";
import { IndividualApplicationListComponent } from "./components/application/individual/individual-application-list.component";
import { IndividualComponent } from "./components/individual/individual.component";
import { AtpComponent } from "./components/atp/atp.component";
import { ComplaintComponent } from "./components/complaint/complaint.component";
import { AtpStudentComponent } from "./components/atp/atp-student/atp-student.component";
import { PaymentDashboardComponent } from "./components/payments/components/dashboard/payment-dashboard.component";
import { ReportsComponent } from "./components/reports/reports-voucher.component";

const routes: Routes = [
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: environment.authEntireSite ? [MsalGuard] : [],
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent, canActivate: [ MsalGuard ]},
      { path: "profile", component: UserInfoComponent, canActivate: [ MsalGuard ]},
      { path: "user-management", component: UserAdministrationComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "atp/management", component: AtpComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "atp/students", component: AtpStudentComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "firm/management", component: FirmComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "firm/applications", component: FirmApplicationListComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "individual/management", component: IndividualComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "individual/applications", component: IndividualApplicationListComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "complaint/list", component: ComplaintComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "payments", component: PaymentDashboardComponent, canActivate: [ MsalGuard, AuthGuard ], data: { roles: ['Admin']}},
      { path: "app-info", component: AppInfoComponent},
      { path: "home", component: HomeComponent },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]  },
      {
        path: "sessions",
        loadChildren: () => import("./components/sessions/sessions.module").then((m) => m.SessionsModule),
      },
    ],
  },
  { path: "**", redirectTo: "home" },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      initialNavigation: !isIframe ? "enabledNonBlocking" : "disabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class RoutesRoutingModule {}
