import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAccountAffiliation } from 'app/models/common/accountAffiliation';
import { IFirm } from 'app/models/firms/firm';
import { CommonDataService } from 'app/services/common/common-data.service';
import { UserService } from 'app/services/user/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-firm-administrator',
  templateUrl: './add-edit-firm-administrator.component.html',
  styleUrls: ['./add-edit-firm-administrator.component.scss']
})
export class AddEditFirmAdministratorComponent implements OnInit {  
  public userList:string[] = [];
  public filteredUserList:string[] = [];
  public selectedUser:string = "";
  public accountAffiliation: IAccountAffiliation = {id:0,userID:"",status:"Active",firm:null,person:null,atp:null};
  public status:string = "Active";
  public uiData = LEAD_BASED_PAINT;
  
  constructor(private dialogRef: MatDialogRef<AddEditFirmAdministratorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{firm:IFirm, affiliations:IAccountAffiliation[], affiliation:IAccountAffiliation},
    public commonService:CommonDataService,
    public userService:UserService,
    private toastr: ToastrService) { }

  ngOnInit(): void {    
    this.accountAffiliation = this.data.affiliation !== null ? this.data.affiliation : this.accountAffiliation;
    this.status = this.accountAffiliation.status;
    this.selectedUser = this.accountAffiliation.userID;
    this.accountAffiliation.firm = this.data.firm;
    this.userService.getAllUsers().subscribe(result=>{
      this.userList = result.map(x=>x.email).filter(user => !this.data.affiliations.some(affiliation => affiliation.userID === user));        
      this.filteredUserList = this.userList;    
    },error=>{});
  }

  filterAdministrators(event: any) {
    const value = event.target.value;
    if (!value) {
      this.filteredUserList = [...this.userList];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredUserList = this.userList.filter(user => user.toLowerCase().includes(filterValue));
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
  onSubmit(): void {
    if(this.filteredUserList.includes(this.selectedUser) || this.data.affiliation.userID === this.selectedUser){
      this.accountAffiliation.userID = this.selectedUser; 
      this.accountAffiliation.status = this.status;     
      this.commonService.saveAccountAffiliation(this.accountAffiliation).subscribe(result=>{
        this.toastr.success("Successfully added the firm administrator");
        this.dialogRef.close(true);
      },error=>{
        this.toastr.error("Failed to add the administrator: ", error);  
      })
    }    
    else{
      this.toastr.error("Failed to add the administrator: The entered user does not exist");
    }
  }
}
