<div *ngIf="sharedService.loading || loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
      <h1>Loading</h1>
  </div>
</div>
  
  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
    <div fxFlex="100" class="matero-col" fxLayoutAlign="start">
      <h2 class="noMarginHeader">
        <mat-icon>business</mat-icon> Firm Management
      </h2>
    </div>

  </div>
  <br />
  <mat-card>
    <div [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
      <div fxLayout="row" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
        <strong class="noMarginHeader">Results ({{filteredFirms.length}})</strong>
        
        <mat-form-field style="width:200px;">
          <input (keyup)="filterTable()" placeholder="Filter Name,CCB,Certificate" [(ngModel)]="filterString" matInput/>        
        </mat-form-field>
        
        <mat-form-field style="width:100px">
          <mat-select placeholder="Filter Inactive" [formControl]="filteredActive" (selectionChange)="filterTable()">
            <mat-option *ngFor="let status of getFirmActiveTypes()" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
        </mat-form-field> 
        
        <mat-form-field style="width:130px">
          <mat-select placeholder="Filter Firm Type" [formControl]="filteredFirmType" (selectionChange)="filterTable()">
            <mat-option *ngFor="let status of ['LBP Firm','RRP Firm','Both']" [value]="status">
              {{ status }}
            </mat-option>
          </mat-select>
        </mat-form-field> 

        <mat-form-field style="width:190px">                                                                
            <input (dateChange)="filterTable()" matInput [matDatepicker]="expireStartPicker" placeholder="CCB Expired Start Date" [formControl]="expirationStartDate">
            <mat-datepicker-toggle matSuffix [for]="expireStartPicker"></mat-datepicker-toggle>
            <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationStartDate.reset(); filterTable()">
              <mat-icon style="color: red;">close</mat-icon>
            </button>   
            <mat-datepicker #expireStartPicker></mat-datepicker>
        </mat-form-field> 
        
        <mat-form-field style="width:180px">                                                                
          <input (dateChange)="filterTable()" matInput [matDatepicker]="expireEndPicker" placeholder="CCB Expired End Date" [formControl]="expirationEndDate">
          <mat-datepicker-toggle matSuffix [for]="expireEndPicker"></mat-datepicker-toggle>
          <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationEndDate.reset(); filterTable()">
            <mat-icon style="color: red;">close</mat-icon>
          </button>   
          <mat-datepicker #expireEndPicker></mat-datepicker>
      </mat-form-field> 
      </div>
      <br />
      <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
        <ng-container *ngFor="let cellData of firmTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
            <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                <ng-container *ngIf="cellData.type === 'date'">
                  <div *ngIf="isMobile" fxLayout="row">  
                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                    <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                  </div>
                  <div *ngIf="!isMobile">  
                      {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                  </div>
                </ng-container>
                <ng-container *ngIf="cellData.type === 'boolean'">
                  <div *ngIf="isMobile" fxLayout="row">
                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                    <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                  </div>
                  <div *ngIf="!isMobile">
                      <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                  </div>                            
                </ng-container>
                <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                  <div *ngIf="isMobile" fxLayout="row">                                    
                    <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                    <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                  </div>
                  <div *ngIf="!isMobile">
                      {{displayLimit(row[cellData.columnName], cellData.size)}}
                  </div>  
                </ng-container>
            </mat-cell>
        </ng-container>                    
      
        <!-- Button column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
            <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
              <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                <span><strong>Action: </strong></span>
                <button mat-icon-button color="primary" (click)="editFirm(row)">
                    <mat-icon>{{'edit'}}</mat-icon>
                </button>
              </div>
              <div *ngIf="!isMobile">
                  <button mat-icon-button color="primary" (click)="editFirm(row)">
                      <mat-icon>{{'edit'}}</mat-icon>
                  </button>
              </div>
            </mat-cell>
        </ng-container>
                            
        <!-- Column definitions -->
        <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="firmTable.layout.columns.concat('actions')"></mat-header-row>
        <mat-row fxLayout="row" fxLayout.lt-md="column" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: firmTable.layout.columns.concat('actions')"></mat-row>
      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, filteredFirms.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>