import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { environment } from '@env/environment';
import { IAddress } from 'app/models/common/address';
import { IDashboardFilter } from 'app/models/filters/dashBoardFilter';
import { IFirm } from 'app/models/firms/firm';
import { IFirmApplication } from 'app/models/firms/firm-application';
import { IFirmApplicationDeficiency } from 'app/models/firms/firm-application-deficiency';
import { IFirmApplicationDocument } from 'app/models/firms/firm-application-document';
import { IFirmApplicationPerson } from 'app/models/firms/firm-application-person';
import { IFirmAddress } from 'app/models/firms/firmAddress';
import { IFirmCertificate } from 'app/models/firms/firmCertificate';
import { IFirmContactInformation } from 'app/models/firms/firmContactInformation';
import { IFirmDashboard } from 'app/models/firms/firmDashboard';
import { IFirmLeadCredential } from 'app/models/firms/firmLeadCredentials';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class FirmService {
  private api: string = environment.privateApi;
  actionType: Array<String>[] = [];
  errorMessages = [];

  constructor(private toastr: ToastrService, private http?: HttpClient) {}

  getDashboardFirmData(filter: IDashboardFilter): Observable<IFirmDashboard> {    
    let params = new HttpParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key] !== null && filter[key] !== undefined) {
        params = params.append(key, filter[key]);
      }
    }    
    return this.http.get<IFirmDashboard>(this.api + 'Firm/GetDashboardFirmData', { params, withCredentials: true });
  }

  saveFirmAddress(address: IFirmAddress): Observable<any> {
    return this.http.post<any>(environment.privateApi + 'Firm/SaveFirmAddress', address, httpOptions);
  }

  getFirmAddresses(id: number): Observable<IFirmAddress[]> {
    return this.http.get<IFirmAddress[]>(environment.privateApi + 'Firm/GetFirmAddresses/' + id, { withCredentials: true });
  }

  deleteFirmAddress(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Firm/DeleteFirmAddress/' + id, httpOptions);
  }
  
  getFirms(): Observable<IFirm[]> {
    return this.http.get<IFirm[]>(environment.privateApi + 'Firm/AllFirms', { withCredentials: true });
  }

  getFirm(firmDetailsId: number): Observable<IFirm> {
    return this.http.get<IFirm>(environment.privateApi + 'Firm/GetFirm/' + firmDetailsId, { withCredentials: true });
  }

  deleteFirm(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Firm/DeleteFirm/' + id, httpOptions);
  }

  saveFirm(firm: IFirm): Observable<any> {
    return this.http.post(environment.privateApi + 'Firm/SaveFirm', firm, httpOptions);
  }



  getFirmLeadCredentials(id: number): Observable<IFirmLeadCredential[]> {
    return this.http.get<IFirmLeadCredential[]>(environment.privateApi + 'Firm/AllFirmLeadCredentials/' + id, { withCredentials: true });
  }

  getFirmLeadCredential(id: number): Observable<IFirmLeadCredential> {
    return this.http.get<IFirmLeadCredential>(environment.privateApi + 'Firm/GetFirmLeadCredential/' + id, { withCredentials: true });
  }

  deleteFirmLeadCredential(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Firm/DeleteFirmLeadCredential/' + id, httpOptions);
  }

  saveFirmLeadCredential(firmLeadCredential: IFirmLeadCredential): Observable<any> {
    return this.http.post(environment.privateApi + 'Firm/SaveFirmLeadCredential', firmLeadCredential, httpOptions);
  }



  getAllCertificatesForFirms(): Observable<IFirmCertificate[]> {
    return this.http.get<IFirmCertificate[]>(environment.privateApi + 'Firm/GetAllCertificatesForFirms/', { withCredentials: true });
  }

  getFirmCertificates(id: number): Observable<IFirmCertificate[]> {
    return this.http.get<IFirmCertificate[]>(environment.privateApi + 'Firm/AllFirmCertificates/' + id, { withCredentials: true });
  }

  getFirmCertificate(id: number): Observable<IFirmCertificate> {
    return this.http.get<IFirmCertificate>(environment.privateApi + 'Firm/GetFirmCertificate/' + id, { withCredentials: true });
  }

  getNextFirmCertificateNumber(): Observable<number> {    
    return this.http.get<number>(this.api + 'Firm/GetNextFirmCertificateNumber', { withCredentials: true });
  }

  deleteFirmCertificate(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Firm/DeleteFirmCertificate/' + id, httpOptions);
  }

  saveFirmCertificate(firmCertificate: IFirmCertificate): Observable<any> {
    return this.http.post(environment.privateApi + 'Firm/SaveFirmCertificate', firmCertificate, httpOptions);
  }


  //Firm Applications
  getAllFirmApplications(): Observable<IFirmApplication[]> {        
    return this.http.get<IFirmApplication[]>(this.api + 'Firm/GetAllFirmApplications/', { withCredentials: true });
  }

  getFirmApplicationById(id: number): Observable<IFirmApplication> {    
    return this.http.get<IFirmApplication>(this.api + 'Firm/GetFirmApplicationById/' + id, { withCredentials: true });
  }

  saveFirmApplication(application: IFirmApplication): Observable<any> {   
    return this.http.post(this.api + 'Firm/SaveFirmApplication', application, httpOptions);
  }

  getFirmApplicationNewPeople(id:number): Observable<IFirmApplicationPerson[]> {    
    return this.http.get<IFirmApplicationPerson[]>(this.api + 'Firm/GetFirmApplicationNewPeople/' + id, { withCredentials: true });
  }

  getFirmApplicationDocuments(id:number): Observable<IFirmApplicationDocument[]> {    
    return this.http.get<IFirmApplicationDocument[]>(this.api + 'Firm/GetFirmApplicationDocuments/' + id, { withCredentials: true });
  }

  saveFirmApplicationDocument(applicationDocument: IFirmApplicationDocument): Observable<any> { 
    return this.http.post(this.api + 'Firm/SaveFirmApplicationDocument', applicationDocument, httpOptions);
  }

  deleteFirmApplicationDocument(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmApplicationDocument/' + id, httpOptions);
  }

  deleteFirmApplicationPerson(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmApplicationPerson/' + id, httpOptions);
  }

  saveFirmApplicationPerson(applicationPerson: IFirmApplicationPerson): Observable<any> {        
    return this.http.post(this.api + 'Firm/SaveFirmApplicationPerson', applicationPerson, httpOptions);
  }

  getFirmApplicationDeficiencies(id:number): Observable<IFirmApplicationDeficiency[]> {    
    return this.http.get<IFirmApplicationDeficiency[]>(this.api + 'Firm/GetAllFirmApplicationDeficiencies/' + id, { withCredentials: true });
  }

  saveFirmApplicationDeficiency(applicationDeficiency: IFirmApplicationDeficiency): Observable<any> { 
    return this.http.post(this.api + 'Firm/SaveFirmApplicationDeficiency', applicationDeficiency, httpOptions);
  }

  deleteFirmApplicationDeficiency(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmApplicationDeficiency/' + id, httpOptions);
  }
  
  getFirmContactInformations(id:number): Observable<IFirmContactInformation[]> {    
    return this.http.get<IFirmContactInformation[]>(this.api + 'Firm/GetFirmContactInformations/' + id, { withCredentials: true });
  }

  getFirmPeopleAndContactInformation(firmID:number): Observable<any[]> {    
    return this.http.get<any[]>(this.api + 'Firm/GetFirmPeopleAndContactInformation/' + firmID, { withCredentials: true });
  }

  saveFirmContactInformation(contactInformation: IFirmContactInformation): Observable<any> { 
    return this.http.post(this.api + 'Firm/SaveFirmContactInformation', contactInformation, httpOptions);
  }

  deleteFirmContactInformation(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmContactInformation/' + id, httpOptions);
  }

  saveFirmPerson(firmPerson: IFirmPerson): Observable<any> { 
    return this.http.post(this.api + 'Firm/SaveFirmPerson', firmPerson, httpOptions);
  }

  deleteFirmPerson(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmPerson/' + id, httpOptions);
  }

  getFirmPeopleByPersonId(personID:number): Observable<IFirmContactInformation> {    
    return this.http.get<IFirmContactInformation>(this.api + 'Firm/GetFirmPeopleByPersonId/' + personID, { withCredentials: true });
  }

  getFirmPeople(firmID:number): Observable<IFirmPerson[]> {    
    return this.http.get<IFirmPerson[]>(this.api + 'Firm/GetFirmPeople/' + firmID, { withCredentials: true });
  }

  getAllFirmPeople(): Observable<IFirmPerson[]> {    
    return this.http.get<IFirmPerson[]>(this.api + 'Firm/GetAllFirmPeople', { withCredentials: true });
  }

  getFirmApplicationPeople(firmApplicationId:number): Observable<IFirmPerson[]> {    
    return this.http.get<IFirmPerson[]>(this.api + 'Firm/GetFirmApplicationPeople/' + firmApplicationId, { withCredentials: true });
  }  

  validateFieldRequired(form: FormGroup, fieldName: string): boolean {
    if (form.hasError(`${fieldName}Required`) && form.get(fieldName).touched) {
      form.get(fieldName).setErrors(['required']);
      return true;
    }
    form.get(fieldName).clearValidators();
    form.get(fieldName).updateValueAndValidity();
    return false;
  }
}