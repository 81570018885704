import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IApplicationInfo } from 'app/models/app-info/app-info';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class AppService {
  private api: string = environment.publicApi;
  constructor(private http: HttpClient) {}

  getAppInfo(): Observable<IApplicationInfo>{
    return this.http.get<IApplicationInfo>(this.api + "Application/info", httpOptions);
  }

}
