import { Component, Output, EventEmitter,
         Input, ChangeDetectionStrategy, ViewEncapsulation, OnInit,
} from "@angular/core";
import { environment } from "environments/environment";
import { Subject, Subscription } from "rxjs";
import { AuthService } from "app/auth/auth.service";
import { SharedService } from "app/services/core/shared.service";
import { EventMessage, EventType } from "@azure/msal-browser";
import { filter } from "rxjs/operators";
import { MsalBroadcastService } from "@azure/msal-angular";
import { Router } from "@angular/router";
import { CommonDataService } from "app/services/common/common-data.service";

@Component({
  selector: "app-navbar",
  host: {
    class: "matero-header",
  },
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})

export class NavbarComponent implements OnInit  {  
  subscription !: Subscription;
  public environment = environment;
  public authenticated: boolean = false;
  @Input() isMobile = false;
  @Input() showToggle = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    public sharedService: SharedService,
    public commonService: CommonDataService,
    public router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void{
    if (this.isAuthenticated()) {
      this.postLogin();
    }
    else{
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      ).subscribe(
        () => {
          this.postLogin();
          this.toggleSidenav.emit();
        },
        error => console.log('error', error)
      );
    }
  }

  postLogin() {
    this.authService.setActiveAccount();
    this.authService.loadUserInformation();
  }

  login() {
    this.authService.login();
  }

  get username(): string {
    return this.authService.username;
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
  }

  toggleTheme() {
    this.sharedService.darkMode = !this.sharedService.darkMode;
    this.sharedService.userTheme = this.sharedService.darkMode ? "dark" : "light";
    if(this.isAuthenticated()){
      this.sharedService.updateUserTheme();
    }
    this.sharedService.toggleTheme();
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
