import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IPerson } from 'app/models/People/person';
import { IFirm } from 'app/models/firms/firm';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-firm-affiliation',
  templateUrl: './add-firm-affiliation.component.html',
  styleUrls: ['./add-firm-affiliation.component.scss']
})
export class AddFirmAffiliationComponent implements OnInit {
  public firmList:IFirm[] = [];
  public filteredFirmList: IFirm[] = [];
  public selectedFirm:string;  
  public uiData = LEAD_BASED_PAINT;
  
  constructor(private dialogRef: MatDialogRef<AddFirmAffiliationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{currentFirms:IFirm [],person:IPerson},
    public firmService:FirmService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {    
    this.firmService.getFirms().subscribe(result=>{          
      this.firmList = this.data.currentFirms.length ? result.filter(firm => !this.data.currentFirms.some(item => item?.id === firm?.id)) : result;      
      this.filteredFirmList = this.firmList;   
    },error=>{})
  }

  filterFirms(event: any) {
    const value = event.target.value;
    if (!value) {
      this.filteredFirmList = [...this.firmList];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredFirmList = this.firmList.filter(firm => firm.name.toLowerCase().includes(filterValue));
  }  

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const fiteredFirms = this.firmList.filter(firm=> firm.name === this.selectedFirm);
    if (fiteredFirms.length){
      const firmToAdd = fiteredFirms[0];
      const firmPerson: IFirmPerson = {
        id: 0,
        firm: firmToAdd,
        person: this.data.person,
        application: null,
        isPOC: false,
      }
      this.firmService.saveFirmPerson(firmPerson).subscribe(result=>{
        this.toastr.success("Firm " + firmToAdd.id + ": " + firmToAdd.name + "was added to the person " + this.data.person.id + ": " + this.data.person.firstName + " " + this.data.person.lastName)
        this.dialogRef.close(true);
      },error=>{
        this.toastr.error("Failed to add the firm person realationship: ", error);
      })   
    }
  }


}
