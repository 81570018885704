import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IComplaintInspection } from 'app/models/complaint/complaintInspection';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-inspection',
  templateUrl: './add-edit-inspection.component.html',
  styleUrls: ['./add-edit-inspection.component.scss']
})
export class AddEditInspectionComponent implements OnInit {
    scopeTypeOptions = [
        {value:'recordKeeping', name:'Record Keeping', checked:false},
        {value:'workPractice', name:'Work Practice', checked:false},
        {value:'preNotification', name:'Pre-Notification', checked:false},
        {value:'initiatedByTipOrComplaint', name:'Initiated by Tip or Complaint', checked:false}
      ];
     inspectionTypeOptions = [
        {value:'inspection', name:'Inspection', checked:false},
        {value:'narrative', name:'Narrative', checked:false},
        {value:'followUp', name:'Follow Up', checked:false}
      ];
  public complaintInspectionDetails: string;
  public selectedInspectionType : string;
  public complaintInspectionToAdd: IComplaintInspection = {
    id: 0, complaintId: 0,
    inspectionType: '',
    inspectionDetails: '',
    inspectorName: '',
    inspectionDate: undefined,
    preNotification: false,
    recordKeeping: false,
    lastUpdatedBy: '',
    lastUpdated: undefined,
    tip: false,
    workPractice: false,
    scope: ''
  };
  public defaultDate: Date = new Date();
  @ViewChild('datePicker') datePicker: MatDatepicker<any>;
  
  constructor(private dialogRef: MatDialogRef<AddEditInspectionComponent>,
    public complaintService: ComplaintService,
    @Inject(MAT_DIALOG_DATA) public data:{complaintId:number,  selected:IComplaintInspection},      
    private toastr: ToastrService) {
      this.defaultDate.setFullYear(this.defaultDate.getFullYear());;
    }

  ngOnInit(): void {    
    this.complaintInspectionToAdd.complaintId = this.data.complaintId;     
    if(this.data.selected !== null){
      this.complaintInspectionToAdd = this.data.selected;
      this.selectedInspectionType = this.inspectionTypeOptions.find(obj => obj.name === this.data.selected.inspectionType)?.value;
      this.defaultDate = new Date(this.data.selected.inspectionDate);
      this.complaintInspectionDetails = this.data.selected.inspectionDetails;
      this.scopeTypeOptions.forEach(option => {
        if(option.value == 'initiatedByTipOrComplaint'){
            option.checked = this.complaintInspectionToAdd.tip;
         }
        if(option.value == 'preNotification'){
            option.checked = this.complaintInspectionToAdd.preNotification;
          }
        if(option.value == 'recordKeeping'){
            option.checked = this.complaintInspectionToAdd.recordKeeping;
          }
        if(option.value == 'workPractice'){
            option.checked = this.complaintInspectionToAdd.workPractice;
          }
      });     
    }
  }

  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value.toISOString();
    this.complaintInspectionToAdd.inspectionDate = selectedDate;    
  }
  
  handleScopeTypeSelection(event: MatCheckboxChange): void {
    const selectedscopeType = event.source.value; 
        switch(selectedscopeType){ 
            case 'recordKeeping':
                this.complaintInspectionToAdd.recordKeeping = event.checked;
                break;
            case 'workPractice':
                this.complaintInspectionToAdd.workPractice = event.checked;
                break;
            case 'preNotification':
                this.complaintInspectionToAdd.preNotification = event.checked;
                break;
            case 'initiatedByTipOrComplaint':
                this.complaintInspectionToAdd.tip = event.checked;
                break;
        }
  }
   
  onCancel(): void {
    this.dialogRef.close(false);
  }
  
  onSubmit(): void {
    if(this.complaintInspectionDetails != null && this.complaintInspectionDetails != ""){   
      this.complaintInspectionToAdd.inspectionDetails = this.complaintInspectionDetails;
      this.complaintInspectionToAdd.complaintId = this.data.complaintId; 
      this.complaintInspectionToAdd.inspectionType = this.selectedInspectionType; 
      this.complaintInspectionToAdd.inspectionDate = this.complaintInspectionToAdd.inspectionDate != null ? this.complaintInspectionToAdd.inspectionDate : new Date().toISOString(); 
      this.complaintService.saveComplaintInspection(this.complaintInspectionToAdd).subscribe(result=>{
        this.toastr.success("Successfully added complaint Inspection")
        this.dialogRef.close(true);
      },error=>{
        this.toastr.error("An error occured saving the complaint Inspection: ", error);
      }); 
    }
    else{
      this.toastr.error("please enter complaint Inspection")
    }
  }
}
