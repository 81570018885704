<section class="p-b-16">
    <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
      <button type="button" mat-stroked-button color="primary" (click)="pdf.saveAs('Complaint' + complaint.complaintNumber +'.pdf')">
        Export to PDF
      </button>
  
      <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
    </div>
  </section>
  <mat-dialog-content>
    <kendo-pdf-export #pdf
                      paperSize="letter"
                      [scale]="0.75"
                      margin="1cm"
                      keepTogether="span">
  
                      <div fxLayout="column">
                        <span style="font-size: x-large" fxFlex><strong>Complaint Number </strong>- {{complaint.complaintNumber}} <strong> Status </strong>- {{complaint.status}}</span><br>
                        <mat-card-content>
                            <mat-card  fxLayout="column" fxLayoutGap="16px">
                              <span><strong>Complaint Source Details</strong></span>  <br>
                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">                                
                                <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                  <div fxLayout="row">
                                    <span><strong>FirstName: </strong> {{complaint.complainteeFirstName}}</span>
                                  </div><br>
                                  <div fxLayout="row">
                                    <span><strong>Address:</strong> {{complaint.complainteeAddress}} </span>
                                  </div><br>
                                  <div fxLayout="row">
                                    <span><strong>Employer: </strong>{{complaint.complainteeEmployer}}</span>                                   
                                  </div>
                                </div>
                                <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                    <div fxLayout="row">
                                       <span><strong>LastName: </strong>{{complaint.complainteeLastName}}</span>                                     
                                    </div><br>
                                    <div fxLayout="row">
                                      <span><strong>City: </strong>{{complaint.complainteeCity}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>Complaint Date:</strong> {{complaint.complaintDate | date}} </span>
                                    </div>                                   
                                 </div>
                                 <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                    <div fxLayout="row">
                                        <span><strong>Phone: </strong>{{complaint.complainteePhone}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>State: </strong>{{complaint.complainteeState}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                      
                                    </div>  
                                  </div>
                                  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                    <div fxLayout="row">
                                        <span><strong>Email: </strong>{{complaint.complainteeEmail}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>Zip: </strong>{{complaint.complainteeZip}}</span>                                      
                                    </div><br>
                                    <div fxLayout="row">
                                      
                                    </div> 
                                  </div>
                                
                              </div> 
                            </mat-card>
                          </mat-card-content>
                          <mat-card-content>
                            <mat-card  fxLayout="column" fxLayoutGap="16px">
                              <span><strong>Complaint Target Details</strong></span>  <br>
                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">                                
                                <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                  <div fxLayout="row">
                                    <span><strong>Association Name: </strong> {{complaint.complaintAssociationName}}</span>
                                  </div><br>
                                  <div fxLayout="row">
                                    <span><strong>Zip:</strong> {{complaint.complainantZip}} </span>
                                  </div><br>
                                  <div fxLayout="row">
                                    <span><strong>Contractor CCB1: </strong>{{complaint.contractorCCB1}}</span>                                   
                                  </div>
                                </div>
                                <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                    <div fxLayout="row">
                                       <span><strong>Address: </strong>{{complaint.complainantAddress}}</span>                                     
                                    </div><br>
                                    <div fxLayout="row">
                                      <span><strong>Contractor1: </strong>{{complaint.complaintContractor1}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>Contractor CCB2: </strong> {{complaint.contractorCCB2}} </span>
                                    </div>                                   
                                 </div>
                                 <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                    <div fxLayout="row">
                                        <span><strong>City: </strong>{{complaint.complainantCity}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>Contractor2: </strong>{{complaint.complaintContractor2}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>Contractor CCB3: </strong>{{complaint.contractorCCB3}}</span>
                                    </div>  
                                  </div>
                                  <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center start" fxLayoutAlign.lt-md="center center" fxFlex="40">
                                    <div fxLayout="row">
                                        <span><strong>State: </strong>{{complaint.complainantState}}</span>
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>Contractor3: </strong>{{complaint.complaintContractor3}}</span>                                      
                                    </div><br>
                                    <div fxLayout="row">
                                        <span><strong>OtherWorker: </strong>{{complaint.otherWorker}}</span>
                                    </div> 
                                  </div>
                                
                              </div>                  
                             
                            </mat-card>
                          </mat-card-content>
                          <mat-card-content fxLayout="column" fxLayoutGap="16px">                           
              
                            <div fxLayout="row">
                              <mat-card fxFlex="100">
                                <div fxLayout="row" fxFlex="100">
                                  <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                                    <span><strong>Complaint Details</strong></span>
                                    <span >{{complaint.complaintProblem}}   </span>
                                  </div>
                                </div>
                              </mat-card>
                            </div>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
                                <mat-card fxFlex="50">
                                  <div fxLayout="column" fxFlex="100">
                                    <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                                      <span><strong>Jurisdictions </strong></span>
                                      <span >{{complaintJurisdictions}}   </span>
                                    </div>
                                  </div>
                                </mat-card>                  
                                <mat-card fxFlex="50">
                                  <div fxLayout="column" fxFlex="100">
                                    <div style="overflow-x: hidden; overflow-y: auto; text-align: center;" fxLayout="column" fxFlex="100">
                                      <span><strong>Enforcement Categories </strong></span>
                                      <span >{{complaintEnforcementCategory}}   </span>
                                    </div>
                                  </div>
                                </mat-card>
                              </div>
                              <mat-card fxFlex="100"> 
                              <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100">
                                <div fxFlex="50">
                                    <span><strong>Investigator Assigned: </strong>{{complaint.complaintInvestigator}}</span>
                                </div><br>       
                                <div fxFlex="50">
                                    <span><strong>Date Assigned: </strong>{{complaint.investigatorAssignedDate | date}}</span>                                      
                                </div>
                              </div>
                            </mat-card>
                          </mat-card-content>
                           <mat-card class="custom-mat-card">
                            <mat-card-title>
                                <div fxLayout="row">
                                    <span>Complaint Referral</span>
                                </div>
                            </mat-card-title>
                            <mat-card-content>
                                <ng-container>
                                    <div fxLayout="column" fxLayoutGap="8px">
                                        <mat-table [dataSource]="complaintReferralsDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                            <ng-container *ngFor="let cellData of complaintReferralTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                                    <ng-container *ngIf="cellData.type === 'date'">
                                                        <div *ngIf="isMobile" fxLayout="row">  
                                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                            <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                        </div>
                                                        <div *ngIf="!isMobile">  
                                                            {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                        </div>
                                                      </ng-container>
                                                      <ng-container *ngIf="cellData.type === 'boolean'">
                                                        <div *ngIf="isMobile" fxLayout="row">
                                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                            <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                        </div>
                                                        <div *ngIf="!isMobile">
                                                            <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                        </div>                            
                                                      </ng-container>
                                                      <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                                        <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                            <div class="status-card">
                                                                <div *ngIf="isMobile" fxLayout="row">  
                                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                                    <span>{{row[cellData.columnName]}}</span>
                                                                </div>
                                                                <div *ngIf="!isMobile">  
                                                                    {{row[cellData.columnName]}}
                                                                </div>                                    
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #notStatus> 
                                                        <div *ngIf="isMobile" fxLayout="row">                                    
                                                            <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                            <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                                        </div>
                                                        <div *ngIf="!isMobile">
                                                            {{displayLimit(row[cellData.columnName], cellData.size)}}
                                                        </div> 
                                                        </ng-template> 
                                                      </ng-container>
                                                </mat-cell>
                                            </ng-container>    
                                            <!-- Column definitions -->
                                            <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintReferralTable.layout.columns"></mat-header-row>
                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintReferralTable.layout.columns"></mat-row>
                                          </mat-table>            
                                    </div>
                        </ng-container>
                    </mat-card-content>
                    </mat-card>
                    <mat-card class="custom-mat-card">
                        <mat-card-title>
                            <div fxLayout="row">
                                <span>Complaint Associations</span>
                            </div>
                        </mat-card-title>
                        <mat-card-content>
                            <ng-container>
                                <div fxLayout="column" fxLayoutGap="8px">
                                    <mat-table [dataSource]="complaintAssocationDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                        <ng-container *ngFor="let cellData of complaintAssociationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                            <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                                <ng-container *ngIf="cellData.type === 'date'">
                                                    <div *ngIf="isMobile" fxLayout="row">  
                                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                        <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                    </div>
                                                    <div *ngIf="!isMobile">  
                                                        {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                    </div>
                                                  </ng-container>
                                                  <ng-container *ngIf="cellData.type === 'boolean'">
                                                    <div *ngIf="isMobile" fxLayout="row">
                                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                        <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                    </div>
                                                    <div *ngIf="!isMobile">
                                                        <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                    </div>                            
                                                  </ng-container>
                                                  <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                                    <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                        <div class="status-card">
                                                            <div *ngIf="isMobile" fxLayout="row">  
                                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                                <span>{{row[cellData.columnName]}}</span>
                                                            </div>
                                                            <div *ngIf="!isMobile">  
                                                                {{row[cellData.columnName]}}
                                                            </div>                                    
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #notStatus> 
                                                    <div *ngIf="isMobile" fxLayout="row">                                    
                                                        <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                        <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                                    </div>
                                                    <div *ngIf="!isMobile">
                                                        {{displayLimit(row[cellData.columnName], cellData.size)}}
                                                    </div> 
                                                    </ng-template> 
                                                  </ng-container>
                                            </mat-cell>
                                        </ng-container>    
                                        <!-- Column definitions -->
                                        <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintAssociationTable.layout.columns"></mat-header-row>
                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintAssociationTable.layout.columns"></mat-row>
                                      </mat-table>            
                                </div>
                    </ng-container>
                </mat-card-content>
                </mat-card>
                <mat-card class="custom-mat-card">
                    <mat-card-title>
                        <div fxLayout="row">
                            <span>Complaint Actions</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <ng-container>
                            <div fxLayout="column" fxLayoutGap="8px">
                                <mat-table [dataSource]="complaintActionDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <ng-container *ngFor="let cellData of complaintActionTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container *ngIf="cellData.type === 'date'">
                                                <div *ngIf="isMobile" fxLayout="row">  
                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                    <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                </div>
                                                <div *ngIf="!isMobile">  
                                                    {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                </div>
                                              </ng-container>
                                              <ng-container *ngIf="cellData.type === 'boolean'">
                                                <div *ngIf="isMobile" fxLayout="row">
                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                    <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                </div>
                                                <div *ngIf="!isMobile">
                                                    <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                </div>                            
                                              </ng-container>
                                              <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                                <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                    <div class="status-card">
                                                        <div *ngIf="isMobile" fxLayout="row">  
                                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                            <span>{{row[cellData.columnName]}}</span>
                                                        </div>
                                                        <div *ngIf="!isMobile">  
                                                            {{row[cellData.columnName]}}
                                                        </div>                                    
                                                    </div>
                                                </ng-container>
                                                <ng-template #notStatus> 
                                                <div *ngIf="isMobile" fxLayout="row">                                    
                                                    <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                    <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                                </div>
                                                <div *ngIf="!isMobile">
                                                    {{displayLimit(row[cellData.columnName], cellData.size)}}
                                                </div> 
                                                </ng-template> 
                                              </ng-container>
                                        </mat-cell>
                                    </ng-container>    
                                    <!-- Column definitions -->
                                    <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintActionTable.layout.columns"></mat-header-row>
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintActionTable.layout.columns"></mat-row>
                                  </mat-table>            
                            </div>
                </ng-container>
            </mat-card-content>
            </mat-card>
            <mat-card class="custom-mat-card">
                <mat-card-title>
                    <div fxLayout="row">
                        <span>Complaint Insepction/Narrative/Followup</span>
                    </div>
                </mat-card-title>
                <mat-card-content>
                    <ng-container>
                        <div fxLayout="column" fxLayoutGap="8px">
                            <mat-table [dataSource]="complaintInspectionDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                <ng-container *ngFor="let cellData of complaintInspectionTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                        <ng-container *ngIf="cellData.type === 'date'">
                                            <div *ngIf="isMobile" fxLayout="row">  
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                            </div>
                                            <div *ngIf="!isMobile">  
                                                {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                            </div>
                                          </ng-container>
                                          <ng-container *ngIf="cellData.type === 'boolean'">
                                            <div *ngIf="isMobile" fxLayout="row">
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                            </div>
                                            <div *ngIf="!isMobile">
                                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                            </div>                            
                                          </ng-container>
                                          <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                            <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                <div class="status-card">
                                                    <div *ngIf="isMobile" fxLayout="row">  
                                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                        <span>{{row[cellData.columnName]}}</span>
                                                    </div>
                                                    <div *ngIf="!isMobile">  
                                                        {{row[cellData.columnName]}}
                                                    </div>                                    
                                                </div>
                                            </ng-container>
                                            <ng-template #notStatus> 
                                            <div *ngIf="isMobile" fxLayout="row">                                    
                                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                            </div>
                                            <div *ngIf="!isMobile">
                                                {{displayLimit(row[cellData.columnName], cellData.size)}}
                                            </div> 
                                            </ng-template> 
                                          </ng-container>
                                    </mat-cell>
                                </ng-container>    
                                <!-- Column definitions -->
                                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintInspectionTable.layout.columns"></mat-header-row>
                                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintInspectionTable.layout.columns"></mat-row>
                              </mat-table>            
                        </div>
            </ng-container>
        </mat-card-content>
        </mat-card>
        <mat-card class="custom-mat-card">
            <mat-card-title>
                <div fxLayout="row">
                    <span>Complaint Owner/Tenant</span>
                </div>
            </mat-card-title>
            <mat-card-content>
                <ng-container>
                    <div fxLayout="column" fxLayoutGap="8px">
                        <mat-table [dataSource]="complaintOwnerTenantDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                            <ng-container *ngFor="let cellData of complaintOwnerTenantTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                    <ng-container *ngIf="cellData.type === 'date'">
                                        <div *ngIf="isMobile" fxLayout="row">  
                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                            <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                        </div>
                                        <div *ngIf="!isMobile">  
                                            {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                        </div>
                                      </ng-container>
                                      <ng-container *ngIf="cellData.type === 'boolean'">
                                        <div *ngIf="isMobile" fxLayout="row">
                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                            <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                        </div>
                                        <div *ngIf="!isMobile">
                                            <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                        </div>                            
                                      </ng-container>
                                      <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                        <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                            <div class="status-card">
                                                <div *ngIf="isMobile" fxLayout="row">  
                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                    <span>{{row[cellData.columnName]}}</span>
                                                </div>
                                                <div *ngIf="!isMobile">  
                                                    {{row[cellData.columnName]}}
                                                </div>                                    
                                            </div>
                                        </ng-container>
                                        <ng-template #notStatus> 
                                        <div *ngIf="isMobile" fxLayout="row">                                    
                                            <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                            <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                        </div>
                                        <div *ngIf="!isMobile">
                                            {{displayLimit(row[cellData.columnName], cellData.size)}}
                                        </div> 
                                        </ng-template> 
                                      </ng-container>
                                </mat-cell>
                            </ng-container>    
                            <!-- Column definitions -->
                            <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintOwnerTenantTable.layout.columns"></mat-header-row>
                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintOwnerTenantTable.layout.columns"></mat-row>
                          </mat-table>            
                    </div>
        </ng-container>
    </mat-card-content>
    </mat-card>
                      </div>

      <ng-template #compliancePlanRuleTemplate let-compliancePlanRule="compliancePlanRule">
        <mat-card>
          <mat-card-content>
            <mat-card  fxLayout="column" fxLayoutGap="16px">
         
    </mat-card>
          </mat-card-content>
        </mat-card>
      </ng-template>
  
    </kendo-pdf-export>
  </mat-dialog-content>
