import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IAddress } from 'app/models/common/address';
import { IFirm } from 'app/models/firms/firm';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class AddressService {
  private api: string = environment.privateApi;
  actionType: Array<String>[] = [];
  errorMessages = [];

  constructor(private toastr: ToastrService, private http?: HttpClient) {}

  getAddresses(): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(environment.privateApi + 'Common/All', { withCredentials: true });
  }

  getAddress(firmDetailsId: number): Observable<IAddress> {
    return this.http.get<IAddress>(environment.privateApi + 'Common/Get/' + firmDetailsId, { withCredentials: true });
  }

  deleteAddress(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Common/Delete/' + id, httpOptions);
  }

  saveAddress(address: IAddress): Observable<any> {
    return this.http.post(environment.privateApi + 'Common/SaveAddress', address, httpOptions);
  }
}