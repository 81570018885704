<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>
  
  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
    <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
      <h2 class="noMarginHeader">
        <mat-icon>place</mat-icon> Address
      </h2>
    </div>
    <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
      <button mat-raised-button color="primary" title="Add Address" (click)="editAddress(0)">
        <mat-icon>add</mat-icon> Add Address
      </button>
      &nbsp;&nbsp;
      <button mat-button mat-dialog-close="Cancel" aria-label="Cancel" mat-raised-button color="basic"
      title="Close">Close</button>
      &nbsp;&nbsp;      
    </div>
  </div>
  <br />
  <mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
      <strong class="noMarginHeader">Results ({{addresses.length}})</strong>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <mat-form-field style="width:200px;">
        <!--<input (keyup)="filterTable($event.target.value)" placeholder="Filter Addresses" matInput />-->
        <input placeholder="Filter Addresses" matInput />
      </mat-form-field>
      <br />
      <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8"
        style="overflow-x: auto;">
        <mat-row>Results ({{addresses.length}})</mat-row>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
        <ng-container matColumnDef="Street">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="37" fxFlex.lt-sm="100">Street</mat-header-cell>
          <mat-cell *matCellDef="let result" fxFlex="37" fxFlex.lt-sm="100">
            <a (click)="editAddress(result.id)" title="Edit {{result.street}}"
              *ngIf="result.street != ''">{{result.street}}</a>
            <a (click)="editAddress(result.id)" title="Edit Address" *ngIf="result.street == ''">Street</a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="City">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20" fxFlex.lt-sm="100">City</mat-header-cell>
            <mat-cell *matCellDef="let result" fxFlex="20" fxFlex.lt-sm="100">
              <span>{{result.city}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="State">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20" fxFlex.lt-sm="100">State</mat-header-cell>
            <mat-cell *matCellDef="let result" fxFlex="20" fxFlex.lt-sm="100">
              <span>{{result.state}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Zip">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20" fxFlex.lt-sm="100">Zip</mat-header-cell>
            <mat-cell *matCellDef="let result" fxFlex="20" fxFlex.lt-sm="100">
              <span>{{result.zip}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="County">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10" fxFlex.lt-sm="100">County</mat-header-cell>
          <mat-cell *matCellDef="let result" fxFlex="10" fxFlex.lt-sm="100">
            <span>{{result.county}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Type">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10" fxFlex.lt-sm="100">Address Type</mat-header-cell>
            <mat-cell *matCellDef="let result" fxFlex="10" fxFlex.lt-sm="100">
              <span>{{result.contactType}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100" style="justify-content: center !important;">
            Actions</mat-header-cell>
          <mat-cell *matCellDef="let result" fxFlex="7" fxFlex.lt-sm="100" style="justify-content: center !important;">
            <a href="javascript:void(0)" (click)="deleteAddress(result.id)" title="Delete {{result.street}}">
              <mat-icon>delete</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, addresses.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>