import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IComplaintOwnerTenant } from 'app/models/complaint/complaintOwnerTenant';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-add-edit-ownertenant',
    templateUrl: './add-edit-ownertenant.component.html',
    styleUrls: ['./add-edit-ownertenant.component.scss']
})
export class AddEditOwnerTenantComponent implements OnInit {
    public complaintOwnerTenant: IComplaintOwnerTenant = {
        id: 0,
        complaintId: 0,
        isOwner: false,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        addressId: 0,
        address: '',
        city: '',
        state: '',
        zip: '',
        cellNumber: '',
        fax: '',
        county: ''
    };
    public complaintOwnerTenantForm = null;
    constructor(private dialogRef: MatDialogRef<AddEditOwnerTenantComponent>,
        public complaintService: ComplaintService,
        @Inject(MAT_DIALOG_DATA) public data:{complaintId:number,  selected:IComplaintOwnerTenant}, 
        public sharedService: SharedService,     
        private toastr: ToastrService) {
        this.setComplaintOwnerTenantFields();
        this.complaintOwnerTenant = this.data.selected ?? this.complaintOwnerTenant;    
        this.loadComplaintOwnerTenantFields();
    }

    ngOnInit(): void {
        // Add your initialization logic here
    }

    loadComplaintOwnerTenantFields(){
        for (const controlName in this.complaintOwnerTenant) {      
            if (this.complaintOwnerTenantForm.controls.hasOwnProperty(controlName)) {
              this.complaintOwnerTenantForm.controls[controlName].setValue(this.complaintOwnerTenant[controlName]);        
            }
          }
      }

    setComplaintOwnerTenantFields(){
        this.complaintOwnerTenantForm = new UntypedFormGroup(
            {
              firstName: new UntypedFormControl("", [Validators.required]), 
              lastName: new UntypedFormControl(""),
              phoneNumber: new UntypedFormControl(""),
              email: new UntypedFormControl(""),
              address: new UntypedFormControl(""),
              city: new UntypedFormControl(""),   
              state: new UntypedFormControl(""),             
              zip: new UntypedFormControl(0),
              isOwner:new UntypedFormControl(false),
              county: new UntypedFormControl(""),
              fax: new UntypedFormControl(""),
              cellNumber: new UntypedFormControl("")
            }, 
            {
              validators: [     
              ]
            }
          );       
      }

      onCancel(): void {
        this.dialogRef.close(false);
      }
      updateComplaintOwnerTenant(): void {    
        for (const controlName in this.complaintOwnerTenant) {
            if (this.complaintOwnerTenantForm.controls.hasOwnProperty(controlName)) {
              this.complaintOwnerTenant[controlName] = this.complaintOwnerTenantForm.controls[controlName].value;                
            }
          } 
      }


      
      onSubmit(): void {
        if(this.complaintOwnerTenantForm &&this.complaintOwnerTenantForm.valid){   
            this.updateComplaintOwnerTenant();
            this.complaintOwnerTenant.complaintId = this.data.complaintId;
            this.complaintService.saveComplaintOwnerTenant(this.complaintOwnerTenant).subscribe(result=>{
                this.toastr.success("Successfully added complaint Owner/Tenant details")
                this.dialogRef.close(true);
              },error=>{
                this.toastr.error("An error occured saving the complaint Owner/Tenant details: ", error);
              }); 
        }
        else{
          this.toastr.error("please enter complaint Owner/Tenant details")
        }
      }

}