import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FIRM_ADDRESS_TYPES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { AddEditAtpCourseComponent } from 'app/components/atp/add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { IAddress } from 'app/models/common/address';
import { IFirmAddress } from 'app/models/firms/firmAddress';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-firm-address',
  templateUrl: './add-edit-firm-address.component.html',
  styleUrls: ['./add-edit-firm-address.component.scss']
})
export class AddEditFirmAddressComponent implements OnInit {
  public addressDataForm = null;
  public addressData: any;
  public uiData = LEAD_BASED_PAINT;  
  public color = 'white';

  constructor(public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{address: any, entity: string},
    private toastr: ToastrService,
    private firmService: FirmService, 
    private personService: PersonService,
    private commonService: CommonDataService,   
    private dialogRef: MatDialogRef<AddEditFirmAddressComponent>,
    public dialog: MatDialog) { 
      if(this.data.entity === null || this.data.entity === undefined || this.data.entity === ''){
        this.data.entity = 'firm';
      }
      
      this.setColor();
    }

  ngOnInit(): void {
    this.initializeDataFormFields();
    this.loadData();
  }

  loadData(){  
    this.addressData = this.data.address;    
    this.loadDataForm();
  }

  initializeDataFormFields(){       
    this.addressDataForm = new UntypedFormGroup({
      contactType: new UntypedFormControl(""),
      street: new UntypedFormControl(""),
      city: new UntypedFormControl(""),
      state: new UntypedFormControl(""),
      zip: new UntypedFormControl(""),
      county: new UntypedFormControl(""),      
      isCurrentPhysical: new UntypedFormControl(false),
      isCurrentMailing: new UntypedFormControl(false),
    }); 
  }

  loadDataForm(){
    for (const controlName in this.addressData) {
      if (this.addressDataForm.controls.hasOwnProperty(controlName)) {        
        this.addressDataForm.controls[controlName].setValue(this.addressData[controlName]);          
      }
    }  
    for (const controlName in this.addressData.address) {
      if (this.addressDataForm.controls.hasOwnProperty(controlName)) {        
        this.addressDataForm.controls[controlName].setValue(this.addressData.address[controlName]);          
      }
    }    
  }

  updateAddressData(){
    for (const controlName in this.addressData) {
      if (this.addressDataForm.controls.hasOwnProperty(controlName)) {
        this.addressData[controlName] = this.addressDataForm.controls[controlName].value;        
      }
    } 
    for (const controlName in this.addressData.address) {
      if (this.addressDataForm.controls.hasOwnProperty(controlName)) {
        this.addressData.address[controlName] = this.addressDataForm.controls[controlName].value;        
      }
    }     
  }

  getAddressTypes(){
    return Object.values(FIRM_ADDRESS_TYPES);
  }

  savePrompt(){
    var isNew = this.addressData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Address?' : 'Are you sure you want to <strong>Update</strong> the Address?',title: isNew ? 'Add the Address' : 'Update the Address'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();        
      }
    })
  }

  saveData() {    
    this.updateAddressData();        

    switch(this.data.entity){
      case 'firm':
        this.commonService.saveAddress(this.addressData.address).subscribe(result=>{
          this.addressData.address = result;
          this.firmService.saveFirmAddress(this.addressData).subscribe(result=>{
            this.addressData = result;
            this.toastr.success('Address saved successfully!');        
            this.dialogRef.close(true);
          },error=>{this.toastr.error('Error saving Address: ', error)})      
        },error=>{this.toastr.error('Error saving Address: ', error)})
        break;
      case 'individual':
        this.commonService.saveAddress(this.addressData.address).subscribe(result=>{
          this.addressData.address = result;
          this.personService.savePersonAddress(this.addressData).subscribe(result=>{
            this.addressData = result;
            this.toastr.success('Address saved successfully!');        
            this.dialogRef.close(true);
          },error=>{this.toastr.error('Error saving Address: ', error)})      
        },error=>{this.toastr.error('Error saving Address: ', error)})
        break;
    }
  }

  setColor(){    
    switch(this.data.entity){
      case 'individual':
        this.color = this.uiData.individual.color;
        break;
      case 'firm':
        this.color = this.uiData.firm.color;
        break;
    }
    return 
  }
}