import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from 'app/auth/auth.models';
import { environment } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class UserService {
  private api: string = environment.privateApi;
  constructor(private httpClient: HttpClient) {}

  getUsers(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(this.api + 'UserAdministration/users' ,  { withCredentials: true });
  }

  getAllUsers(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(this.api + 'UserAdministration/allUsers' ,  { withCredentials: true });
  }

  getUser(email: string): Observable<IUser> {
    return this.httpClient.get<IUser>(this.api + 'UserAdministration/user/' + email,  { withCredentials: true });
  }

  removeUser(username: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.api + 'UserAdministration/user/remove/' + username,  { withCredentials: true });
  }

  findUser(filter: string) : Observable<IUser> {
    return this.httpClient.get<IUser>(this.api + 'UserAdministration/user/find/' + filter,  { withCredentials: true });
  }

  getRoles() : Observable<string[]> {
    return this.httpClient.get<string[]>(this.api + 'UserAdministration/roles' ,  { withCredentials: true });
  }

  addUser(user: IUser) : Observable<any> {
    return this.httpClient.post<any>(this.api + 'UserAdministration/user/add', user, httpOptions);
  }

  saveUser(user: IUser) : Observable<any> {
    return this.httpClient.post<any>(this.api + 'UserAdministration/user/save', user, httpOptions);
  }
}
