<mat-dialog-content id="editPayment">
<div fxLayout="Column" fxLayoutGap="8px">
    <span class="f-s-20">{{payment.id > 0? 'Edit' : 'Add'}} Payment</span>
    <form [formGroup]="paymentForm" (ngSubmit)="savePayment()" class="p-t-16">
      <div fxLayout="column" fxLayoutGap="16px" fxLayoutGap.lt-md="4px">
        <mat-form-field>
            <mat-label>Payment Type</mat-label>
            <mat-select formControlName="feeType" (selectionChange)="onChangePaymentType()">
              <mat-option>-- None --</mat-option>
              <mat-option *ngFor="let option of paymentService.paymentOptionsByEntityType(payment.entityType)" value={{option.FeeType}}>{{option.Text}}
              </mat-option>
            </mat-select>
          <mat-error *ngIf="paymentForm.get('feeType').invalid">
            Please enter a type
          </mat-error>
        </mat-form-field>
         <mat-form-field>
            <mat-label>Payment Status</mat-label>
            <mat-select formControlName="status" >
              <mat-option>-- None --</mat-option>
              <mat-option *ngFor="let option of paymentService.paymentStatuses" value={{option}}>{{option}}
              </mat-option>
            </mat-select>
          <mat-error *ngIf="paymentForm.get('status').invalid">
            Please enter a payment status
          </mat-error>
        </mat-form-field>
        <mat-form-field >
          <mat-label>Payment Cleared </mat-label>
          <input formControlName="paymentCleared"
                 placeholder="mm/dd/yyyy"
                 matInput
                 [matDatepicker]="datepickerPaymentCleared"
                 [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="datepickerPaymentCleared"></mat-datepicker-toggle>
          <mat-datepicker #datepickerPaymentCleared>
            <mat-datepicker-actions>
              <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
              <button mat-button matDatepickerCancel>Cancel</button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error *ngIf="paymentForm.get('paymentCleared').value > today">
            Please choose an earlier date.
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Amount" formControlName="amount">
          <mat-error *ngIf="paymentForm.get('amount').invalid">
            Please enter an amount
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Payment Method</mat-label>
          <mat-select formControlName="paymentMethod" >
            <mat-option>-- None --</mat-option>
            <mat-option *ngFor="let option of paymentService.paymentMethods" value={{option}}>{{option}}
            </mat-option>
          </mat-select>
        <mat-error *ngIf="paymentForm.get('paymentMethod').invalid">
          Please enter a payment method
        </mat-error>
      </mat-form-field>
        <mat-form-field>
          <textarea formControlName="comment"
                    matInput
                    rows="5"
                    placeholder="Comments"
                    type="text">
        </textarea>
          <mat-error *ngIf="paymentForm.get('comment').invalid">
            Please enter comments
          </mat-error>
        </mat-form-field> 
        <div fxLayout="row" fxLayoutGap="16px" class="m-t-16">
          <button *ngIf="paymentForm.valid && paymentForm.get('paymentCleared').value <= today"
                  mat-raised-button type="button" color="primary" [disabled]="processing"
                  (click)="savePayment()">Save
          </button>
          <button mat-raised-button type="button" color="warn" (click)="cancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

