import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAtp } from 'app/models/atp/atp';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { AddEditAtpComponent } from './add-edit-atp/add-edit-atp.component';
import { ACCREDITATION_STATUS } from '@shared/utils/app-static-data';

@Component({
  selector: 'app-atp',
  templateUrl: './atp.component.html',
  styleUrls: ['./atp.component.scss']
})
export class AtpComponent {
  public loading: boolean = true;
  public pageSize: number = 50;  
  public trainingProviders: IAtp[] = [];
  public dataSource: MatTableDataSource<any>;
  public filterString: string = '';
  public filteredTrainingProviders = [];
  public filteredActive = new FormControl('Both');
  public filterRRPAccreditationStatus = new FormControl([]);
  public filterLBPAccreditationStatus = new FormControl([]);
  public accreditationStatusOptions = Object.values(ACCREDITATION_STATUS);

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['id', 'inactive', 'name', 'nameAKA', 'atporId', 'privateCompany', 'rrpAccredStatus', 'lbpaAccredStatus'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'5'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'7'},        
        {displayName:'ATP Name',columnName:'name', type:'string', size:'17'},        
        {displayName:'Also Known As',columnName:'nameAKA', type:'string', size:'16'},        
        {displayName:'ATP OR Id',columnName:'atporId', type:'string', size:'10'},
        {displayName:'Private Company',columnName:'privateCompany', type:'boolean', size:'7'},        
        {displayName:'RRP Accreditation Status',columnName:'rrpAccredStatus', type:'string', size:'15'}, 
        {displayName:'LBPA Accreditation Status',columnName:'lbpaAccredStatus', type:'string', size:'16'}, 
      ],
      data: []      
    }
  }
  
  constructor(
    public dialog: MatDialog,
    public sharedService: SharedService,
    public commonService: CommonDataService,
    public atpService: AtpService,    
  ) { 
    this.loadATPs();
  }

  loadATPs(): void {
    this.filterString = this.commonService.atpManagementFilter.filterString;
    this.filteredActive = this.commonService.atpManagementFilter.filteredActive;
    this.filterLBPAccreditationStatus = this.commonService.atpManagementFilter.filterLBPAccreditationStatus;
    this.filterRRPAccreditationStatus = this.commonService.atpManagementFilter.filterRRPAccreditationStatus;

    this.atpService.getATPs().subscribe(result=>{      
      this.trainingProviders = result;    
      this.filteredTrainingProviders = result;  
      this.filterTable();            
      this.loading = false;
    },error=>{})    
  }
  
  updateDataSource(){    
    this.dataSource = new MatTableDataSource<any>(this.filteredTrainingProviders);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;    
  }

  editATP(atp: IAtp) {    
    const dialogRef = this.dialog.open(AddEditAtpComponent, {
      width: '95%',
      data: {atp: atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      this.loadATPs();    
    });
  }

  filterTable() { 
    this.commonService.atpManagementFilter.filterString = this.filterString;
    this.commonService.atpManagementFilter.filteredActive = this.filteredActive;
    this.commonService.atpManagementFilter.filterRRPAccreditationStatus = this.filterRRPAccreditationStatus;
    this.commonService.atpManagementFilter.filterLBPAccreditationStatus = this.filterLBPAccreditationStatus;

    var stringFilter = this.filterString.trim().toLowerCase();    
    this.filteredTrainingProviders = this.trainingProviders;

    this.filteredTrainingProviders = this.filteredTrainingProviders.filter(x => {      
      return x.id?.toString().includes(stringFilter) || 
        x.name?.toLowerCase().includes(stringFilter) || 
        x.nameAKA?.toLowerCase().includes(stringFilter) ||
        x.atporId?.toLowerCase().includes(stringFilter);
    });      

    if (this.filteredActive.value !== 'Both'){
      this.filteredTrainingProviders = this.filteredTrainingProviders.filter(x=>x.inactive === (this.filteredActive.value === 'InActive') ? true : false);
    } 

    if (this.filterRRPAccreditationStatus.value.length > 0){
      this.filteredTrainingProviders = this.filteredTrainingProviders.filter(x => 
          this.filterRRPAccreditationStatus.value.includes(x.rrpAccredStatus) || 
          !x.rrpAccredStatus && this.filterRRPAccreditationStatus.value.includes('None')
      );
    }
  
    if (this.filterLBPAccreditationStatus.value.length > 0){
      this.filteredTrainingProviders = this.filteredTrainingProviders.filter(x => 
          this.filterLBPAccreditationStatus.value.includes(x.lbpaAccredStatus) || 
          !x.lbpaAccredStatus && this.filterLBPAccreditationStatus.value.includes('None')
      );
    }

    this.updateDataSource();
  }

  getFirmActiveTypes(){
    return ['Active','InActive','Both'];
  }

  displayLimit(text: any, limit: string){
    var newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}
