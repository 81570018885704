<div *ngIf="loading">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>

<form #form="ngForm" [formGroup]="paymentService.searchForm" class="form-field-full">
  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
    <div fxFlex="50.00" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
      <h2 class="noMarginHeader">
        <mat-icon>attach_money</mat-icon> Search Payments
      </h2>
    </div>
  </div>
  <div fxLayout="row wrap" class="matero-row">
    <div fxFlex="100.00" fxFlex.lt-sm="100" class="matero-col">
      <mat-card>
        <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field appearance="standard">
              <mat-label>Search Term</mat-label>
              <input id="searchTerm" name="searchTerm" formControlName="searchTerm" matInput />
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field appearance="standard">
              <mat-label>Payment Type</mat-label>
              <mat-select id="paymentType" name="paymentType" formControlName="paymentType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let option of paymentService.paymentOptions" value="{{option.FeeType}}">{{option.Text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>



        <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="standard">
            <mat-label>Payment Status</mat-label>
            <mat-select id="status" name="status" formControlName="status">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let option of paymentService.paymentStatuses" value="{{option}}">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </div>

        <!-- buttons -->
        <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <div fxFlex="100" fxFlex.lt-sm="100" fxLayoutAlign="start center">
            <br /><br /><br /><br />
            <button type="submit" id="submitButtonSearch" (click)="onSubmit()" mat-raised-button color="primary"
            title="Search" [disabled]='loading'>Search</button>
            &nbsp;&nbsp;
            <button type="submit" id="submitButtonReset" (click)="reset()" mat-raised-button color="basic" title="Clear"
              [disabled]='loading'>Clear</button>
            &nbsp;&nbsp;
          </div>
        </div>  
      </mat-card>
    </div>
  </div>
</form>

<br />
<!-- Results -->
<mat-card [style.background]="cardColor" *ngIf="showResults">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">Payments ({{payments.length}}) </span>
        <span class="f-s-14">Click Entry to Edit </span>
      </div>

      <mat-table [dataSource]="paymentsSource" matSort #TableSort="matSort" aria-describedby="owners" class="mat-elevation-z8" >
        <ng-container matColumnDef="entity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Entity</mat-header-cell>
          <mat-cell  *matCellDef="let payment">
            <span class="mobile-label">Entity</span>
            <a (click)="editEntity(payment)">
              <span>{{ payment.entityDescription }} </span>
              <span *ngIf="payment.entityDescription == null || payment.entityDescription.length == 0">[ID: {{payment.entityId}}]</span>
            </a>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="localReferenceId">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Local Reference Id</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">localReferenceId</span>
            {{ payment.localReferenceId }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="orderNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Order Number</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Order Number</span>
            {{ payment.orderNumber }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Type</span>
            {{ paymentService.getPaymentName(payment.entityType, payment.type)}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Status</span>
            {{ payment.status }} &nbsp; <mat-icon *ngIf="payment.paid">done</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paymentCleared">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Payment Cleared</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Cleared</span>
            {{ payment.paymentCleared }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Amount</span>
            {{ payment.amount }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="paymentMethod">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Method</span>
            {{ payment.paymentMethod }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="sku">
          <mat-header-cell *matHeaderCellDef mat-sort-header>SKU</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">SKU</span>
            {{ payment.sku }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Comments</mat-header-cell>
          <mat-cell (click)="editingPayment? editPayment(payment) : ''" *matCellDef="let payment">
            <span class="mobile-label">Comments</span>
            <span style="white-space: pre-wrap">{{ payment.comment }}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef=""></mat-header-cell>
          <mat-cell *matCellDef="let payment">
            <!-- <button mat-icon-button color="warn" aria-label="Delete Payment" *ngIf="allowDelete"
              (click)="deletePayment(payment.id, payment.type)"  [disabled]="!editingPayment">
              <mat-icon>delete</mat-icon>
            </button> -->
            <button mat-raised-button type="button" *ngIf="!payment.paid" color="primary" aria-label="Mark as Cleared" matTooltip="Mark as paid"
              (click)="markAsCleared(payment)" [disabled]="!editingPayment">
              Mark as Cleared
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="paymentColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: paymentColumns">
        </mat-row>
      </mat-table>
    </div>


  </mat-card-content>
</mat-card>
