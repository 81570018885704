<div fxLayout="column">
    <mat-card [style.background-color]="uiData.individual.color">
        <mat-card-title>
            {{'Edit Firm Certificate'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">                         

        <form [formGroup]="certificateForm">
            <div fxLayout="column" fxFlex>
                <mat-form-field class="example-full-width">
                    <mat-label>Stage</mat-label>
                    <mat-select formControlName="stage">
                    <mat-option *ngFor="let stage of stageType" [value]="stage">{{ stage }}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field class="example-full-width">
                    <mat-label>Code Type</mat-label>
                    <mat-select formControlName="codeType">
                    <mat-option *ngFor="let codeType of codeType" [value]="codeType">{{ codeType }}</mat-option>
                    </mat-select>
                </mat-form-field>          
            
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Control Number" formControlName="controlNumber">
                </mat-form-field>
            
                <mat-form-field class="example-full-width">
                    <input matInput type="date" placeholder="Issue Date" formControlName="issueDate">
                </mat-form-field>
            
                <mat-form-field class="example-full-width">
                    <input matInput type="date" placeholder="Recertification Date" formControlName="recertificationDate">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput type="date" placeholder="Expiration Date" formControlName="expirationDate">
                </mat-form-field>    

                <mat-form-field class="example-full-width">
                    <input matInput type="date" placeholder="Date of Letter" formControlName="dateOfLetter">
                </mat-form-field>    
            </div>
        </form>

    </div>
    <mat-card [style.background-color]="uiData.individual.color" fxLayout="row wrap" fxLayoutGap="8px">            
        <span class="text-red-400" *ngIf="data.canEdit">Renewal in progress. Editing Disabled</span>        
        <button mat-raised-button color="primary" style="margin-right: 10px;" [disabled]="!certificateForm.valid" (click)="onSubmit()">Submit</button>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
    </mat-card>    
</div>
  