<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>

<div fxLayout="column">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <h2 class="noMarginHeader">
                <mat-icon>{{'recent_actors'}}</mat-icon> {{'Individual Applications'}}
            </h2>
        </div>
        <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
            <button mat-raised-button color="primary" title="Add Individual Application" (click)="editIndividualApplication(null)">
              <mat-icon>add</mat-icon> Add Individual Application
            </button>                
          </div>
    </div>

    <mat-card>
        <div [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
                <strong class="noMarginHeader">Results ({{filteredApplications.length}})</strong>
                <div fxLayout="column">  
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
                        <mat-form-field style="width:150px;">
                            <input (keyup)="filterTable()" placeholder="Filter Id, Name" [(ngModel)]="filterString" matInput/>                    
                        </mat-form-field>            
                        
                        <mat-form-field style="width:200px">
                            <mat-select multiple placeholder="Filter by Status" [formControl]="selectedStatus" (selectionChange)="filterTable()">
                                <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">
                                {{ status }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field> 

                        <mat-form-field style="width:200px">
                            <mat-select placeholder="Initial/Renewal/Expiring/Outstanding" [formControl]="selectedRenewal" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let type of getApplicationFilterValues()" [value]="type">
                                {{ type }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field> 
                        
                        <mat-form-field style="width:165px">                                                                
                            <input (dateChange)="filterTable()" matInput [matDatepicker]="applicationDateStartPicker" placeholder="Application Start Date" [formControl]="applicationDateFilterStart">
                            <mat-datepicker-toggle matSuffix [for]="applicationDateStartPicker"></mat-datepicker-toggle>
                            <button matSuffix mat-icon-button aria-label="Clear date" (click)="applicationDateFilterStart.reset(); filterTable()">
                                <mat-icon style="color: red;">close</mat-icon>
                            </button>  
                            <mat-datepicker #applicationDateStartPicker></mat-datepicker>
                        </mat-form-field>   
                        
                        <mat-form-field style="width:160px">                                                                
                            <input (dateChange)="filterTable()" matInput [matDatepicker]="applicationDateEndPicker" placeholder="Application End Date" [formControl]="applicationDateFilterEnd">
                            <mat-datepicker-toggle matSuffix [for]="applicationDateEndPicker"></mat-datepicker-toggle>
                            <button matSuffix mat-icon-button aria-label="Clear date" (click)="applicationDateFilterEnd.reset(); filterTable()">
                                <mat-icon style="color: red;">close</mat-icon>
                            </button>  
                            <mat-datepicker #applicationDateEndPicker></mat-datepicker>
                        </mat-form-field>                               
                    </div>                        
                </div>
            </div>
            <br />

            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8">
                <ng-container *ngFor="let cellData of individualApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                        <ng-container *ngIf="cellData.type === 'date'">
                            <ng-container>
                                <div *ngIf="isMobile" fxLayout="row">  
                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                    <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                </div>
                                <div *ngIf="!isMobile">  
                                    {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                </div>                                
                            </ng-container>                         
                        </ng-container>
                        <ng-container *ngIf="cellData.type !== 'date'">                            
                            <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                    <div *ngIf="isMobile" fxLayout="row">  
                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                        <span>{{row[cellData.columnName]}}</span>
                                    </div>
                                    <div *ngIf="!isMobile">  
                                        {{row[cellData.columnName]}}
                                    </div>                                    
                                </div>
                            </ng-container>
                            <ng-template #notStatus>                                
                                <div *ngIf="isMobile" fxLayout="row">                                    
                                    <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                    <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                </div>
                                <div *ngIf="!isMobile">
                                    {{displayLimit(row[cellData.columnName], cellData.size)}}
                                </div>                                
                            </ng-template>
                        </ng-container>
                    </mat-cell>
                </ng-container>                     
              
                <!-- Button column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">                        
                        <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                            <span><strong>Action: </strong></span>
                            <button mat-icon-button color="primary" (click)="editIndividualApplication(row)">
                                <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="!isMobile">
                            <button mat-icon-button color="primary" (click)="editIndividualApplication(row)">
                                <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                            </button>
                        </div>                        
                    </mat-cell>
                </ng-container>
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="individualApplicationTable.layout.columns.concat('actions')"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: individualApplicationTable.layout.columns.concat('actions')"></mat-row>
            </mat-table>
              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
