import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IAzureAdUser } from 'app/models/user-info/azure-ad-user';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class UserInformationService {
  private api: string = environment.privateApi;
  constructor(private http: HttpClient) {}

  getUserInfo(): Observable<IAzureAdUser>{
    return this.http.get<IAzureAdUser>(this.api + "AzureIdentity/GetUserIdentity", httpOptions);
  }
}
