<div fxLayout="column">
    <mat-card [style.background-color]="uiData.individual.color">
        <mat-card-title>
            {{personTestingData.id !== 0 ? 'Edit Testing Data' :'Add Testing Data'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">
        <form [formGroup]="personTestingDataForm" fxLayout="column">
            <div fxLayout="row" fxLayout.lt-md="column">
                <mat-card fxFlex="100" fxLayout="column">
                    <mat-card-content>                                                    
                        <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100" fxLayoutGap="20px"> 
                            <div fxLayout="column" fxFlex="50">
                                <span><strong>Test Discipline</strong></span>                                                       
                                <mat-form-field>
                                    <mat-label>Test Discipline</mat-label>                                    
                                    <mat-select formControlName="codeType">   
                                        <mat-option *ngFor="let status of getTestDisciplines()" [value]="status">{{ status }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <span><strong>Test Location</strong></span>
                                <mat-form-field>
                                    <mat-label>Test Location</mat-label>
                                    <input type="text" matInput formControlName="testLocation" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete">
                                        <mat-option *ngFor="let status of filteredTestLocations | async" [value]="status">
                                            {{ status }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                

                                <span><strong>Test Version</strong></span>                                                       
                                <mat-form-field>
                                    <mat-label>Test Version</mat-label>                                    
                                    <mat-select formControlName="testVersion">   
                                        <mat-option *ngFor="let status of getTestVersions()" [value]="status">{{ status }}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <span><strong>Test Date</strong></span>                                                            
                                <mat-form-field style="width: 100%;">
                                    <input matInput [matDatepicker]="testDateTimePicker" placeholder="Test Date" formControlName="testDate" (focus)="testDateTimePicker.open()">
                                    <mat-datepicker-toggle matSuffix [for]="testDateTimePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #testDateTimePicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="column" fxFlex="50">
                                <span><strong>Test Serial#</strong></span>                                                       
                                <mat-form-field>
                                    <input matInput placeholder="Test Serial#"  formControlName="testSerial">
                                </mat-form-field>

                                <span><strong>Test Score</strong></span>                                                       
                                <mat-form-field>
                                    <input matInput placeholder="Test Score"  formControlName="testScore">
                                </mat-form-field>

                                <span><strong>Pass/Fail</strong></span>                                                       
                                <mat-form-field>
                                    <mat-label>Pass/Fail</mat-label>                                    
                                    <mat-select formControlName="passFail">   
                                        <mat-option *ngFor="let status of getPassFail()" [value]="status">{{ status }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>                                    
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </form>        
    </div>
    <mat-card [style.background-color]="uiData.individual.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()" [disabled]="!personTestingDataForm.valid">
            {{personTestingData.id !== 0 ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>