
<form [formGroup]="complaintReferralForm">
    <div fxLayout="column" fxLayoutGap="8px">
        <span><strong>Complaint Referral</strong></span>  
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"> 
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Complaint Number" formControlName="complaintNumber" >                                    
            </mat-form-field>                   
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="First" formControlName="firstName" >                                    
            </mat-form-field>
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Last" formControlName="lastName" >                                    
            </mat-form-field>          
        </div>  
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"> 
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Agency Name" formControlName="agencyName" >                                    
            </mat-form-field>                   
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Agency Type" formControlName="agencyType" >                                    
            </mat-form-field>
            <mat-form-field>
                <input matInput [matDatepicker]="referralDatePicker" placeholder="" (focus)="datePicker.open()" [value]="defaultDate" (dateChange)="onDateSelected($event)">
                <mat-datepicker-toggle matSuffix [for]="referralDatePicker"></mat-datepicker-toggle>
            </mat-form-field>    
            <mat-datepicker #referralDatePicker></mat-datepicker>
        </div>  
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"> 
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Agency Email" formControlName="agencyEmail">
            </mat-form-field>                    
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Email"  formControlName="email">
            </mat-form-field>
        </div>  
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"> 
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Phone Number" formControlName="phoneNumber">
            </mat-form-field>                    
            <mat-form-field fxFlex="100%">
                <input matInput placeholder="Fax"  formControlName="fax">
            </mat-form-field>
        </div> 
       
        <div>
            <button mat-raised-button (click)="onCancel()">Cancel</button>
            <button mat-raised-button color="primary" [disabled]="!complaintReferralForm.valid" (click)="onSubmit()">{{this.data.selected !== null ? 'Save' : 'Add'}}</button>
        </div>              
    </div>
</form>  
