import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-person-delete',
  templateUrl: './person-delete.component.html',
  styleUrls: ['./person-delete.component.scss']
})
export class PersonDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
