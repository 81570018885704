import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IPerson } from 'app/models/People/person';
import { IPersonCertificate } from 'app/models/People/person-certificate';
import { IPersonApplication } from 'app/models/People/person-application';
import { IPersonApplicationDeficiency } from 'app/models/People/person-application-deficiency';
import { IPersonAddress } from 'app/models/People/personAddress';
import { IPersonContactInformation } from 'app/models/People/personContactInformation';
import { Observable } from 'rxjs';
import { IPersonTesting } from 'app/models/People/personTesting';
import { IPersonApplicationDocument } from 'app/models/People/person-application-document';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { IPersonDashboard } from 'app/models/People/personDashboard';
import { IDashboardFilter } from 'app/models/filters/dashBoardFilter';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private api: string = environment.privateApi;
  
  constructor(private http?: HttpClient) { }

  getDashboardPersonData(filter: IDashboardFilter): Observable<IPersonDashboard> {    
    let params = new HttpParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key] !== null && filter[key] !== undefined) {
        params = params.append(key, filter[key]);
      }
    }    
    return this.http.get<IPersonDashboard>(this.api + 'Person/GetDashboardPersonData', { params, withCredentials: true });
  }

  deletePersonContactInfo(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Person/DeletePersonContactInformation/' + id, httpOptions);
  }

  getPersonContactInformationByPersonId(id:number): Observable<IPersonContactInformation> {    
    return this.http.get<IPersonContactInformation>(this.api + 'Person/GetPersonContactInformationByPersonId/' + id, { withCredentials: true });
  }

  getPersonContactInformationsByPersonId(id:number): Observable<IPersonContactInformation[]> {    
    return this.http.get<IPersonContactInformation[]>(this.api + 'Person/GetPersonContactInformationsByPersonId/' + id, { withCredentials: true });
  }

  savePersonContactInfo(personContactInformation: IPersonContactInformation): Observable<any> {
    return this.http.post(environment.privateApi + 'Person/SavePersonContactInformation', personContactInformation, httpOptions);
  }

  savePerson(person: IPerson): Observable<any> {
    return this.http.post(environment.privateApi + 'Person/SavePerson', person, httpOptions);
  }

  getPeople(): Observable<IPerson[]> {    
    return this.http.get<IPerson[]>(this.api + 'Person/GetPeople/', { withCredentials: true });
  }

  getPeopleFiltered(filter: IPersonFilter): Observable<IPerson[]> {    
    let params = new HttpParams();
    for (const key in filter) {
      if (filter.hasOwnProperty(key) && filter[key] !== null && filter[key] !== undefined) {
        params = params.append(key, filter[key]);
      }
    }    
    return this.http.get<IPerson[]>(this.api + 'Person/GetPeopleFiltered', { params, withCredentials: true });
  }

  getPersonById(id: number): Observable<IPerson> {    
    return this.http.get<IPerson>(this.api + 'Person/GetPersonById/' + id, { withCredentials: true });
  }

  getAllPersonApplications(): Observable<IPersonApplication[]> {    
    return this.http.get<IPersonApplication[]>(this.api + 'Person/GetAllPersonApplications/', { withCredentials: true });
  }

  getPersonApplicationById(id: number): Observable<IPersonApplication> {    
    return this.http.get<IPersonApplication>(this.api + 'Person/GetPersonApplicationById/' + id, { withCredentials: true });
  }

  SavePersonApplication(personApplication: IPersonApplication): Observable<any> {
    return this.http.post(environment.privateApi + 'Person/SavePersonApplication', personApplication, httpOptions);
  }

  getPersonApplicationDeficiencies(id:number): Observable<IPersonApplicationDeficiency[]> {    
    return this.http.get<IPersonApplicationDeficiency[]>(this.api + 'Person/GetAllPersonApplicationDeficiencies/' + id, { withCredentials: true });
  }

  savePersonApplicationDeficiency(applicationDeficiency: IPersonApplicationDeficiency): Observable<any> { 
    return this.http.post(this.api + 'Person/SavePersonApplicationDeficiency', applicationDeficiency, httpOptions);
  }

  deletePersonApplicationDeficiency(id: number): Observable<any> {    
    return this.http.get(this.api + 'Person/DeletePersonApplicationDeficiency/' + id, httpOptions);
  }

  getPersonAddressesByPersonId(id:number): Observable<IPersonAddress[]> {    
    return this.http.get<IPersonAddress[]>(this.api + 'Person/GetPersonAddressesByPersonId/' + id, { withCredentials: true });
  }

  savePersonAddress(personAddress: IPersonAddress): Observable<any> {
    return this.http.post(environment.privateApi + 'Person/SavePersonAddress', personAddress, httpOptions);
  }

  deletePersonAddress(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Person/DeletePersonAddress/' + id, httpOptions);
  }

  getPersonCertificates(id:number): Observable<IPersonCertificate[]> {    
    return this.http.get<IPersonCertificate[]>(this.api + 'Person/GetPersonCertificates/' + id, { withCredentials: true });
  }

  getNextPersonCertificateNumber(): Observable<number> {    
    return this.http.get<number>(this.api + 'Person/GetNextPersonCertificateNumber', { withCredentials: true });
  }

  savePersonCertificate(personCertificate: IPersonCertificate): Observable<any> {
    return this.http.post(environment.privateApi + 'Person/SavePersonCertificate', personCertificate, httpOptions);
  }

  getAllPersonCertificates(): Observable<IPersonCertificate[]> {    
    return this.http.get<IPersonCertificate[]>(this.api + 'Person/GetAllPersonCertificates' , { withCredentials: true });
  }

  deletePersonTesting(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Person/DeletePersonTesting/' + id, httpOptions);
  }

  getPersonTestings(id:number): Observable<IPersonTesting[]> {    
    return this.http.get<IPersonTesting[]>(this.api + 'Person/GetPersonTestings/' + id, { withCredentials: true });
  }

  savePersonTesting(personContactInformation: IPersonTesting): Observable<any> {
    return this.http.post(environment.privateApi + 'Person/SavePersonTesting', personContactInformation, httpOptions);
  }

  getPersonApplicationDocuments(id:number): Observable<IPersonApplicationDocument[]> {    
    return this.http.get<IPersonApplicationDocument[]>(this.api + 'Person/GetPersonApplicationDocuments/' + id, { withCredentials: true });
  }

  savePersonApplicationDocument(applicationDocument: IPersonApplicationDocument): Observable<any> { 
    return this.http.post(this.api + 'Person/SavePersonApplicationDocument', applicationDocument, httpOptions);
  }

  deletePersonApplicationDocument(id: number): Observable<any> {    
    return this.http.get(this.api + 'Person/DeletePersonApplicationDocument/' + id, httpOptions);
  }
}
