<div fxLayout="column" fxFlex>  
    <h1 mat-dialog-title>Action</h1>  
    <span><strong>Date of Action</strong></span> <br>
    <mat-form-field>
        <input matInput [matDatepicker]="actionDatePicker" placeholder="" (focus)="datePicker.open()" [value]="defaultDate" (dateChange)="onDateSelected($event)">
        <mat-datepicker-toggle matSuffix [for]="actionDatePicker"></mat-datepicker-toggle>
    </mat-form-field>    
    <mat-datepicker #actionDatePicker></mat-datepicker>  
    <div fxLayout="column">
        <span><strong>Action Type:</strong></span>&nbsp;&nbsp;
        <div class="grid-2columns">            
            <ng-container *ngFor="let row of actionTypeOptions">
                <mat-radio-button value="{{row.value}}" [checked]="row.checked" (change)="handleActionTypeSelection($event)">{{row.name}}</mat-radio-button>
            </ng-container>
        </div> <br>
        <div *ngIf="otherActionSelected">
            <span><strong>Other Action:</strong></span> <br>
            <mat-form-field appearance="outline" style="width: 85%;">                                                        
                <textarea matInput [(ngModel)]="otherActionDetails" rows="2" cols="20" style="white-space: pre-wrap;"></textarea>
            </mat-form-field>
        </div>   
    </div>    
    <div>
        <button mat-raised-button (click)="onCancel()">Cancel</button>        
        <button mat-raised-button color="primary" (click)="onSubmit()">{{this.data.selected !== null ? 'Save' : 'Add'}}</button>
    </div>
</div>

