<div fxLayout="column" [ngStyle]="{'--entity-color' : color}">
    <mat-card [style.background-color]="color">
        <mat-card-title>
            {{contactInformationData.id !== 0 ? 'Edit Contact Information ' + contactInformationData.id : 'Add New Contact Information'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">

        <form [formGroup]="contactInformationDataForm">
            <div fxLayout="column" fxFlex>
                <mat-form-field>
                    <mat-label>Contact Type</mat-label>
                    <mat-select formControlName="contactType">
                      <mat-option *ngFor="let type of getContactTypes()" [value]="type">{{ type }}</mat-option>
                    </mat-select>
                </mat-form-field>                                               

                <mat-form-field>
                    <mat-label>Phone</mat-label>
                    <input matInput placeholder="Phone" formControlName="phone">
                </mat-form-field>
            
                <mat-form-field>
                    <mat-label>Extension</mat-label>
                    <input matInput placeholder="Extension" formControlName="ext">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Cell</mat-label>
                    <input matInput placeholder="Cell" formControlName="cell">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Fax</mat-label>
                    <input matInput placeholder="Fax" formControlName="fax">
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>   
                
                <mat-form-field>
                    <mat-label>Website</mat-label>
                    <input matInput placeholder="Website" formControlName="website">
                </mat-form-field>   
            </div>
        </form>
      
    </div>
    <mat-card [style.background-color]="color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{contactInformationData.id !== 0 ? 'Save' : 'Add'}}
        </button>        
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>
