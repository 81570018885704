import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IPayment, IPaymentSearch } from '../models/payment';
import { FormControl, FormGroup } from '@angular/forms';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({providedIn: 'root'})
export class PaymentService {
  //search form
  searchForm = new FormGroup({
    id: new FormControl(),
    entityId: new FormControl(''),
    entityType: new FormControl(''),
    searchTerm: new FormControl(''),  
    paid: new FormControl(true), 
    status: new FormControl(''),
    paymentType: new FormControl('')
  });



  

  //fee types
  public applicationFeeType = "ApplicationFee";
  public licenseFeeType = "LicenseFee";
  public reducedLicenseFeeType = "ReducedLicenseFee";


  //entity types
  public FirmApplicationEntityType = "FirmApplication";  
  public FirmEntityType = "Firm";  
  public ATPAccreditationEntityType = "AtpAccreditation";  
  public AtpEntityType = "Atp";
  public IndividualApplicationEntityType = "PersonApplication";
  public IndividualEntityType = "Person";

  //Firm Fee types
  public FirmLbpaFeeType = "firm-lbpa";
  public FirmRrpFeeType = "firm-rrp";
  public FirmCitationFeeType = "firm-citation";

  //Individual Fee types
  public SupervisorLbpaFeeType = "Supervisor";
  public RiskAssessorLbpaFeeType = "Risk Assessor";
  public ProjectDesignerLbpaFeeType = "Project Designer";
  public InspectorLbpaFeeType = "Inspector";
  public WorkerLbpaFeeType = "Worker";
  public DustSamplingTechFeeType = "Dust Sampling Tech";
  public RRPRenovatorFeeType = "RRP Renovator";

  //ATP Fee types
  public AtpInspectorInitialAccredLbpaFeeType = "atp-insprector-initial-accred-lbpa";
  public AtpInspectorInitialReAccredLbpaFeeType = "atp-insprector-initial-reaccred-lbpa";
  public AtpInspectorRefresherAccredLbpaFeeType = "atp-insprector-refresher-accred-lbpa";
  public AtpInspectorRefresherReAccredLbpaFeeType = "atp-insprector-refresher-reaccred-lbpa";

  public AtpRiskAssessorInitialAccredLbpaFeeType = "atp-risk-assessor-initial-accred-lbpa";
  public AtpRiskAssessorInitialReAccredLbpaFeeType = "atp-risk-assessor-initial-reaccred-lbpa";
  public AtpRiskAssessorRefresherAccredLbpaFeeType = "atp-risk-assessor-refresher-accred-lbpa";
  public AtpRiskAssessorRefresherReAccredLbpaFeeType = "atp-risk-assessor-refresher-reaccred-lbpa";

  public AtpProjectDesignerInitialAccredLbpaFeeType = "atp-project-designer-initial-accred-lbpa";
  public AtpProjectDesignerInitialReAccredLbpaFeeType = "atp-project-designer-initial-reaccred-lbpa";
  public AtpProjectDesignerRefresherAccredLbpaFeeType = "atp-project-designer-refresher-accred-lbpa";
  public AtpProjectDesignerRefresherReAccredLbpaFeeType = "atp-project-designer-refresher-reaccred-lbpa";

  public AtpWorkerInitialAccredLbpaFeeType = "atp-worker-initial-accred-lbpa";
  public AtpWorkerInitialReAccredLbpaFeeType = "atp-worker-initial-reaccred-lbpa";
  public AtpWorkerRefresherAccredLbpaFeeType = "atp-worker-refresher-accred-lbpa";
  public AtpWorkerRefresherReAccredLbpaFeeType = "atp-worker-refresher-reaccred-lbpa";

  public AtpSupervisorInitialAccredLbpaFeeType = "atp-supervisor-initial-accred-lbpa";
  public AtpSupervisorInitialReAccredLbpaFeeType = "atp-supervisor-initial-reaccred-lbpa";
  public AtpSupervisorRefresherAccredLbpaFeeType = "atp-supervisor-refresher-accred-lbpa";
  public AtpSupervisorRefresherReAccredLbpaFeeType = "atp-supervisor-refresher-reaccred-lbpa";

  public AtpInspectorRiskAssessorCombineRefresherReAccredLbpaFeeType = "atp-inspector-risk-assessor-combine-refresher-reaccred-lbpa";
  public AtpWorkerSupervisorCombineRefresherReAccredLbpaFeeType = "atp-worker-supervisor-combine-refresher-reaccred-lbpa";

  
  public AtpRenovatorInitialAccredRrpFeeType = "atp-renovator-initial-accred-rrp";
  public AtpRenovatorInitialReAccredRrpFeeType = "atp-renovator-initial-reaccred-rrp";  
  public AtpRenovatorRefresherAccredRrpFeeType = "atp-renovator-refresher-accred-rrp";
  public AtpRenovatorRefresherReAccredRrpFeeType = "atp-renovator-refresher-reaccred-rrp";

  public AtpDustSamplingInitialAccredRrpFeeType = "atp-dust-sampling-initial-accred-rrp";
  public AtpDustSamplingInitialReAccredRrpFeeType = "atp-dust-sampling-initial-reaccred-rrp";  
  public AtpDustSamplingRefresherAccredRrpFeeType = "atp-dust-sampling-refresher-accred-rrp";
  public AtpDustSamplingRefresherReAccredRrpFeeType = "atp-dust-sampling-refresher-reaccred-rrp";

  public AtpStudentRrpFeeType = "atp-student-rrp";
  public AtpStudentMultipleRrpFeeType = "atp-student-multiple-rrp";

  public AtpRrpFeeType = "atp-rrp";

  //Citation Fee types
  public CitationFeeType = "citation";




  //Payment Options
  public paymentOptions = [
    //Firm Options
    {
      Text: "Firm (LBPA)",
      EntityType: this.FirmApplicationEntityType,
      FeeType: this.FirmLbpaFeeType,
      Amount: "255.00",
    },
    {
      Text: "Firm (RRP)",
      EntityType: this.FirmApplicationEntityType,
      FeeType: this.FirmRrpFeeType,
      Amount: "250.00"
    },
    {
      Text: "Firm Citation",
      EntityType: this.FirmEntityType,
      FeeType: this.FirmCitationFeeType,
      Amount: "0.00"
    },
    
    //Individual Options
    {
      Text: "Supervisor (LBPA)",
      EntityType: this.IndividualApplicationEntityType,
      FeeType: this.SupervisorLbpaFeeType,
      Amount: "255.00"
    },
    {
      Text: "Risk Assessor (LBPA)",
      EntityType: this.IndividualApplicationEntityType,
      FeeType: this.RiskAssessorLbpaFeeType,
      Amount: "255.00"
    },
    {
      Text: "Project Designer (LBPA)",
      EntityType: this.IndividualApplicationEntityType,
      FeeType: this.ProjectDesignerLbpaFeeType,
      Amount: "255.00"
    },
    {
      Text: "Inspector (LBPA)",
      EntityType: this.IndividualApplicationEntityType,
      FeeType: this.InspectorLbpaFeeType,
      Amount: "255.00"
    },
    {
      Text: "Worker (LBPA)",
      EntityType: this.IndividualApplicationEntityType,
      FeeType: this.WorkerLbpaFeeType,
      Amount: "150.00"
    },
    
    
    {
      Text: "Individual Citation",
      EntityType: this.IndividualEntityType,
      FeeType: this.CitationFeeType,
      Amount: "0.00"
    },
    //ATP Options
    {
      Text: "LBPA Inspector (Initial-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpInspectorInitialAccredLbpaFeeType,
      Amount: "750.00"
    },
    {
      Text: "LBPA Risk Assessor (Initial-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRiskAssessorInitialAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Worker (Initial-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpWorkerInitialAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Supervisor (Initial-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpSupervisorInitialAccredLbpaFeeType,
      Amount: "750.00"
    },
    {
      Text: "LBPA Inspector (Initial-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpInspectorInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },
    {
      Text: "LBPA Risk Assessor (Initial-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRiskAssessorInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },
    {
      Text: "LBPA Worker (Initial-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpWorkerInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },
    {
      Text: "LBPA Supervisor (Initial-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpSupervisorInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },

    {
      Text: "LBPA Inspector (Refresher-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpInspectorRefresherAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Risk Assessor (Refresher-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRiskAssessorRefresherAccredLbpaFeeType,
      Amount: "350.00"
    },
    {
      Text: "LBPA Worker (Refresher-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpWorkerRefresherAccredLbpaFeeType,
      Amount: "350.00"
    },
    {
      Text: "LBPA Supervisor (Refresher-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpSupervisorRefresherAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Inspector (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpInspectorRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Risk Assessor (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRiskAssessorRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Worker (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpWorkerRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Supervisor (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpSupervisorRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Inspector/Risk Assessor (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpInspectorRiskAssessorCombineRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Worker/Supervisor (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpWorkerSupervisorCombineRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "RRP Renovator (Initial-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRenovatorInitialAccredRrpFeeType,
      Amount: "560.00"
    },
    {
      Text: "RRP Renovator (Initial-Reaccreditation) ",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRenovatorInitialReAccredRrpFeeType,
      Amount: "340.00"
    },
    {
      Text: "RRP Renovator (Refresher-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRenovatorRefresherAccredRrpFeeType,
      Amount: "400.00"
    },
    {
      Text: "RRP Renovator (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpRenovatorRefresherReAccredRrpFeeType,
      Amount: "310.00"
    },    
    {
      Text: "RRP Dust Sampling Technician (Initial-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpDustSamplingInitialAccredRrpFeeType,
      Amount: "560.00"
    },
    {
      Text: "RRP Dust Sampling Technician (Initial-Reaccreditation) ",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpDustSamplingInitialReAccredRrpFeeType,
      Amount: "340.00"
    },
    {
      Text: "RRP Dust Sampling Technician (Refresher-Accreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpDustSamplingRefresherAccredRrpFeeType,
      Amount: "400.00"
    },
    {
      Text: "RRP Dust Sampling Technician (Refresher-Reaccreditation)",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpDustSamplingRefresherReAccredRrpFeeType,
      Amount: "310.00"
    },
    {
      Text: "RRP Single Student Fee",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpStudentRrpFeeType,
      Amount: "50.00"
    },
    {
      Text: "RRP Multiple Student Fee",
      EntityType: this.AtpEntityType,
      FeeType: this.AtpStudentMultipleRrpFeeType,
      Amount: "0.00"
    },
    //ATP AccreditationOptions
    {
      Text: "LBPA Inspector (Initial-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpInspectorInitialAccredLbpaFeeType,
      Amount: "750.00"
    },
    {
      Text: "LBPA Risk Assessor (Initial-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRiskAssessorInitialAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Worker (Initial-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpWorkerInitialAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Supervisor (Initial-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpSupervisorInitialAccredLbpaFeeType,
      Amount: "750.00"
    },
    {
      Text: "LBPA Inspector (Initial-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpInspectorInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },
    {
      Text: "LBPA Risk Assessor (Initial-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRiskAssessorInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },
    {
      Text: "LBPA Worker (Initial-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpWorkerInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },
    {
      Text: "LBPA Supervisor (Initial-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpSupervisorInitialReAccredLbpaFeeType,
      Amount: "300.00"
    },

    {
      Text: "LBPA Inspector (Refresher-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpInspectorRefresherAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Risk Assessor (Refresher-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRiskAssessorRefresherAccredLbpaFeeType,
      Amount: "350.00"
    },
    {
      Text: "LBPA Worker (Refresher-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpWorkerRefresherAccredLbpaFeeType,
      Amount: "350.00"
    },
    {
      Text: "LBPA Supervisor (Refresher-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpSupervisorRefresherAccredLbpaFeeType,
      Amount: "500.00"
    },
    {
      Text: "LBPA Inspector (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpInspectorRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Risk Assessor (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRiskAssessorRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Worker (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpWorkerRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Supervisor (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpSupervisorRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Inspector/Risk Assessor (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpInspectorRiskAssessorCombineRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "LBPA Worker/Supervisor (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpWorkerSupervisorCombineRefresherReAccredLbpaFeeType,
      Amount: "100.00"
    },
    {
      Text: "RRP Renovator (Initial-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRenovatorInitialAccredRrpFeeType,
      Amount: "560.00"
    },
    {
      Text: "RRP Renovator (Initial-Reaccreditation) ",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRenovatorInitialReAccredRrpFeeType,
      Amount: "340.00"
    },
    {
      Text: "RRP Renovator (Refresher-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRenovatorRefresherAccredRrpFeeType,
      Amount: "400.00"
    },
    {
      Text: "RRP Renovator (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpRenovatorRefresherReAccredRrpFeeType,
      Amount: "310.00"
    },    
    {
      Text: "RRP Dust Sampling Technician (Initial-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpDustSamplingInitialAccredRrpFeeType,
      Amount: "560.00"
    },
    {
      Text: "RRP Dust Sampling Technician (Initial-Reaccreditation) ",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpDustSamplingInitialReAccredRrpFeeType,
      Amount: "340.00"
    },
    {
      Text: "RRP Dust Sampling Technician (Refresher-Accreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpDustSamplingRefresherAccredRrpFeeType,
      Amount: "400.00"
    },
    {
      Text: "RRP Dust Sampling Technician (Refresher-Reaccreditation)",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpDustSamplingRefresherReAccredRrpFeeType,
      Amount: "310.00"
    },
    {
      Text: "RRP Single Student Fee",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpStudentRrpFeeType,
      Amount: "50.00"
    },
    {
      Text: "RRP Multiple Student Fee",
      EntityType: this.ATPAccreditationEntityType,
      FeeType: this.AtpStudentMultipleRrpFeeType,
      Amount: "0.00"
    },
  ];

  public paymentPendingStatus = 'Pending';
  public paymentClearedStatus = 'Cleared';
  public paymentAwaitingClearanceStatus = 'Awaiting Clearance';
  public paymentStatuses = [this.paymentPendingStatus,  this.paymentAwaitingClearanceStatus, this.paymentClearedStatus];
  public paymentMethods = [
    'Online',
    'Money Order',
    'Personal/Business Check',
    'Cashier\'s Check',
    //'Cash'
  ];
  private api: string = environment.privateApi + 'Payments/';

  paymentSearch: IPaymentSearch = {  
    entityId: '',
    entityType: '',
    searchTerm: '',
    paid: false,
    status: this.paymentPendingStatus,
    paymentType: '',
    id: 0   
  };

  constructor(private http: HttpClient) { }

  public getPaymentName(entityType: string, feeType: string): string {
    let option = this.paymentOptions.find(po => po.EntityType == entityType && po.FeeType == feeType);
    if(option != null && option != undefined) {
      return option.Text;
    }
    return entityType;
  }

  public getPaymentAmount(entityType: string, feeType: string): string {
    let option = this.paymentOptions.find(po => po.EntityType == entityType && po.FeeType == feeType);
    if(option != null && option != undefined) {
        return option.Amount;
    };
    return "";
  }

  public paymentOptionsByEntityType(entityType : string){
    return this.paymentOptions.filter(po => po.EntityType == entityType);
  }

  getPaymentsPending(): Observable<IPayment[]>{
    return this.http.get<IPayment[]>(this.api + 'GetPaymentsPending/' );
  }

  getPaymentsAwaitingClearance(): Observable<IPayment[]>{
    return this.http.get<IPayment[]>(this.api + 'GetPaymentsAwaitingClearance/' );
  }

  getPaymentsByEntity(entityId: string, entityType: string): Observable<IPayment[]>{
    return this.http.get<IPayment[]>(this.api + 'GetPayments/' + entityType + '/' + entityId);
  }

  getPaymentsById(entityId: number): Observable<IPayment[]>{
    return this.http.get<IPayment[]>(this.api + 'GetPayments/' + entityId);
  }

  savePayment(payment: IPayment): Observable<IPayment>{
    return this.http.post<any>(this.api + "SavePayment", payment);
  }

  deletePayment(id: number, type: string): Observable<any> {
    return this.http.get<any>(this.api + 'DeletePayment/' + type + '/'+ id);
  }

  search(search: IPaymentSearch): Observable<any> {
    return this.http.post(this.api+ 'SearchPayments', search, httpOptions);
  }

}
