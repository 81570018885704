<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>


<div *ngIf="!sharedService.loading && !loading" [ngStyle]="{'--entity-color' : uiData.atp.color}">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            {{complaint.id !== 0 ? complaint.complainteeFirstName + (complaint.complainteeLastName ? ' ' + complaint.complainteeLastName : '') + ' - Complaint Number : '+ complaint.complaintNumber : 'Add New Complaint'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">       
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxFlex="100">
                    <mat-card>
                        <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
                            <form [formGroup]="complaintForm">
                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    Complaint
                                </ng-template>
                                <ng-container>
                                    <br>
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px"> 
                                        <mat-form-field fxFlex="50%">       
                                            <span><strong>Complaint Status: </strong></span>                                        
                                            <mat-select formControlName="status">
                                                <mat-option value="Open">Open</mat-option>
                                                <mat-option value="Closed">Closed</mat-option>
                                            </mat-select>                                
                                        </mat-form-field>
                                        <mat-form-field fxFlex="50%">
                                            <span><strong>Complaint Closed Date:</strong></span>
                                            <input matInput [matDatepicker]="closedDatePicker" placeholder="" formControlName="complaintClosedDate" [disabled]="complaintForm.get('status').value === 'Open'">
                                            <mat-datepicker-toggle matSuffix [for]="closedDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #closedDatePicker></mat-datepicker>                               
                                        </mat-form-field>
                                    </div>                         
                                    <button mat-raised-button color="primary" [disabled]="complaint.id === 0 || complaintForm.get('status').value === 'Closed'" (click)="resolveComplaint()">Resolve Complaint</button>
                                      <br> <br>
                                            <mat-panel-title>
                                                <span><h4>Complaint Source</h4></span>
                                            </mat-panel-title>                                                                       
                                          <mat-card fxFlex>                                         
                                            <mat-card-content>
                                            <div fxLayout="column" fxLayoutGap="8px">
                                                <div fxLayout="column" fxFlex="50" fxLayoutGap="8px">  
                                                <mat-checkbox  formControlName="isAnyonymous">   
                                                    <span class="mat-checkbox-label"><strong>
                                                        Anonymous Complaint or Complainant Requested Anonymity
                                                    </strong></span>                                               
                                                </mat-checkbox> 
                                                </div>
                                                <span><strong>Contact Details</strong></span>                
                                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                                    <mat-form-field fxFlex="100%">
                                                        <input matInput placeholder="First" formControlName="complainteeFirstName" >                                    
                                                    </mat-form-field>
                                                    <mat-form-field fxFlex="100%">
                                                        <input matInput placeholder="Last" formControlName="complainteeLastName" >                                    
                                                    </mat-form-field>       
                                                    <mat-form-field fxFlex="100%">
                                                        <input matInput placeholder="Phone" formControlName="complainteePhone" >                                    
                                                    </mat-form-field>
                                                    <mat-form-field fxFlex="100%">
                                                        <input matInput placeholder="Email" formControlName="complainteeEmail" >                                    
                                                    </mat-form-field>
                                                </div>  
                                                <div fxLayout="column" class="m-t-8">
                                                    <span><strong>Complaint Source Address(Optional):</strong></span>           
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" >
                                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                            <input matInput placeholder="Street Apt/Unit/Suite" formControlName="complainteeAddress">
                                                        </mat-form-field>                                                            
                                                        <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                                                            <input matInput placeholder="City"  formControlName="complainteeCity">
                                                        </mat-form-field>                                        
                                                        <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                                                            <mat-label>State</mat-label>
                                                            <mat-select formControlName="complainteeState">
                                                            <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                            </mat-select>                                
                                                        </mat-form-field>                                        
                                                        <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                                                            <input matInput placeholder="Zip" formControlName="complainteeZip">
                                                        </mat-form-field>  
                                                    </div>                
                                                </div>
                                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                                    <mat-form-field fxFlex="50%">
                                                        <span><strong>Employer (If Complainant is an employee):</strong></span> 
                                                        <input matInput placeholder="" formControlName="complainteeEmployer" >                                    
                                                    </mat-form-field>
                                                    <mat-form-field fxFlex="50%">
                                                        <span><strong>Date Complaint Recieved by OHA:</strong></span> 
                                                        <input matInput [matDatepicker]="datePicker" placeholder="" formControlName="complaintDate" (focus)="datePicker.open()">
                                                            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                                                            <mat-datepicker #datePicker></mat-datepicker>                                   
                                                    </mat-form-field>
                                                </div>                
                                            </div>
                                            </mat-card-content>
                                        </mat-card>   
                                        <br>
                                </ng-container>
                            </mat-tab>
                            </form>
                            <div *ngIf="complaint.id  !== 0"> 
                                <form [formGroup]="complaintForm">
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                    <mat-icon>{{uiData.complaint.icon}}</mat-icon> &nbsp; Complaint Target
                                    </ng-template>
                                    <ng-container>
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">                                  
                                            <mat-card fxFlex>
                                              <mat-card-content> 
                                                  <span><strong>Contact Details</strong></span>                
                                                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                                      <mat-form-field fxFlex="100%">
                                                          <input matInput placeholder="Name" formControlName="complaintAssociationName" >                                    
                                                      </mat-form-field>
                                                      <mat-form-field fxFlex="100%">
                                                          <input matInput placeholder="Street Apt/Unit/Suite" formControlName="complainantAddress" >                                    
                                                      </mat-form-field> 
                                                  </div> 
                                                  <div fxLayout="column" class="m-t-8">        
                                                      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" >                                                            
                                                          <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                              <input matInput placeholder="City"  formControlName="complainantCity">
                                                          </mat-form-field>                                        
                                                          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                                              <mat-label>State</mat-label>
                                                              <mat-select formControlName="complainantState">
                                                              <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                              </mat-select>                                
                                                          </mat-form-field>                                        
                                                          <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                                                              <input matInput placeholder="Zip" formControlName="complainantZip">
                                                          </mat-form-field> 
                                                          <mat-form-field fxFlex="30" fxFlex.lt-md="100" *ngIf="complaintForm.get('complainantState').value === 'OR'">                                
                                                              <mat-label>County</mat-label>
                                                              <mat-select formControlName="complainantCounty" >
                                                              <mat-option>-- None --</mat-option>
                                                              <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                                              </mat-option>
                                                              </mat-select>
                                                          </mat-form-field> 
                                                      </div>                
                                                  </div>  
                                                  <span><strong>Who is doing the work?</strong></span>
                                                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                                      <mat-form-field fxFlex="100%">
                                                          <span><strong>Contractor 1:</strong></span> 
                                                          <input matInput placeholder="" formControlName="complaintContractor1" >                                    
                                                      </mat-form-field>
                                                      <mat-form-field fxFlex="100%">
                                                          <span><strong>Contractor 2:</strong></span> 
                                                          <input matInput placeholder="" formControlName="complaintContractor2" >                                    
                                                      </mat-form-field> 
                                                      <mat-form-field fxFlex="100%">
                                                          <span><strong>Contractor 3:</strong></span> 
                                                          <input matInput placeholder="" formControlName="complaintContractor3" >                                    
                                                      </mat-form-field> 
                                                  </div>
                                                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                                      <mat-form-field fxFlex="100%">
                                                          <span><strong>CCB# 1:</strong></span> 
                                                          <input matInput placeholder="" formControlName="contractorCCB1" >                                    
                                                      </mat-form-field>
                                                      <mat-form-field fxFlex="100%">
                                                          <span><strong>CCB# 2:</strong></span> 
                                                          <input matInput placeholder="" formControlName="contractorCCB2" >                                    
                                                      </mat-form-field> 
                                                      <mat-form-field fxFlex="100%">
                                                          <span><strong>CCB# 3:</strong></span> 
                                                          <input matInput placeholder="" formControlName="contractorCCB3" >                                    
                                                      </mat-form-field> 
                                                  </div>                                                
                                                      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">                                                  
                                                          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="70">
                                                              <span class="f-2-20" style="padding-top: 20px;"><strong>If not a contractor who is doing the work:</strong></span>
                                                              <mat-form-field fxFlex="40">
                                                              <input matInput placeholder=""  formControlName="otherWorker">
                                                              </mat-form-field>
                                                          </div>                                               
                                                       </div> 
                                                  <div *ngIf="complaint.id  !== 0">   
                                                      <div fxLayout="column">
                                                          <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                              <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                                  <div>  
                                                                      <span><strong>Associations</strong></span>                                                           
                                                                  </div>                                     
                                                                  <div>                        
                                                                    <button mat-raised-button color="primary" (click)="addAssociations(null)">
                                                                        <mat-icon>add</mat-icon> Add Associations
                                                                    </button>
                                                                  </div>
                                                              </div>                          
                                                              <br />                                        
                                                              <mat-table [dataSource]="complaintAssociationTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                                  <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="complaintAssociationTable.layout.columns.concat('actions')"></mat-header-row>                  
                                                                  <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: complaintAssociationTable.layout.columns.concat('actions')" 
                                                                   title="Edit {{row.Name}}"></mat-row>                                     
                                                                                      
                                                                  <ng-container *ngFor="let cellData of complaintAssociationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                                      <mat-header-cell [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                      <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                          <ng-container [ngSwitch]="cellData.type">
                                                                              <ng-container *ngSwitchCase="'date'">
                                                                                  {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                              </ng-container>
                                                                              <ng-container *ngSwitchCase="'bool'">
                                                                                  <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                              </ng-container>
                                                                              <ng-container *ngSwitchDefault>
                                                                                  {{ displayLimit(row[cellData.columnName], '80') }}
                                                                              </ng-container>
                                                                          </ng-container>
                                                                      </mat-cell>
                                                                  </ng-container> 
                                                                      <!-- Button column -->
                                                                  <ng-container matColumnDef="actions">
                                                                      <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                                      <mat-cell *matCellDef="let row" fxFlex="7" fxFlex.lt-sm="100" style="height: 45px">
                                                                          <button title="Delete {{row['Name']}}" mat-icon-button class="m-8" color="warn" aria-label="Delete" (click)="deleteAssociation(row); $event.stopPropagation()">
                                                                              <mat-icon>delete</mat-icon>
                                                                          </button>
                                                                      </mat-cell>
                                                                  </ng-container>                                                      
                                                              </mat-table>
                                                                              
                                                              <br />
                                                              <mat-paginator [pageSize]="complaintAssocationTablePageSize" [pageSizeOptions]="[10, 50, complaintAssociationTable.layout.data.length]" #TablePaginator="matPaginator"
                                                                  showFirstLastButtons></mat-paginator>
                                                          </div>
                                                      </div>   
                                                       
                                                    <br>
                                                    <div fxLayout="column">
                                                      <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                          <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                              <div>  
                                                                  <span><strong>Referrals</strong></span>                                                           
                                                              </div>                                     
                                                              <div>                        
                                                                <button mat-raised-button color="primary" (click)="editComplaintReferral(null)">
                                                                    <mat-icon>add</mat-icon> Add Referrals
                                                                </button>
                                                              </div>
                                                          </div>                          
                                                          <br />                                        
                                                          <mat-table [dataSource]="complaintReferralTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                              <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="complaintReferralTable.layout.columns.concat('actions')"></mat-header-row>                  
                                                              <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: complaintReferralTable.layout.columns.concat('actions')" 
                                                              (click)="editComplaintReferral(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                     
                                                                                  
                                                              <ng-container *ngFor="let cellData of complaintReferralTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                                  <mat-header-cell [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                  <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                      <ng-container [ngSwitch]="cellData.type">
                                                                          <ng-container *ngSwitchCase="'date'">
                                                                              {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                          </ng-container>
                                                                          <ng-container *ngSwitchCase="'bool'">
                                                                              <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                          </ng-container>
                                                                          <ng-container *ngSwitchDefault>
                                                                              {{ displayLimit(row[cellData.columnName], '80') }}
                                                                          </ng-container>
                                                                      </ng-container>
                                                                  </mat-cell>
                                                              </ng-container> 
                                                                  <!-- Button column -->
                                                              <ng-container matColumnDef="actions">
                                                                  <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                                  <mat-cell *matCellDef="let row" fxFlex="7" fxFlex.lt-sm="100" style="height: 45px">
                                                                      <button title="Delete {{row['Name']}}" mat-icon-button class="m-8" color="warn" aria-label="Delete" (click)="deleteReferral(row); $event.stopPropagation()">
                                                                          <mat-icon>delete</mat-icon>
                                                                      </button>
                                                                  </mat-cell>
                                                              </ng-container>                                                      
                                                          </mat-table>
                                                                          
                                                          <br />
                                                          <mat-paginator [pageSize]="complaintReferralTablePageSize" [pageSizeOptions]="[10, 50, complaintReferralTable.layout.data.length]" #TablePaginator="matPaginator"
                                                              showFirstLastButtons></mat-paginator>
                                                      </div>
                                                  </div>  
                                                  </div>  
                                              </mat-card-content>
                                          </mat-card>
                                      </div>
                                    </ng-container>
                                    </mat-tab>  
                                </form>
                            <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.complaint.icon}}</mat-icon> &nbsp; Complaint Detail
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">                                       
                                            <mat-card-content>                                             
                                            <div fxLayout="row" fxLayoutGap="16px">
                                                <mat-card fxFlex="50%">
                                                    <!-- Content for the first card -->
                                                    <span><strong>Desciption of Complaint/problem Issue:</strong></span> <br>&nbsp;
                                                    <mat-form-field appearance="outline" style="width: 100%;"> 
                                                    <textarea matInput [(ngModel)]="complaintProblem" rows="15" cols="40" style="white-space: pre-wrap;"></textarea>
                                                </mat-form-field>
                                                </mat-card>
                                                <mat-card fxFlex="50%">
                                                    <!-- Content for the second card -->                                                   
                                                    <div fxLayout="column">
                                                        <span><strong>Enforcement Category:</strong></span>
                                                        <div class="grid-2columns">
                                                            <ng-container *ngFor="let row of enforcementCategoryOptions">
                                                                <mat-checkbox value="{{row.value}}" [checked]="row.checked" (change)="handleEnforcementCategorySelection($event)">{{row.name}}</mat-checkbox>
                                                            </ng-container>
                                                        </div>                                                        
                                                        <mat-form-field>
                                                            <textarea matInput [(ngModel)]="otherEnforcementCategory" placeholder="Other"></textarea>
                                                        </mat-form-field>
                                                    </div>
                                                    <div fxLayout="column">
                                                        <span><strong>Possible Jurisdictions:</strong></span> 
                                                        <div class="grid-3columns">
                                                            <ng-container *ngFor="let row of jurisdictionOptions">
                                                                <mat-checkbox value="{{row.value}}" [checked]="row.checked" (change)="handleJurisdictionsSelection($event)">{{row.name}}</mat-checkbox>
                                                            </ng-container>
                                                        </div>                                                         
                                                        <mat-form-field>
                                                            <textarea matInput [(ngModel)]="otherJurisdiction" placeholder="Other Jurisdiction"></textarea>
                                                        </mat-form-field>
                                                    </div>
                                                    <span><strong>If OHA, Investigator assigned to Complaint:</strong></span> 
                                                    <br>
                                                    <span>Investigator:</span>&nbsp;
                                                    <mat-form-field>
                                                        <input matInput [(ngModel)]="complaintInvestigator" name="" placeholder="">
                                                    </mat-form-field>                                                   
                                                    <br>
                                                    <span>Date Assigned:</span>
                                                    <mat-form-field>
                                                        <input matInput [matDatepicker]="investigatorAssignedDatePicker" placeholder="" (dateChange)="onInvestigatorAssignedDateSelected($event)">
                                                        <mat-datepicker-toggle matSuffix [for]="investigatorAssignedDatePicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #investigatorAssignedDatePicker></mat-datepicker>
                                                    </mat-form-field>
                                                </mat-card>
                                            </div>                                       
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>                                                    
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.complaint.icon}}</mat-icon> &nbsp; Actions & Status
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">                                 
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                            <div> <strong>&nbsp;&nbsp;&nbsp;Inspections/FollowUps/Narratives</strong> </div>                                     
                                                            <div>                        
                                                              <button mat-raised-button color="primary" (click)="editComplaintInspection(null)">
                                                                  <mat-icon>add</mat-icon> Add Inspections/FollowUps/Narratives
                                                              </button>
                                                            </div>
                                                        </div>                          
                                                        <br />                                        
                                                        <mat-table [dataSource]="complaintInspectionTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="complaintInspectionTable.layout.columns.concat('actions')"></mat-header-row>                  
                                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: complaintInspectionTable.layout.columns.concat('actions')"  (click)="editComplaintInspection(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                                
                                                            <ng-container *ngFor="let cellData of complaintInspectionTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                                <mat-header-cell [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                    <ng-container [ngSwitch]="cellData.type">
                                                                        <ng-container *ngSwitchCase="'date'">
                                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'bool'">
                                                                            <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            {{ displayLimit(row[cellData.columnName], '80') }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </mat-cell>
                                                            </ng-container> 
                                                            <ng-container matColumnDef="actions">
                                                                <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" fxFlex="7" fxFlex.lt-sm="100" style="height: 45px">
                                                                    <button title="Delete {{row['Name']}}" mat-icon-button class="m-8" color="warn" aria-label="Delete" (click)="deleteInspection(row); $event.stopPropagation()">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </mat-cell>
                                                              </ng-container>  
                                                        </mat-table>                                                                        
                                                        <br />
                                                        <mat-paginator [pageSize]="complaintInspectionTablePageSize" [pageSizeOptions]="[10, 50, complaintInspectionTable.layout.data.length]" #TablePaginator="matPaginator"
                                                            showFirstLastButtons></mat-paginator>
                                                    </div><br />   <br />   
                                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                            <div>&nbsp;&nbsp;&nbsp;<strong>Actions</strong>                                       
                                                            </div>                                     
                                                            <div>                        
                                                              <button mat-raised-button color="primary" (click)="editAction(null)">
                                                                  <mat-icon>add</mat-icon> Add Actions
                                                              </button>                                                             
                                                            </div>
                                                        </div>                          
                                                        <br />                                        
                                                        <mat-table [dataSource]="complaintActionTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="complaintActionTable.layout.columns.concat('actions')"></mat-header-row>                  
                                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: complaintActionTable.layout.columns.concat('actions')" (click)="editAction(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                                
                                                            <ng-container *ngFor="let cellData of complaintActionTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                                <mat-header-cell [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                    <ng-container [ngSwitch]="cellData.type">
                                                                        <ng-container *ngSwitchCase="'date'">
                                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="'bool'">
                                                                            <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchDefault>
                                                                            {{ displayLimit(row[cellData.columnName], '80') }}
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </mat-cell>
                                                            </ng-container> 
                                                            <ng-container matColumnDef="actions">
                                                                <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" fxFlex="7" fxFlex.lt-sm="100" style="height: 45px">
                                                                    <button title="Delete {{row['Name']}}" mat-icon-button class="m-8" color="warn" aria-label="Delete" (click)="deleteAction(row); $event.stopPropagation()">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </mat-cell>
                                                            </ng-container>   
                                                        </mat-table>                                                                        
                                                        <br />
                                                        <mat-paginator [pageSize]="complaintActionTablePageSize" [pageSizeOptions]="[10, 50, complaintActionTable.layout.data.length]" #TablePaginator="matPaginator"
                                                            showFirstLastButtons></mat-paginator>
                                                    </div>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>   
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.complaint.icon}}</mat-icon> &nbsp; Owners/Tenants
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                        <div>                                                             
                                                        </div>                                     
                                                        <div>                        
                                                          <button mat-raised-button color="primary" (click)="editOwnerTenant(null)">
                                                              <mat-icon>add</mat-icon> Add Owner/Tenant
                                                          </button>
                                                        </div>
                                                    </div>                          
                                                    <br />                                                       

                                                    <mat-table [dataSource]="complaintOwnerTenantTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="complaintOwnerTenantTable.layout.columns.concat('actions')"></mat-header-row>                  
                                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: complaintOwnerTenantTable.layout.columns.concat('actions')" 
                                                        (click)="editOwnerTenant(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                            
                                                        <ng-container *ngFor="let cellData of complaintOwnerTenantTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                            <mat-header-cell [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                                <ng-container [ngSwitch]="cellData.type">
                                                                    <ng-container *ngSwitchCase="'date'">
                                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="'bool'">
                                                                        <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchDefault>
                                                                        {{ displayLimit(row[cellData.columnName], '80') }}
                                                                    </ng-container>
                                                                </ng-container>
                                                            </mat-cell>
                                                        </ng-container> 
                                                            <!-- Button column -->
                                                            <ng-container matColumnDef="actions">
                                                                <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                                <mat-cell *matCellDef="let row" fxFlex="7" fxFlex.lt-sm="100" style="height: 45px">
                                                                    <button title="Delete {{row['Name']}}" mat-icon-button class="m-8" color="warn" aria-label="Delete" (click)="deleteOwnerTenant(row); $event.stopPropagation()">
                                                                        <mat-icon>delete</mat-icon>
                                                                    </button>
                                                                </mat-cell>
                                                            </ng-container>                                                      
                                                    </mat-table>       
                                                    <br />
                                                    <mat-paginator [pageSize]="complaintOwnerTenantTablePageSize" [pageSizeOptions]="[10, 50, complaintOwnerTenantTable.layout.data.length]" #TablePaginator="matPaginator"
                                                        showFirstLastButtons></mat-paginator>
                                                </div>
                                            </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>  
                             
                                <mat-tab>
                                    <ng-template mat-tab-label>                
                                        <mat-icon>{{uiData.complaint.icon}}</mat-icon> &nbsp; Reports
                                    </ng-template>
                                    <ng-container>
                                        <mat-card class="custom-mat-card">
                                            <mat-card-title>
                                                <div fxLayout="row">
                                                    <span>Reports</span>
                                                </div>
                                            </mat-card-title>
                                            <mat-card-content>
                                                <div fxLayout="column">
                                                    <button mat-button type="button" color="primary" (click)="exportPlan()">Export to PDF</button>
                                                </div>
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-container>
                                </mat-tab>   
                            </div>                                         
                        </mat-tab-group>
                    </mat-card>
                </div>              
            </div>
       
    </div>
    <mat-card [style.background-color]="uiData.atp.color">       
        <button mat-raised-button color="primary" aria-label="Save" style="margin-right: 10px;" (click)="saveComplaint()">
            {{complaint.id === 0 ? 'Add Complaint' : 'Save'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit" style="margin-right: 10px;">Exit</button>
    </mat-card>
</div>