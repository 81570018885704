import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public message: string,
              private dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  public cancel(): void{
    this.close(false);
  }

  public close(value: boolean): void{
    this.dialogRef.close(value);
  }

  public confirm(): void{
    this.close(true);
  }

  @HostListener("keydown.esc")
  public onEsc(): void{
    this.close(false);
  }

}
